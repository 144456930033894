import {configureStore, StateFromReducersMapObject} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {BuilderSlice} from "./Slice";


const reducer = {
    builder: BuilderSlice.reducer
}

export type BuilderState = StateFromReducersMapObject<typeof reducer>

export function createBuilderStore(initialState: BuilderState) {
    return configureStore({
        reducer: reducer,
        preloadedState: initialState
    })
}

export type BuilderStore = ReturnType<typeof createBuilderStore>

export const useBuilderDispatch = useDispatch.withTypes<ReturnType<typeof createBuilderStore>["dispatch"]>()
export const useBuilderSelector = useSelector.withTypes<BuilderState>()