export class ApiWrapper<T> {
    private _api : T | null = null;
    private readonly _create: () => T
    private readonly _destroy: (arg1: T) => void

    constructor(create: () => T, destroy : (api: T) => void) {
        this._create = create
        this._destroy = destroy
    }

    getApi() {
        if (!this._api)
            this._api = this._create()
        return this._api
    }

    free() {
        if (this._api){
            this._destroy(this._api)
            this._api = null
        }
    }
}
