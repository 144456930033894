import {
    Block,
    BlockFunctions,
    BlockType,
    getTypeGuard,
    OperatorType,
    OperatorTypes,
    SingleValueType,
    SingleValueTypes
} from "./Block";
import {isNullable, StudyType, StudyTypeValue} from "./StudyType";
import {uniqueId} from "../../crafter/Utils";
import {ValuesOf} from "../../lib/utils/Type";
import {TypeGuard} from "../Guards";
import {TypeConverter} from "../Converters";
import {StudyContext} from "./StudyContext";

export namespace Operator {
    type Associativity = "left" | "right";

    export interface Type<T extends OperatorType = OperatorType> extends Block<T> {
        validInTypes: {
            t1: StudyType[],
            t2: StudyType[]
        },
        validOutTypes: StudyType[],
        precedence: number,
        associativity: Associativity,
        altName: string,
        useAltName: boolean
    }

    export function GetOperatorOutStudyType(context: StudyContext, b: Type, v1: StudyType, v2:StudyType): StudyType | undefined {
        return GetOutStudyTypeMap[b.type](context, b, v1, v2);
    }

    export function GetAssociativityPopCondition(a: Associativity){
        if (a === "left")
            return (top: Type, b: Type) => top.precedence >= b.precedence
        else return (top: Type, b: Type) => top.precedence > b.precedence
    }

    export const GetStringRepresentation: TypeConverter<Type<OperatorType>, string> = (arg) => {
        return arg.useAltName ? arg.altName : arg.value;
    }

    export const typeGuard: TypeGuard<Block<any>, Type<OperatorType>> = (b): b is Type<OperatorType> => OperatorTypes.includes(b.type)

    export namespace Arithmetic {
        export const ArithmeticOperatorProps = {
            Divide: {
                altName: "/",
                precedence: 6,
                associativity: "left" as Associativity
            },
            Exponent: {
                altName: "^",
                precedence: 7,
                associativity: "right" as Associativity
            },
            Minus: {
                altName: "-",
                precedence: 5,
                associativity: "left" as Associativity
            },
            Multiply: {
                altName: "*",
                precedence: 6,
                associativity: "left" as Associativity
            },
            Plus: {
                altName: "+",
                precedence: 5,
                associativity: "left" as Associativity
            },
        } as const;
        export type ArithmeticOperation = keyof typeof ArithmeticOperatorProps;
        export const ArithmeticOperations: ValuesOf<ArithmeticOperation> = Object.keys(ArithmeticOperatorProps) as ValuesOf<ArithmeticOperation>;

        export interface Type extends Operator.Type<"ArithmeticOperator"> {
            validInTypes: {
                t1: ["Number", "NullableNumber"],
                t2: ["Number", "NullableNumber"]
            },
            validOutTypes: ["Number", "NullableNumber"]
        }

        export const GetOutStudyType: typeof Operator.GetOperatorOutStudyType = (_, b, v1, v2) => {
            if (b.validInTypes.t1.includes(v1) && b.validInTypes.t2.includes(v2))
                if (isNullable(v1) || isNullable(v2))
                    return "NullableNumber"
                else return "Number"
            else return undefined; // TODO: type mismatch
        }

        export const functions: BlockFunctions<Type, { id?: string, value: ArithmeticOperation, useAltName?: boolean }> = {
            checkType: getTypeGuard<Type>("ArithmeticOperator"),
            constructor: (args) => {
                return {
                    id: args.id ?? uniqueId(),
                    type: "ArithmeticOperator",
                    value: args.value,
                    validInTypes: {
                        t1: ["Number", "NullableNumber"],
                        t2: ["Number", "NullableNumber"]
                    },
                    validOutTypes: ["Number", "NullableNumber"],
                    useAltName: args.useAltName ?? false,
                    ...ArithmeticOperatorProps[args.value],
                }
            },
            converters: {
                fromDb: ({value}) => {
                    // TODO: temp useAltName
                    return functions.constructor({value, useAltName:true});
                },
                toDb: ({type, value}) => {
                    return {
                        type,
                        value
                    };
                }
            }
        }
    }

    export namespace Comparison {
        export const ComparisonOperationProps = {
            IsGreaterThan: {
                altName: ">",
                precedence: 4,
                associativity: "left" as Associativity
            },
            IsLessThan: {
                altName: "<",
                precedence: 4,
                associativity: "left" as Associativity
            },
            IsGreaterThanOrEqualTo: {
                altName: ">=",
                precedence:  4,
                associativity: "left" as Associativity
            },
            IsLessThanOrEqualTo: {
                altName: "<=",
                precedence:  4,
                associativity: "left" as Associativity
            },
            IsEqualTo: {
                altName: "equals to",
                precedence: 3,
                associativity: "left" as Associativity
            },
            IsNotEqualTo: {
                altName: "not equals to",
                precedence: 3,
                associativity: "left" as Associativity
            },
        } as const;
        export type ComparisonOperation = keyof typeof ComparisonOperationProps;
        export const ComparisonOperations = Object.keys(ComparisonOperationProps) as ValuesOf<ComparisonOperation>;

        export const GetOutStudyType: typeof Operator.GetOperatorOutStudyType = (_, b, v1, v2) => {
            if (b.validInTypes.t1.includes(v1) && b.validInTypes.t2.includes(v2))
                if (isNullable(v1) || isNullable(v2))
                    return "NullableBoolean"
                else return "Boolean"
            else return undefined; // TODO: type mismatch
        }

        export interface Type extends Operator.Type<"ComparisonOperator"> {
            validInTypes: {
                t1: ["Number", "NullableNumber"],
                t2: ["Number", "NullableNumber"]
            },
            validOutTypes: ["Boolean", "NullableBoolean"]
        }
        export const functions: BlockFunctions<Type, { id?: string, value: ComparisonOperation, useAltName?: boolean }> = {
            checkType: getTypeGuard<Type>("ComparisonOperator"),
            constructor: (args) => {
                return {
                    id: args.id ?? uniqueId(),
                    type: "ComparisonOperator",
                    value: args.value,
                    validInTypes: {
                        t1: ["Number", "NullableNumber"],
                        t2: ["Number", "NullableNumber"]
                    },
                    validOutTypes: ["Boolean", "NullableBoolean"],
                    useAltName: args.useAltName ?? false,
                    ...ComparisonOperationProps[args.value]
                }
            },
            converters: {
                fromDb: ({value}) => {
                    // TODO: temp useAltName
                    return functions.constructor({value, useAltName:true});
                },
                toDb: ({type, value}) => {
                    return {
                        type,
                        value
                    };
                }
            }
        }
    }

    export namespace Logical {
        const LogicalOperationProps = {
            And: {
                altName: "and",
                precedence: 2,
                associativity: "left" as Associativity
            },
            Or: {
                altName: "or",
                precedence: 1,
                associativity: "left" as Associativity
            }
        } as const;
        export type LogicalOperation = keyof typeof LogicalOperationProps;
        export const LogicalOperations = Object.keys(LogicalOperationProps) as ValuesOf<LogicalOperation>;

        export interface Type extends Operator.Type<"BooleanOperator"> {
            validInTypes: {
                t1: ["Boolean", "NullableBoolean"],
                t2: ["Boolean", "NullableBoolean"]
            },
            validOutTypes: ["Boolean", "NullableBoolean"]
        }

        // export function GetOutStudyType(t1: Type["validInTypes"]["t1"][number], t2: Type["validInTypes"]["t2"][number]): Type["validOutTypes"][number] {
        //     if (t1 === "NullableBoolean" || t2 === "NullableBoolean")
        //         return "NullableBoolean"
        //     else return "Boolean"
        // }

        export const GetOutStudyType: typeof Operator.GetOperatorOutStudyType = (_, b, v1, v2) => {
            if (b.validInTypes.t1.includes(v1) && b.validInTypes.t2.includes(v2))
                if (isNullable(v1) || isNullable(v2))
                    return "NullableBoolean"
                else return "Boolean"
            else return undefined; // TODO: type mismatch
        }

        export const functions: BlockFunctions<Type, { id?: string, value: LogicalOperation, useAltName?: boolean }> = {
            checkType: getTypeGuard<Type>("BooleanOperator"),
            constructor: (args) => {
                return {
                    id: args.id ?? uniqueId(),
                    type: "BooleanOperator",
                    value: args.value,
                    validInTypes: {
                        t1: ["Boolean", "NullableBoolean"],
                        t2: ["Boolean", "NullableBoolean"]
                    },
                    validOutTypes: ["Boolean", "NullableBoolean"],
                    useAltName: args.useAltName ?? false,
                    ...LogicalOperationProps[args.value]
                }
            },
            converters: {
                fromDb: ({value}) => {
                    return functions.constructor({value});
                },
                toDb: ({type, value}) => {
                    return {
                        type,
                        value
                    };
                }
            }
        }
    }

    const GetOutStudyTypeMap: Record<OperatorType, typeof GetOperatorOutStudyType> = {
        "ArithmeticOperator": Arithmetic.GetOutStudyType,
        "ComparisonOperator": Comparison.GetOutStudyType,
        "BooleanOperator": Logical.GetOutStudyType
    }
}