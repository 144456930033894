import {configureStore, StateFromReducersMapObject} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {DashboardSlice} from "./Slice";


const reducer = {
    dashboard: DashboardSlice.reducer
}

export type DashboardState = StateFromReducersMapObject<typeof reducer>

export function createDashboardStore(initialState: DashboardState) {
    return configureStore({
        reducer: reducer,
        preloadedState: initialState
    })
}

export type DashboardStore = ReturnType<typeof createDashboardStore>

export const useDashboardDispatch = useDispatch.withTypes<ReturnType<typeof createDashboardStore>["dispatch"]>()
export const useDashboardSelector = useSelector.withTypes<DashboardState>()