import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './lib/css/index.css';
import {getAuthorizationHeader, Home} from "./home/Home";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {LoaderWrappedBuilder, LoaderWrappedCrafter, LoaderWrappedDashboard} from "./Wrapped";
import {CrafterState} from "./crafter/data/Store";
import {Provider} from "react-redux";
import {MainStore, MainStoreContext, useMainDispatch} from "./home/data/Store";
import {BuilderState} from "./builder/data/Store";
import {Dashboard} from "./builder/types/Dashboard";
import {DashboardState} from "./dashboard/data/Store";
import {Converters} from "./types/Converters";
import {ReadStudy} from "./types/study/db/ReadStudy";
import {ConnectBroker} from "./profile/BrokerPage";
import {auth, Auth} from "./firebase/auth";
import {setUser} from "./auth/AuthSlice";
import {AuthComponent} from "./login/AuthComponent";
import Login from "./login/login";
import { Unsubscribe } from 'firebase/auth';
import {clear} from "./home/data/HomeSlice";
import {HomeNew} from "./home/HomeNew";
import {ApiResponse, getUserStudies} from "./home/data/Thunks";
import {defaultStudyConfig} from "./types/study/Config";
import {About} from "./about/About";
import {TRALY_BACKEND_URL} from "./constants";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/home",
        element: <About/>
    },
    {
        path:"/login",
        element: <Login/>
    },
    {
        path: "/connectBroker/:brokerName/:hash",
        element: <ConnectBroker/>
    },
    {
        path: "/",
        element: <AuthComponent>
            <HomeNew/>
        </AuthComponent>
    },
    {
        path: "/crafter/:studyId",
        loader: async ({params}): Promise<CrafterState | null> => {
            const authHeader = await getAuthorizationHeader();
            const res = await fetch(`${TRALY_BACKEND_URL}/study?studyId=${params.studyId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader
                }
            })
            if (!res.ok) {
                console.log("Error", res)
                return null; // TODO: Review null, handle null in loader wrapped crafter
            }
            const json = await res.json() as ApiResponse;
            if (json.status){
                const dt = Converters.Study.Read(json.data);
                return {
                    crafter: {
                        study: dt,
                        saveStatus: "upToDate",
                        config: defaultStudyConfig
                    }
                };
            }
            else return null;
        },
        element: <AuthComponent>
            <LoaderWrappedCrafter/>
        </AuthComponent>
    },
    {
        path: "/builder/:dashboardId",
        loader: async ({params}): Promise<BuilderState | null> => {
            const authHeader = await getAuthorizationHeader();
            const res = await fetch(`${TRALY_BACKEND_URL}/dashboard?id=${params.dashboardId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader
                }
            })
            if (!res.ok) {
                console.log("Error")
                console.log(res)
                return null;
            }
            const json = await res.json() as Dashboard;
            return {
                builder: {
                    dashboard: json,
                    saveStatus: "upToDate"
                }
            };
        },
        element: <AuthComponent>
            <LoaderWrappedBuilder/>
        </AuthComponent>
    },
    {
        path: "/dashboard/:dashboardId",
        loader: async ({params}): Promise<DashboardState | null> => {
            // TODO: Use Proper API
            const res = await fetch(`${TRALY_BACKEND_URL}/dashboard?id=${params.dashboardId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (!res.ok) {
                console.log("Error")
                console.log(res)
                return null;
            }
            const json = await res.json() as Dashboard;
            return {
                dashboard: {
                    dashboard: json
                }
            };
        },
        element: <AuthComponent>
            <LoaderWrappedDashboard/>
        </AuthComponent>
    }
])

function App(){
    const dispatch = useMainDispatch();

    useEffect(() => {
        let cleanUp : Unsubscribe | undefined = undefined;
        Auth.onAuthStateChanged((user) => {
            const payload = user ? {
                uid: user.uid,
                name: user.displayName || "",
                email: user.email || "",
                image: user.photoURL ?? undefined
            } : null
            dispatch(setUser(payload));
            dispatch(clear());
            dispatch(getUserStudies())
        }).then((unsub) => {
            cleanUp = unsub;
        })

        return () => {
            if (cleanUp)
                cleanUp();
        }
    }, []);

    return (
        <RouterProvider router={router}/>
    )
}

root.render(
    <Provider store={MainStore} context={MainStoreContext}>
        <App/>
    </Provider>
);
