import React, {useEffect} from "react";
import {getAuthorizationHeader} from "../home/Home";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {UriState} from "../home/data/HomeSlice";
import {TRALY_BACKEND_URL} from "../constants";

const REDIRECT_URI = Symbol()

type DetailedConnectionStep = {
    data: React.ReactNode,
    subList?: ConnectionStep[],
    ordered?: boolean,
    footer?: React.ReactNode
}

type ConnectionStep = React.ReactNode | DetailedConnectionStep | typeof REDIRECT_URI;

function isDetailedConnectionStep(step: ConnectionStep): step is DetailedConnectionStep {
    return (step as DetailedConnectionStep).data !== undefined;
}

export const BrokerIds = ["fyers", "angelone"] as const;
export type BrokerId = (typeof BrokerIds)[number]

// TODO: Make id type safe
export type BrokerInfo = {
    id: BrokerId;
    name: string;
    image: {
        /**
         * The image name must be <broker-id>-logo.<extension>
         * Preferable extension is svg.
         * Example: fyers-logo.svg
         * */
        url: string,
        /**
         * Should not be more than w-40
         * */
        tailwindWidth: string
    },
    miniImage: string,
    // loginURI: string;
    getRedirectURI: (/* user info */) => string;
    steps: ConnectionStep[];
    orderedSteps: boolean;
    openAccountLink: string;
    openApiLink: string;
    inputs: {
        name: string,
        text?: string,
        placeholder?: string,
        extra?: string
    }[]
}

export const BrokerList: BrokerInfo[] = [
    {
        id: "fyers",
        name: "Fyers",
        image: {
            url: "fyers-logo.svg",
            tailwindWidth: "w-32"
        },
        miniImage: "fyers-logo-mini.webp",
        getRedirectURI: () => "",
        steps: [
            <a href="https://fyers.in/" className="underline">Open account Link</a>,
            <a href="https://fyers.in/api/" className="underline">Open Api Link</a>,
            "Create an account on Fyers",
            {
                data:"Get your",
                subList: [
                    "Go to the API section",
                    "Create an app",
                    "Get your API key and secret"
                ]
            },
            "Get your API key and secret",
            "Connect your account"
        ],
        orderedSteps: true,
        openAccountLink: "https://fyers.in/",
        openApiLink: "https://fyers.in/api/",
        inputs: [{
            name: "apiId",
            placeholder: "Enter your API Key",
            text: "API Key",
            extra: "This is the key you get from the API section of Fyers"
        },
            {
                name: "apiSecret",
                placeholder: "Enter your API Secret",
                text: "API Secret"
            }
        ]
    },
    {
        id: "angelone",
        name: "Angel One",
        image: {
            url: "angelone-logo.svg",
            tailwindWidth: "w-40"
        },
        miniImage: "angelone-logo-mini.svg",
        getRedirectURI: () => "",
        steps: [
            <span className="space-x-2">
                <span>Sign Up, and log in to your account on</span>
                <a target="_blank" rel="noopener noreferrer"  href="https://smartapi.angelbroking.com/" className="underline text-blue-500">
                    Angel One - Smart API
                </a>
                .
            </span>,
            {
                data: <span>
                    Create an app, and fill in the following details.
                </span>,
                subList: [
                    <span><span className="font-semibold">App Name</span>- Any name of your choice.</span>,
                    <span><span className="font-semibold">Redirect Uri</span>- Copy and paste the uri given below.</span>,
                ]
            },
            REDIRECT_URI,
            <span>
                Enter the API key you get from the created app on Angel One.
            </span>
        ],
        orderedSteps: true,
        openAccountLink: "https://smartapi.angelbroking.com/",
        openApiLink: "https://smartapi.angelbroking.com/",
        inputs: [{
            name: "apiKey",
            placeholder: "Enter your API Key",
            text: "API Key"
        }]
    }
]

export const Brokers: Record<BrokerId, BrokerInfo> = {
    fyers: BrokerList[0],
    angelone: BrokerList[1],
}



export type BrokerInputs = {
    inputs: Record<string, string>,
    brokerName: string
}

const brokerBackendHost = TRALY_BACKEND_URL + "/"
const connectBrokerPath = "broker/connect/"
export function ConnectBroker(){

    const params = useParams()
    const [queryParams] = useSearchParams()
    const nav = useNavigate()
    useEffect(() => {
        async function connectBroker()
        {
            let inputs: Record<string, string> = {}
            queryParams.forEach((v, k) => {
                inputs = {
                    ...inputs,
                    [k]: v
                }
            })
            const authHeader = await getAuthorizationHeader()
           const res =  await fetch(`${brokerBackendHost}${connectBrokerPath}${params.brokerName}/${params.hash}`, {
                method: "POST",
                headers: {
                     "Content-Type": "application/json",
                        ...authHeader
                },
                body: JSON.stringify({inputs})
              })
            console.log(res)
            if (res.ok)
                nav("/")
        }
        connectBroker()
    }, [queryParams]);

    return(
        <div>
            <h1>Connecting to {params.brokerName}</h1>
        </div>
    )
}

export const NO_REDIRECT_URI = -1
export const LOADING_REDIRECT_URI = 1
type StepUIProps = {
    step: ConnectionStep,
    uri?: UriState
}

export function StepUI(props: StepUIProps){
    return (
        <>
            {
                isDetailedConnectionStep(props.step) ?
                    <li>
                        {props.step.data}
                        <ul className={`${props.step.ordered ? "list-decimal" : props.step.ordered === false ? "list-disc": "list-none"} p-2`}>
                            {props.step.subList?.map(subStep =>
                                <StepUI uri={NO_REDIRECT_URI} step={subStep} />)}
                        </ul>
                    </li>
                    :
                    props.step === REDIRECT_URI ?
                            props.uri !== NO_REDIRECT_URI && props.
                            uri !== undefined &&
                            <>
                                {
                                    props.uri === LOADING_REDIRECT_URI ?
                                        <img alt={"Loading"} className="size-6" src="/loading.svg" />
                                        :
                                        <>
                                            {/*<span className="text-sm font-semibold">Redirect uri</span>*/}
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(props.uri as string)
                                                        .then(() => {
                                                            alert("Copied to clipboard")
                                                        })
                                                }}
                                                className="rounded-sm bg-zinc-600 py-2 px-4 cursor-pointer hover:bg-zinc-500">
                                                <div className="text-sm font-mono">{props.uri}</div>
                                                <div className="pt-2 italic text-xs font-semibold text-zinc-400">Click to copy</div>
                                            </div>
                                        </>
                                }
                            </>
                        :
                    <li>
                        {props.step}
                    </li>
            }
        </>
    )
}
