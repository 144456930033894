import {configureStore} from "@reduxjs/toolkit";
import {createDispatchHook, createSelectorHook, ReactReduxContextValue} from "react-redux";
import {HomeSlice} from "./HomeSlice";
import React from "react";
import {AuthSlice} from "../../auth/AuthSlice";

// TODO: Move file to outside dir

export const MainStoreContext = React.createContext<ReactReduxContextValue | null>(null);

export const MainStore = configureStore({
    reducer: {
        auth: AuthSlice.reducer,
        home: HomeSlice.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production'
})

export type MainStoreState = ReturnType<typeof MainStore.getState>
export type MainStoreDispatch = typeof MainStore.dispatch

export const useMainDispatch = createDispatchHook(MainStoreContext).withTypes<MainStoreDispatch>()
export const useMainSelector = createSelectorHook(MainStoreContext).withTypes<MainStoreState>()
