import {configureStore, StateFromReducersMapObject} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from "react-redux";
import {CrafterSlice} from "./Slice";

const reducer = {
    crafter: CrafterSlice.reducer
}

export type CrafterState = StateFromReducersMapObject<typeof reducer>

export function createCrafterStore(initialState: CrafterState) {
    return configureStore({
        reducer: reducer,
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production'
    })
}

export type CrafterStore = ReturnType<typeof createCrafterStore>
export type CrafterStoreSelector<T> = (state: CrafterState) => T

export const useCrafterDispatch = useDispatch.withTypes<CrafterStore["dispatch"]>()
export const useCrafterSelector = useSelector.withTypes<CrafterState>()