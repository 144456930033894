import React, {forwardRef, ReactNode, useImperativeHandle, useLayoutEffect, useRef} from "react";
import {ApiWrapper} from "./api-wrapper";
import {
    IChartApi,
    ISeriesApi,
    SeriesDataItemTypeMap,
    SeriesOptionsMap,
    SeriesPartialOptionsMap
} from "lightweight-charts";
import {createNullableContext, useNotNullContext} from "./react-helpers";
import {ChartApiContext} from "./Chart";

type SeriesApi<T extends keyof SeriesOptionsMap> = ApiWrapper<ISeriesApi<T>>

export type SeriesProps<T extends keyof SeriesOptionsMap> = {
    initialData?: SeriesDataItemTypeMap[T][],
    data?: SeriesDataItemTypeMap[T][],
    options?: SeriesPartialOptionsMap[T],
    children?: ReactNode
}
function makeSeries<T extends keyof SeriesOptionsMap>(createApiFunc: (thisArg: IChartApi) => () => ISeriesApi<T>)
:[React.Context<SeriesApi<T> | null>, React.ForwardRefExoticComponent<React.PropsWithoutRef<SeriesProps<T>> & React.RefAttributes<ISeriesApi<T>>>] {
    const Context = createNullableContext<SeriesApi<T>>()
    const Component= forwardRef((props: SeriesProps<T>, ref:React.ForwardedRef<ISeriesApi<T>>) => {
         const chartApi = useNotNullContext(ChartApiContext);
         const seriesApiRef = useRef(new ApiWrapper<ISeriesApi<T>>(
             ()=> {
                 const api = createApiFunc(chartApi.getApi()).call(chartApi.getApi())
                 api.setData(props.initialData ?? [])
                 return api
             },
             () => {
                 chartApi.getApi().removeSeries(seriesApiRef.current.getApi())
             }
         ))

         useLayoutEffect(() => {
             const currentRef = seriesApiRef.current;
             currentRef.getApi();
             return () => currentRef.free();
         }, []);

         useLayoutEffect(() => {
             if (props.options)
                 seriesApiRef.current.getApi().applyOptions(props.options);
         });

        useLayoutEffect(() => {
            if (props.data)
                seriesApiRef.current.getApi().setData(props.data)
        }, [props.data]);

         useImperativeHandle(ref, () => seriesApiRef.current.getApi(), []);

         return (
             <>
                 <Context.Provider value={seriesApiRef.current}>
                     {props.children}
                 </Context.Provider>
             </>
         )
     })
    return [Context, Component];
}

export const [AreaSeriesApiContext, AreaSeries] = makeSeries<"Area">(chartApi => chartApi.addAreaSeries)
export const [LineSeriesApiContext, LineSeries] = makeSeries<"Line">(chartApi => chartApi.addLineSeries)
export const [BarSeriesApiContext, BarSeries] = makeSeries<"Bar">(chartApi => chartApi.addBarSeries)
export const [BaseLineSeriesApiContext, BaseLineSeries] = makeSeries<"Baseline">(chartApi => chartApi.addBaselineSeries)
export const [CandleStickSeriesApiContext, CandleStickSeries] = makeSeries<"Candlestick">(chartApi => chartApi.addCandlestickSeries)
export const [HistogramSeriesApiContext, HistogramSeries] = makeSeries<"Histogram">(chartApi => chartApi.addHistogramSeries)
