import {ExclamationCircleIcon} from "@heroicons/react/16/solid";
import React from "react";

type AppInputProps = {
    error?: string
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export function AppInput(props: AppInputProps) {
    return (
        <>
            <div className="flex flex-1 bg-zinc-800 rounded-sm p-1.5 text-sm focus:dark:outline-zinc-700">
                <input
                    {...props}
                    className={`flex-1 bg-transparent focus:dark:outline-0 ${props.className}`}
                />
                {
                    props.error &&
                    <ExclamationCircleIcon className="w-5 dark:text-red-400"/>
                }
            </div>
            {
                props.error &&
                <div className="text-sm p-0.5 h-6 dark:text-red-400">{props.error}</div>
            }
        </>
    )
}