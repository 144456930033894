import {Block, BlockType} from "../types/study/Block";
import {Constructors} from "../types/Constructors";
import {Value} from "../types/study/Values";
import { Operator } from "../types/study/Operators";
import {EquationScopedStudyContext} from "../types/study/StudyContext";
import {Positions, Shapes} from "./components/TypeEditor";

export type BlockItem = {
    uid: string,
    itemName: string,
    type: BlockType,
    blockFactory: () => Block<BlockType>
}
export function generateBlockItems(context: EquationScopedStudyContext): BlockItem[] {
    return [
        ...Value.Single.SeriesValue.SeriesElements.map((v) => ({
            uid: `${v}-full`,
            itemName: v,
            type: "Series" as BlockType,
            blockFactory: () => Constructors.Block.Series({value: v})
        })),
        ...context.deps.allSeries.map((v) => ({
            uid: `${v.refId}-full`,
            itemName: v.refValue.paramName,
            type: "Study" as BlockType,
            blockFactory: () => Constructors.Block.Study({value: v.refId})
        })),
        ...Value.Single.SeriesValue.SeriesElements.map((v) => ({
            uid: `${v}-offset`,
            itemName: `${v}-1`,
            type: "Series" as BlockType,
            blockFactory: () => Constructors.Block.Series({value: v, offset:1})
        })),
        ...context.deps.allSeries.map((v) => ({
            uid: `${v.refId}-offset`,
            itemName: `${v.refValue.paramName}-1`,
            type: "Study" as BlockType,
            blockFactory: () => Constructors.Block.Study({value: v.refId, offset: 1})
        })),
        ...Operator.Arithmetic.ArithmeticOperations.map((v) => ({
            uid: `${v}-full`,
            itemName: v,
            type: "ArithmeticOperator" as BlockType,
            blockFactory: () => Constructors.Block.ArithmeticOperator({value: v})
        })),
        ...Operator.Arithmetic.ArithmeticOperations.map((v) => ({
            uid: `${v}-alt`,
            itemName: Operator.Arithmetic.ArithmeticOperatorProps[v].altName,
            type: "ArithmeticOperator" as BlockType,
            blockFactory: () => Constructors.Block.ArithmeticOperator({value: v, useAltName: true})
        })),
        ...Operator.Comparison.ComparisonOperations.map((v) => ({
            uid: `${v}-full`,
            itemName: v,
            type: "ComparisonOperator" as BlockType,
            blockFactory: () => Constructors.Block.ComparisonOperator({value: v})
        })),
        ...Operator.Comparison.ComparisonOperations.map((v) => ({
            uid: `${v}-alt`,
            itemName: Operator.Comparison.ComparisonOperationProps[v].altName,
            type: "ComparisonOperator" as BlockType,
            blockFactory: () => Constructors.Block.ComparisonOperator({value: v, useAltName: true})
        })),
        ...Operator.Logical.LogicalOperations.map((v) => ({
            uid: `${v}-full`,
            itemName: v,
            type: "BooleanOperator" as BlockType,
            blockFactory: () => Constructors.Block.BooleanOperator({value: v})
        })),
        ...Positions.map((p) => {
            return {
                uid: `position-${p}`,
                itemName: `position.${p}`,
                type: "Constant" as BlockType,
                blockFactory: () => Constructors.Block.Constant({value: {type: "Position", value: p}})
            }
        }),
        ...Shapes.map((p) => {
            return {
                uid: `shape-${p}`,
                itemName: `shape.${p}`,
                type: "Constant" as BlockType,
                blockFactory: () => Constructors.Block.Constant({value: { type:"Shape", value: p }})
            }
        }),
        {
            uid: `expression-()`,
            itemName: "( )",
            type: "Expression" as BlockType,
            blockFactory: () => Constructors.Block.Expression({value: []})
        },
        {
            uid: `nullsafe-full`,
            itemName: "NullSafe",
            type: "BinaryComposite" as BlockType,
            blockFactory: () => Constructors.Block.BinaryComposite({value: {op: "NullSafe", lhs: Constructors.Block.Empty({value: undefined}), rhs: Constructors.Block.Empty({value: undefined})}})
        }
    ]
}