import {Button} from "@headlessui/react";
import React from "react";

export type PrimaryButtonProps = {
    text: string,
    disabled?: boolean,
    className?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    children?: React.ReactNode
}

export function PrimaryButton(props: PrimaryButtonProps) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${props.className} text-zinc-200 inline-flex data-[disabled]:bg-gray-500 items-center gap-2 rounded-md dark:bg-[#5F57FF]/70 py-1.5 px-3 text-md shadow-inner shadow-white/5 focus:outline-none data-[hover]:dark:bg-[#5F57FF]/50 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}>
            {props.text}
        </Button>
    )
}

export function SecondaryButton(props: PrimaryButtonProps) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${props.className} text-zinc-200 inline-flex data-[disabled]:bg-gray-500 items-center gap-2 rounded-md ark:bg-zinc-700 py-1.5 px-3 text-md focus:outline-none data-[hover]:dark:bg-zinc-700/50 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}>
            {props.children}
            {props.text}
        </Button>
    )
}