import React, {useEffect, useRef, useState} from 'react';
import {Alert, Form} from "react-bootstrap";
import Icon from "react-icons-kit";
import {useAuth} from "./authOld";
import {useNavigate} from "react-router-dom";
import {eye} from 'react-icons-kit/feather/eye'
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {Auth} from "../firebase/auth";
import {useMainSelector} from "../home/data/Store";

const Login = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // const {signingInWithEmailAndPassword, doSignInWithGoogle} = useAuth();
    const navigate = useNavigate();

    // TODO
    const user = useMainSelector(state => state.auth.user);
    useEffect(() => {
        if (user !== null) {
            navigate("/")
        }
    }, [user]);

    // const emailRef: React.MutableRefObject<HTMLInputElement | null | undefined> = useRef();
    // const passwordRef: React.MutableRefObject<HTMLInputElement | null | undefined> = useRef();

    //password eye (visible)
    // const [typePassword, setTypePassword] = useState('password');
    // const [iconPassword, setIconPassword] = useState(eyeOff);

    // const handlePasswordToggle = () => {
    //     if (typePassword === 'password') {
    //         setIconPassword(eye);
    //         setTypePassword('text')
    //     } else {
    //         setIconPassword(eyeOff)
    //         setTypePassword('password')
    //     }
    // }

    const onGoogleSignIn = async (e: any) => {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);
            Auth.signInWithPopup();
            console.log("logged in with google");
            // navigate("/")

        } catch(e) {
            console.log(e)
            setError("Failed to Signin  with  Google");
        }
        setLoading(false);
    }

    //TODO: Add various handling for password
    // const signedInUser = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //
    //     try {
    //         setError("")
    //         setLoading(true);
    //         await signingInWithEmailAndPassword(emailRef.current?.value, passwordRef.current?.value);
    //         console.log("logged in");
    //         // navigate("/")
    //     } catch {
    //         setError("Failed to sign in to   an Account");
    //     }
    //     setLoading(false);
    // }
    return (
        <main
            className="w-full  h-screen bg-zinc-900 flex self-center place-content-center place-items-center drop-shadow-2xl ">
            <div className="w-96 text-zinc-300 bg-zinc-800 space-y-5 p-4 shadow-2xl rounded-lg">
                <div className="text-center">
                    <div className="mt-1">
                        <div className="text-2xl font-sans font-bold">Login to Your Acount</div>
                    </div>
                </div>
                {error && <Alert variant="danger" className="text-red-400">{error}</Alert>}
                <div className="">
                    <Form>
                        {/*<Form.Group>
                            <Form.Label className="font-bold">Email</Form.Label>
                            <Form.Control type="email" autoComplete='email'
                                          className="w-full mt-2 px-2 py-2 bg-zinc-600 outline-none shadow-sm rounded-lg "
                                          ref={emailRef as React.RefObject<HTMLInputElement>}
                                          required/>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label className="font-bold">Password</Form.Label>
                            <div className="relative">
                                <Form.Control type={typePassword}
                                              className="w-full mt-2 px-2 py-2 bg-zinc-600 outline-none shadow-sm rounded-lg "
                                              ref={passwordRef as React.RefObject<HTMLInputElement>}
                                              required/>
                                <span className="absolute right-3 top-4 cursor-pointer"
                                      onClick={handlePasswordToggle}>
                                        <Icon icon={iconPassword} size={20}/>
                                    </span>
                            </div>
                            <div className="w-100 text-center mt-3">
                                <span className="underline float-right">Forgot Password?</span>
                            </div>
                        </Form.Group>
                        <div className="flex items-center justify-center ">
                            <Button
                                type="submit"
                                className={`w-50 ml-28  px-4 mt-8 py-2 text-white font-medium rounded-lg ${loading ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'}`}
                                disabled={loading}>Login</Button>
                        </div>*/}
                        <button
                            disabled={loading}
                            onClick={(e) => {
                                onGoogleSignIn(e)
                            }}
                            className={`w-full flex items-center mt-6 border-black-100  justify-center gap-x-3 py-2.5 border rounded text-sm font-medium  ${loading ? 'cursor-not-allowed' : 'hover:border-purple-200 transition duration-300 active:bg-gray-100'}`}>
                            <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_17_40)">
                                    <path
                                        d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                                        fill="#4285F4"/>
                                    <path
                                        d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                                        fill="#34A853"/>
                                    <path
                                        d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                                        fill="#FBBC04"/>
                                    <path
                                        d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                                        fill="#EA4335"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_17_40">
                                        <rect width="48" height="48" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            {loading ? 'Signing In...' : 'Sign in with Google'}
                        </button>
                    </Form>
                </div>
                <div className="text-white w-100 text-center   mt-4">Need an Account? <span
                    className="text-blue-400">Sign Up</span></div>
            </div>
        </main>

    );
};

export default Login;
