import {Constructor} from "../Constructors";
import {TypeGuard} from "../Guards";
import _ from "lodash";
import {ValuesOf} from "../../lib/utils/Type";
import {undefined} from "zod";

// Non-Nullable Types
type NonNullablePrimitiveMap = {
    Number: number,
    Boolean: boolean,
    String: string,
    Color: string,
    // Time
    Position: "AboveBar" | "BelowBar",
    Shape: "ArrowUp" | "ArrowDown"
}
export type NonNullableStudyType = keyof NonNullablePrimitiveMap

// Nullable Types -> Derived from Non-Nullable types
type Nullable<T> = `Nullable${T & string}`
type NullableStudyType = Nullable<NonNullableStudyType>
type NullableToNonNullableMap = { [k in keyof NonNullablePrimitiveMap as Nullable<k>]: k }
type NullablePrimitiveMap = { [k in keyof NonNullablePrimitiveMap as Nullable<k>]: NonNullablePrimitiveMap[k] | null }

/**
 * All Study types. Union of Non-Nullable and Nullable types
 */
export type StudyType = NonNullableStudyType | NullableStudyType;


/**
 * Map of StudyType to its primitive types.
 * */
export type StudyTypePrimitiveMap = { [k in StudyType]: k extends keyof NonNullablePrimitiveMap ? NonNullablePrimitiveMap[k] : k extends NullableStudyType ? NullablePrimitiveMap[k] : never }

export type StudyTypePrimitive = StudyTypePrimitiveMap[StudyType]

const DefaultStudyTypeValues: StudyTypePrimitiveMap = {
    Boolean: false,
    NullableBoolean: null,
    NullableNumber: null,
    Number: 0,
    NullableString: null,
    String: "",
    Color: "#FF8A65",
    NullableColor: null,
    NullablePosition: null,
    NullableShape: null,
    Position: "BelowBar",
    Shape: "ArrowUp"
}

export const StudyTypeConstructors : { [k in StudyType]: Constructor<void, StudyTypePrimitiveMap[k]> } = {
    Boolean: () => DefaultStudyTypeValues.Boolean,
    NullableBoolean: () => DefaultStudyTypeValues.NullableBoolean,
    Number: () => DefaultStudyTypeValues.Number,
    NullableNumber: () => DefaultStudyTypeValues.NullableNumber,
    String: () => DefaultStudyTypeValues.String,
    NullableString: () => DefaultStudyTypeValues.NullableString,
    Color: () => DefaultStudyTypeValues.Color,
    NullableColor: () => DefaultStudyTypeValues.NullableColor,
    Position: () => DefaultStudyTypeValues.Position,
    NullablePosition: () => DefaultStudyTypeValues.NullablePosition,
    Shape: () => DefaultStudyTypeValues.Shape,
    NullableShape: () => DefaultStudyTypeValues.NullableShape
}

export const StudyTypeMap: { [k in StudyType]: k } = {
    Boolean: "Boolean",
    Color: "Color",
    NullableBoolean: "NullableBoolean",
    NullableColor: "NullableColor",
    NullableNumber: "NullableNumber",
    NullableString: "NullableString",
    Number: "Number",
    String: "String",
    Position: "Position",
    Shape: "Shape",
    NullablePosition: "NullablePosition",
    NullableShape: "NullableShape"
}

// TODO: No type checks
export const AllStudyTypes: StudyType[] = ["Boolean", "Color", "NullableBoolean", "NullableColor", "NullableNumber", "NullableString", "Number", "String", "Position", "Shape","NullablePosition", "NullableShape" ]

export const ConvertToNonNullable: { [k in StudyType]: k extends NullableStudyType ? NullableToNonNullableMap[k] : k } = {
    Boolean: "Boolean", NullableBoolean: "Boolean", NullableNumber: "Number", Number: "Number",
    NullableString: "String",
    String: "String", Color: "Color", NullableColor: "Color",
    NullablePosition: "Position",
    Position: "Position",
    NullableShape: "Shape",
    Shape: "Shape"
}
export const NonNullableStudyTypes: NonNullableStudyType[] = Array.from(new Set(Object.values(ConvertToNonNullable)))

export const ConvertToNullable: { [k in StudyType]: k extends NonNullableStudyType ? Nullable<k> : k } = {
    Boolean: "NullableBoolean",
    NullableBoolean: "NullableBoolean",
    NullableNumber: "NullableNumber",
    Number: "NullableNumber",
    NullableString: "NullableString",
    String: "NullableString", Color: "NullableColor", NullableColor: "NullableColor",
    Position: "NullablePosition",
    NullablePosition: "NullablePosition",
    Shape: "NullableShape",
    NullableShape: "NullableShape"
}

export const ToggleNullable: {
    [k in StudyType]: k extends NonNullableStudyType ? Nullable<k> : k extends NullableStudyType ? NullableToNonNullableMap[k] : never
} = {
    Boolean: "NullableBoolean", NullableBoolean: "Boolean", NullableNumber: "Number", Number: "NullableNumber",
    NullableString: "String",
    String: "NullableString", Color: "NullableColor", NullableColor: "Color",
    Position: "NullablePosition",
    NullablePosition: "Position",
    Shape: "NullableShape",
    NullableShape: "Shape"
}

/**
 * A value of a StudyType.
 * */
export type StudyTypeValue<T extends StudyType = StudyType> = {
    type: T,
    value: StudyTypePrimitiveMap[T]
}

export const StudyValueConstructor: Constructor<StudyType, StudyTypeValue> = type => ({type, value: StudyTypeConstructors[type]()})
//TODO: Tidy up
export const _StudyValueConstructor = <S extends StudyType>(type: S, value: StudyTypePrimitiveMap[S]) => ({type, value})

export type OptionalStudyTypeValue<T extends StudyType = StudyType> = {
    type: T,
    value?: StudyTypePrimitiveMap[T]
}

/**
 * Type guard for StudyTypeValue.
 * */
export function checkStudyType<T extends StudyType>(type: T, value: StudyTypeValue<any> | OptionalStudyTypeValue<any>): value is StudyTypeValue<T> {
    return value.type === type
}

export const isNullable = (t: StudyType) => t.includes("Nullable")

export const StudyTypePrimitiveTypeGuards : {[k in keyof StudyTypePrimitiveMap]: TypeGuard<any, StudyTypePrimitiveMap[k]>} = {
    Boolean(arg: any): arg is boolean {
        return _.isBoolean(arg);
    },
    Color(arg: any): arg is string {
        // TODO: Add Format Check
        return _.isString(arg);
    },
    NullableBoolean(arg: any): arg is boolean | null {
        return _.isNull(arg) || _.isBoolean(arg);
    },
    NullableColor(arg: any): arg is string | null {
        return _.isNull(arg) || _.isString(arg);
    },
    NullableNumber(arg: any): arg is number | null {
        return _.isNull(arg) || _.isNumber(arg);
    },
    NullableString(arg: any): arg is string | null {
        return _.isNull(arg) || _.isString(arg);
    },
    Number(arg: any): arg is number {
        // TODO: Add Check for limits
        return _.isFinite(arg);
    },
    String(arg: any): arg is string {
        // TODO: Add Check for limits
        return _.isString(arg);
    },
    Position(arg: any): arg is "AboveBar" | "BelowBar" {
        return arg === "AboveBar" || arg === "BelowBar";
    },
    Shape(arg: any): arg is "ArrowUp" | "ArrowDown" {
        return arg === "ArrowUp" || arg === "ArrowDown";
    },
    NullablePosition(arg: any): arg is "AboveBar" | "BelowBar" | null {
        return _.isNull(arg) || arg === "AboveBar" || arg === "BelowBar";
    },
    NullableShape(arg: any): arg is "ArrowUp" | "ArrowDown" | null {
        return _.isNull(arg) || arg === "ArrowUp" || arg === "ArrowDown";
    }
}