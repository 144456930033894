import {ClockIcon} from "@heroicons/react/24/outline";
import React from "react";

type AnalyzerProps = {

}

export function Analyzer(props: AnalyzerProps){
    return (
        <div className="text-zinc-300 px-12">
            <div className="mt-8 text-2xl">Analyzer</div>
            <div className="text-zinc-400 mt-2 font-light italic">Analyze technical charts with the help of powerful tools
            </div>
            <ul className="font-sans pt-2 text-zinc-400 list-disc ">
                <li>Eon - Your AI assistant for market analysis</li>
                <li>Filter Charts based on days and time</li>
                <li>Add lines and signals based on conditions</li>
            </ul>
            <span className="text-zinc-400">and more...</span>
            <div className=" mt-8 font-light flex space-x-1 items-center"><span>Launching soon</span><span><ClockIcon
                className="h-5 w-5"/></span></div>

        </div>
    )
}