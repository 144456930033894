import {DbStyle} from "./DbStyle";
import {DbInput} from "./DbInput";
import {DbEquation} from "./DbEquation";
import {Study, StudyDependency} from "../Study";
import {Converters, TypeConverter} from "../../Converters";
import {Equation} from "../Equation";
import {tempContext} from "../../../crafter/data/Selectors";

/**
 * Type of study dependencies that will be saved in the database.
 * */
export type DbStudyDependency = {
    id: string,
    studyId: string,
    inputValues: Omit<DbInput, "isFixed">[]
}

/**
 * Type of study that will be saved in the database. Used to write study to the database. To read a study, use {@link ReadStudy}
 * */
export type DbStudy = {
    id: string,
    name: string,
    dependencies: DbStudyDependency[],
    inputs: DbInput[],
    equations: DbEquation[],
    styles: DbStyle[]
}

export const DbStudyConverter: TypeConverter<Study, DbStudy> =
    (item) =>  {
        const context = Converters.StudyContext.fromStudy(item)
        const eqConverter = (eq: Equation) => Converters.Equation.toDb(eq, tempContext(context, eq));
        return {
            id: item.id,
            name: item.name,
            dependencies: item.dependencies.map(DbStudyDependencyConverter), // TODO: Use from Converters
            inputs: item.inputs.map(Converters.Input.toDb),
            equations: item.equations.map(eqConverter),
            styles: item.styles.map(Converters.Style.toDb)
        }
    }

export const DbStudyDependencyConverter: TypeConverter<StudyDependency, DbStudyDependency> =
    ({id, studyId, inputValues}) => {
        return {
            id,
            studyId,
            inputValues: inputValues.map((iv) => ({name: iv.name, value: iv.value}))
        }
    }
