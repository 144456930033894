import {GetTimeframeRepresentation, Timeframe} from "../types/study/Config";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/16/solid";

export const defaultTimeframes: Timeframe[] = [
    { multiplier: 1, unit: "m" },
    { multiplier: 3, unit: "m" },
    { multiplier: 5, unit: "m" },
    { multiplier: 15, unit: "m" },
    { multiplier: 30, unit: "m" }
]

type TimeframeSelectorProps = {
    timeframe: Timeframe,
    setTimeframe: (t: Timeframe) => void
}

export function TimeframeSelector(props: TimeframeSelectorProps){
    return (
        <div className="">
            <Menu>
                <MenuButton
                    className="h-full w-16 items-center gap-2 bg-zinc-700/50 py-1.5 px-3 text-sm/6 text-zinc-200 outline-none data-[hover]:bg-zinc-700/80 data-[open]:bg-zinc-700">
                    {
                        props.timeframe ?
                            <>
                                {GetTimeframeRepresentation(props.timeframe)}
                            </>
                            :
                            <span className="italic font-light text-zinc-400">Connect a broker</span>
                    }
                </MenuButton>
                <MenuItems
                    transition
                    anchor="bottom start"
                    className="w-24 z-20 origin-top-right mt-1 rounded border border-white/5 bg-zinc-700 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                    {
                        defaultTimeframes.map(t =>
                            <MenuItem>
                                <button
                                    onClick={() => props.setTimeframe(t)}
                                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                    {GetTimeframeRepresentation(t)}
                                </button>
                            </MenuItem>
                        )
                    }
                    {/*<div className="my-1 h-px bg-white/5" />*/}
                </MenuItems>
            </Menu>
        </div>
    )
}