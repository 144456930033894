import {SimpleButton} from "../../../temp/SimpleButton";
import {CloseButton, Dialog, DialogPanel} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/16/solid";
import React from "react";
import {PrimaryButton} from "./PrimaryButton";

export type AppButtonDialogProps = {
    triggerTitle: string,
    title: string,
    isOpen: boolean,
    setIsOpen: (b: boolean) => void,
    children?: React.ReactNode,
    buttonType?: "simple" | "primary"
}

export function AppButtonDialog(props: AppButtonDialogProps) {
    return (
        <>
            {
                props.buttonType === "primary" ?
                    <PrimaryButton className="mt-4" text={props.triggerTitle} disabled={false} onClick={() => props.setIsOpen(true)}/>
                    :
                    <SimpleButton
                        className="ml-6 mt-4"
                        onClick={() => props.setIsOpen(true)}>
                        {props.triggerTitle}
                    </SimpleButton>
            }

            <Dialog
                open={props.isOpen}
                onClose={() => props.setIsOpen(false)}
                className="relative z-50">

                <div className="fixed inset-0 bg-black/50" aria-hidden="true"/>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className="w-80 max-w-80 rounded-lg bg-zinc-700 dark:text-zinc-200 p-8">
                        <div className="flex pb-4">
                            <span className="flex-1 min-w-56">{props.title}</span>
                            <CloseButton>
                                <div className="hover:dark:bg-zinc-800/75 p-0.5 rounded-md">
                                    <PlusIcon className="w-6 rotate-45 cursor-pointer"/>
                                </div>
                            </CloseButton>
                        </div>
                        {
                            props.children
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}

type AppDialogProps = {
    isOpen: boolean,
    onClose: (b: boolean) => void,
    title: string,
    children?: React.ReactNode,
}

export function AppDialog(props: AppDialogProps){
    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={() => props.onClose(false)}
                className="relative z-50">

                <div className="fixed inset-0 bg-black/50" aria-hidden="true"/>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=" rounded-lg bg-zinc-700 dark:text-zinc-200 p-8">
                        <div className="flex pb-4">
                            <span className="flex-1 min-w-56">{props.title}</span>
                            <CloseButton>
                                <div className="hover:dark:bg-zinc-800/75 p-0.5 rounded-md">
                                    <PlusIcon className="w-6 rotate-45 cursor-pointer"/>
                                </div>
                            </CloseButton>
                        </div>
                        {
                            props.children
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}