import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PrimaryButton} from "../crafter/components/ui/PrimaryButton";
import {AppButtonDialog} from "../crafter/components/ui/AppDialog";
import {Labeled} from "../crafter/components/ui/Labeled";
import {AppInput} from "../crafter/components/ui/AppInput";
import {uniqueId} from "lodash";
import {hasValidState} from "./data/HomeSlice";
import {useMainDispatch, useMainSelector} from "./data/Store";
import {auth, Auth} from "../firebase/auth";
import {ClockIcon} from "@heroicons/react/24/outline";
import {ApiResponse, getUserStudies} from "./data/Thunks";
import {TRALY_BACKEND_URL} from "../constants";

// #f5f5f5

// TODO: Move to proper folder
export async function getAuthorizationHeader(): Promise<{ Authorization: string }> {
    // TODO: take Token from store
    await auth.authStateReady();
    const token = await auth.currentUser?.getIdToken()
    return {
        Authorization: `Bearer ${token}`
    }
}

// TODO: Move to proper folder
export const _authorizedFetch: typeof fetch = async (input, init) => {
    const authHeader = await getAuthorizationHeader();
    return fetch(input, {
        ...init,
        headers: {
            ...init?.headers,
            ...authHeader
        }
    });
}

export async function createStudy(name: string) {
    const authHeader = await getAuthorizationHeader();
    return await fetch(`${TRALY_BACKEND_URL}/study/create?name=${name}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authHeader
        },
    })
}
export async function createDashboard(name: string) {
    const authHeader = await getAuthorizationHeader();
    return await fetch(`${TRALY_BACKEND_URL}/dashboard/create?name=${name}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authHeader
        },
    })
}

export function Home() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isDashboardDialogOpen, setIsDashboardDialogOpen] = useState(false)
    const [name, setName] = useState(uniqueId("Study-"));
    const [dashName, setDashName] = useState(uniqueId("Dashboard-"));
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useMainDispatch();
    const studies = useMainSelector(state => state.home.userStudies);
    const dashboards = useMainSelector(state => state.home.userDashboards);

    useEffect(() => {
        if (studies === "Idle")
            dispatch(getUserStudies());
    }, [studies, dispatch]);
    // useEffect(() => {
    //     if (dashboards === undefined)
    //         dispatch(getUserDashboards())
    // }, [dashboards, dispatch]);

    return (
        <>
            <div className="w-screen min-h-screen font-rubik dark:bg-zinc-800 dark:text-zinc-200">
                <div className="h-16 bg-zinc-700/50">

                </div>
                <div className="flex items-end">
                    <div className="flex-1"/>
                    <h2
                        onClick={() => navigate("/connect")}
                        className="cursor-pointer pr-4">Connect</h2>
                    <h2
                        onClick={() => Auth.signOut()}
                        className="underline cursor-pointer">Sign out</h2>
                </div>
                <div className="px-16 pt-12">

                <div className="mt-8 text-2xl">My Dashboards</div>
                <div className="text-zinc-400 mt-2 font-light italic">Build your own customised dashboards with realtime charts and powerful widgets, on a drag-drop interface.</div>
                <div className=" mt-8 font-light flex space-x-1 items-center"><span>Launching soon</span><span><ClockIcon className="h-5 w-5"/></span></div>

                {/*<ul className="flex space-x-8">*/}
                {/*    {*/}
                {/*    dashboards &&*/}
                {/*        dashboards.map((d) =>*/}
                {/*            <div className="p-2 hover:bg-zinc-600 rounded-lg cursor-pointer space-y-2">*/}
                {/*                <div*/}
                {/*                    className="w-48 h-48 bg-zinc-700 rounded-lg flex items-center justify-center"*/}
                {/*                    onClick={() => navigate(`/builder/${d.id}`)}>*/}
                {/*                    <ChartBarIcon className="w-12 text-zinc-200"/>*/}
                {/*                </div>*/}
                {/*                <div>{d.displayName}</div>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</ul>*/}
                {/*<AppButtonDialog buttonType={"primary"} triggerTitle={"Build a Dashboard"} title={"Build a Dashboard"}*/}
                {/*                 isOpen={isDashboardDialogOpen} setIsOpen={setIsDashboardDialogOpen}>*/}
                {/*    <Labeled label={"Name"}>*/}
                {/*        <AppInput*/}
                {/*            value={dashName}*/}
                {/*            onChange={(e) => setDashName(e.target.value)}*/}
                {/*            placeholder="name..."*/}
                {/*        />*/}
                {/*        <div className="flex justify-end">*/}
                {/*            <PrimaryButton text={"Start Building"} disabled={isLoading} onClick={() => {*/}
                {/*                setIsLoading(true)*/}
                {/*                createDashboard(dashName).then(async (res) => {*/}
                {/*                    const dt = await res.json()*/}
                {/*                    setIsLoading(false)*/}
                {/*                    setIsDashboardDialogOpen(false)*/}
                {/*                    navigate(`/builder/${dt.id}`)*/}
                {/*                })*/}
                {/*            }}/>*/}
                {/*        </div>*/}
                {/*    </Labeled>*/}
                {/*</AppButtonDialog>*/}
                <div className="h-px w-full mt-16 dark:bg-zinc-700"></div>
                <div className="mt-8 text-2xl pb-4">My Studies</div>
                {
                    hasValidState(studies) &&
                    studies.map((s: any) =>
                        <div
                            onClick={() => navigate(`/crafter/${s.id}`)}
                            className="p-2 bg-zinc-200/10 hover:bg-zinc-200/5 border-y-zinc-200/20 cursor-pointer border-y">{s.name}</div>
                    )
                }
                {
                    studies === "Loading" &&
                    <div className="flex justify-center">
                        <img className="size-12" src="/loading.svg" alt="Loading user studies"/>
                    </div>
                }

                <AppButtonDialog buttonType={"primary"} triggerTitle={"Craft a Study"} title={"Create a Study"}
                                 isOpen={isOpen} setIsOpen={setIsOpen}>
                    <Labeled label={"Name"}>
                        <AppInput
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="name..."
                        />
                        <div className="flex justify-end">
                            <PrimaryButton text={"Craft"} disabled={isLoading} onClick={() => {
                                setIsLoading(true)
                                // TODO: Use thunk
                                createStudy(name).then(async (res) => {
                                    const dt = await res.json()
                                    setIsLoading(false)
                                    setIsOpen(false)
                                    navigate(`/crafter/${dt.id}`)
                                })
                            }}/>
                        </div>
                    </Labeled>
                </AppButtonDialog>
                {/*<div className="h-px w-full mt-16 dark:bg-zinc-700"></div>
                <div className="mt-8 text-2xl">Tools</div>
                <div className="w-full mt-12 flex space-x-8 dark:text-zinc-200">
                    <Tool onClick={() => navigate("/builder/TEMP")} name="Builder" img="./b1.png"
                          desc="Build stunning and powerful dashboards tailored to your specific needs."><WrenchScrewdriverIcon
                        className="w-5"/> </Tool>
                    <Tool onClick={() => {}} name="Crafter" img="./b2.png"
                          desc="Craft those genius ideas of yours and bring them to the charts."><PresentationChartLineIcon
                        className="w-5"/> </Tool>
                    <Tool name="Keeper" img="./b3.png"
                          desc="Journalise and keep your analysis organised, structured and categorised."><BookOpenIcon
                        className="w-5"/> </Tool>
                </div>*/}
            </div>
            </div>
        </>
    )
}

function Tool(props: { name: string, img?: string, desc?: string, children?: React.ReactNode, onClick?: () => void }) {
    return (
        <div
            onClick={props.onClick}
            className="flex-1 hover:dark:bg-zinc-700/50 hover:shadow-lg p-3 rounded-xl">
            <div className="h-72 bg-zinc-700 rounded-xl">
                <img className="w-full h-full" src={props.img}
                     style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
            </div>
            <div className="mt-4 flex dark:text-zinc-200 items-center">
                <h1 className="text-2xl font-semibold mr-2">{props.name}</h1>
                {props.children}
            </div>
            <div>
                <h1 className="pr-2 pt-2 pb-1">{props.desc}</h1>
            </div>
        </div>
    )
}