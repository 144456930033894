import {ChartOptions} from "../temp/Builder";
import {CandleStickSeries, LineSeries} from "../lib/chart/Series";
import {CandlestickData, IChartApi, ISeriesApi, LineData, SeriesMarker} from "lightweight-charts";
import {Chart} from "../lib/chart/Chart";
import React, {useEffect, useRef, useState} from "react";
import {ParsedStyle, ParsedStyleMap} from "../types/study/utils/StyleParser";
import _ from "lodash";
import {TimeSeries} from "../types/study/Config";
import {SymbolSelector} from "./SymbolSelector";
import {BiSearch} from "react-icons/bi";
import {TimeframeSelector} from "./TimeframeSelector";
import {StyleType} from "../types/study/Style";

type BaseChartProps = {
    mainSeriesData: CandlestickData[],
    mainSeriesUpdate?: CandlestickData,
    parsedStyles: ParsedStyleMap,
    parsedStylesUpdate?: { styleId: string, styleType: StyleType, parsed: ParsedStyle[StyleType] }[],
    height: number,
    timeSeries: TimeSeries,
    setTimeSeries: (t: TimeSeries) => void
}

// TODO: Remove useeffects and implement imperative handle
export function BaseChart(props: BaseChartProps){
    const [isOpen, setIsOpen] = useState(false)

    const upRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<IChartApi>();
    const mainSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);

    const lineSeriesRef = useRef<Record<string, ISeriesApi<"Line">>>({});

    useEffect(() => {
        if (chartRef.current)
            chartRef.current.applyOptions({
                height: props.height - (upRef.current?.clientHeight ?? 0)
            })
    }, [props.height]);

    useEffect(() => {
        if (props.mainSeriesUpdate)
            mainSeriesRef.current?.update(props.mainSeriesUpdate);
    }, [props.mainSeriesUpdate]);

    const markers = useRef<ParsedStyle["Mark"][]>([]);
    useEffect(() => {
        const allMarkers = props.parsedStyles.Mark.reduce((acc, dt) => {
            return _.sortBy([...acc, ...dt.parsed], (d) => d.time)
        }, [] as ParsedStyle["Mark"][])
        markers.current = allMarkers
        mainSeriesRef.current?.setMarkers(allMarkers)
    }, [props.parsedStyles.Mark]);

    useEffect(() => {
        if (props.parsedStylesUpdate)
            props.parsedStylesUpdate.forEach((dt) => {
                if (dt.styleType === "Plot"){
                    lineSeriesRef.current[dt.styleId]?.update(dt.parsed as ParsedStyle["Plot"])
                }
                else if(dt.styleType === "Mark"){
                    markers.current.push(dt.parsed as ParsedStyle["Mark"])
                    mainSeriesRef.current?.setMarkers(markers.current)
                }
            })
    }, [props.parsedStylesUpdate]);

    return (
        <div className="">
            <div ref={upRef} className="h-10 flex border-b border-zinc-700">
                <div onClick={() => setIsOpen(true)}  className="rounded-tl bg-zinc-700/50 flex px-4 space-x-2 items-center cursor-pointer hover:bg-zinc-700/80">
                    <BiSearch className="text-zinc-300 size-4"/>
                    <div className="min-w-24 text-zinc-300">{props.timeSeries.instrument.symbol}</div>
                </div>
                <div className="w-px bg-zinc-600"></div>
                <TimeframeSelector timeframe={props.timeSeries.timeframe} setTimeframe={(t) => {
                    props.setTimeSeries({
                        ...props.timeSeries,
                        timeframe: t
                    })
                }}/>
            </div>
            <Chart
                ref={chartRef}
                className={`h-full flex`}
                   options={ChartOptions}>
                <CandleStickSeries ref={mainSeriesRef} initialData={props.mainSeriesData} data={props.mainSeriesData} />
                {
                    props.parsedStyles.Plot.map((dt) =>
                        <LineSeries
                            ref={(r) => {
                                if (r)
                                    lineSeriesRef.current[dt.styleId] = r
                                else
                                    delete lineSeriesRef.current[dt.styleId]
                            }}
                            options={{
                                // @ts-ignore TODO
                                lineWidth: parseInt(dt.statics.LineWidth),
                                lineStyle: parseInt(dt.statics.LineStyle),
                                lineType: parseInt(dt.statics.LineType),
                                crosshairMarkerVisible:false
                            }}
                            data={dt.parsed}
                        />
                    )
                }
            </Chart>
            <SymbolSelector
                onSymbolSelected={(i) => {
                    props.setTimeSeries({
                        ...props.timeSeries,
                        instrument: i
                    })
                }}
                isOpen={isOpen} setIsOpen={setIsOpen}/>
        </div>
    )
}