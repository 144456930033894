import {ClockIcon} from "@heroicons/react/24/outline";
import React from "react";

type DashboardsProps = {

}

export function Dashboards(props: DashboardsProps){
    return (
        <div className="text-zinc-300 px-12">
            <div className="mt-8 text-2xl">Dashboards</div>
            <div className="text-zinc-400 mt-2 font-light italic">Build your own customised dashboards with realtime
                charts and powerful widgets, on a drag-drop interface.
            </div>
            <ul className="font-sans pt-2 text-zinc-400 list-disc ">
                <li>Design your dashboard with realtime charts and widgets</li>
                <li>Add built-in and custom studies to your charts</li>
                <li>Connect and use powerful customizable widgets </li>
            </ul>
            <span className="text-zinc-400">and more...</span>
            <div className=" mt-8 font-light flex space-x-1 items-center"><span>Launching soon</span><span><ClockIcon
                className="h-5 w-5"/></span></div>

        </div>
    )
}