import {v4 as uuidv4} from "uuid";
import {StudyType} from "../types/study/StudyType";
import {Block, BlockType, OperatorType} from "../types/study/Block";
import {Operator} from "../types/study/Operators";
import {Value} from "../types/study/Values";
import {TypeGuards} from "../types/Guards";


// TODO: Important check for Expression No. of operands = No. of operators + 1

/**
 * A Referent is an object that needs to be referenced by other objects. The object itself may not have an id in the
 * backend (example: Styles, Inputs).
 * */
export type Referent<T> = {
    _id: string,
} & T

/**
 * A generic constructor for {@link Referent}.
 * @param item
 * @param id - The id to use, if not defined, a unique id will be generated.
 * */
export function ReferentConstructor<T>(item: T, id?: string): Referent<T> {
    return {
        _id: id ?? uniqueId(),
        ...item
    }
}

/**
 * A Referenced object is an object that references to a referent. Ideally, must be used with {@link Referent}.
 * */
export type Referenced<T> = {
    refId: string,
    refValue: T
}

export function ReferencedConstructor<R, T>(referent: Referent<R>, item: T): Referenced<T> {
    return {
        refId: referent._id,
        refValue: item
    }
}

export const MappedReferencedConstructor = <T, R>(mapFn: (t: Referent<R>) => T) => (t: Referent<R>) => ReferencedConstructor(t, mapFn(t))

export function getType(v: StudyType | Referenced<StudyType>): StudyType {
    if (typeof v === "string")
        return v
    else return v.refValue
}


export type BlockWithExpectedType<T extends Block<any>> = {
    expected: Referenced<StudyType>,
    block: T
}
// TODO: Review
export function toPostfix(blocks: Block<BlockType>[]): Block<BlockType>[] {
    const ops: Operator.Type<OperatorType>[] = [];
    const postfix: Block<BlockType>[] = [];

    blocks.forEach((b) => {
        if (TypeGuards.Block.Value(b)) {
            // value
            postfix.push(b)
        } else if (TypeGuards.Block.Operator(b)) {
            const last = ops.at(ops.length - 1)
            // TODO: < or <=
            if (!last || last.precedence < b.precedence) ops.push(b)
            else {
                // empty ops to postfix
                ops.reverse()
                ops.forEach((op) => postfix.push(op))
                ops.splice(0, ops.length)
                // append ops
                ops.push(b)
            }
        }
    })
    ops.reverse()
    ops.forEach((op) => postfix.push(op))
    return postfix
}

export function topDown(topLevelExpected: Referenced<StudyType>, exp: Value.Composite.Expression.Type): { [blockId: string]: Referenced<StudyType> } {
    const postfix = toPostfix(exp.value);
    return postfix.reduce((acc, b, idx, arr) => {
        const nextOp = arr.slice(idx + 1).find((b1) => TypeGuards.Block.Operator(b1)) as Operator.Type<OperatorType>
        const expectedType: Referenced<StudyType> = nextOp ? {
            refId: nextOp.id,
            refValue: nextOp.validOutTypes[0] // TODO
        } : topLevelExpected
        return {
            ...acc,
            [b.id]: expectedType
        }
    }, {})
}

// Type -> Valid
// undefined -> missing
// false -> mismatch
export function calcType(blocks: Block<BlockType>[]) {
    const postfix = toPostfix(blocks);
    const res: StudyType[] = []

    postfix.forEach((b) => {

    })
}

//
// export types ConditionalValues =  {
//     conditionalValues: ConditionalValue[],
//     elseValue: Expression
// }
//
// export class ConditionalMultiCompositeValue extends Value {
//     displayType: string = "TODO"
//     displayValue: string = "Condition";
//     returnType: StudyType | undefined;
//     types: BlockType = "Conditional"
//     override value: ConditionalValues;
//
//     constructor(value: ConditionalValues) {
//         super();
//         this.value = value;
//     }
//
//     addConditionalValue(condition: Expression, value: Expression): ConditionalMultiCompositeValue {
//         return new ConditionalMultiCompositeValue({
//             ...this.value,
//             conditionalValues: [...this.value.conditionalValues, { id: uniqueId(), condition: condition, value: value }],
//         })
//     }
//
//     updateCondition(id: string, condition?: Expression, value?: Expression): ConditionalMultiCompositeValue {
//         return new ConditionalMultiCompositeValue({
//             ...this.value,
//             conditionalValues: this.value.conditionalValues.map((cv): ConditionalValue =>
//                 cv.id === id ? { ...cv, condition: condition ?? cv.condition, value: value ?? cv.value }: cv
//             )
//         })
//     }
//
//     updateElseValue(value: Expression): ConditionalMultiCompositeValue {
//         return new ConditionalMultiCompositeValue({
//             ...this.value,
//             elseValue: value
//         })
//     }
//
//     static singleConditionalValue(condition: Expression, value: Expression, elseValue: Expression): ConditionalMultiCompositeValue {
//         return new ConditionalMultiCompositeValue({
//             conditionalValues: [{ id: uniqueId(), condition: condition, value: value }],
//             elseValue: elseValue
//         })
//     }
//
//     getJson(): any {
//         return {
//             types: this.types,
//             conditionalValues: this.value.conditionalValues.map((cv) => {
//                 return {
//                     condition: cv.condition.getJson(),
//                     value: cv.value.getJson()
//                 }
//             }),
//             elseValue: this.value.elseValue.getJson()
//         }
//     }
// }


// TODO: Maybe use nanoId
export function uniqueId(): string {
    return uuidv4();
}