import React, {forwardRef, useEffect, useMemo, useState} from "react";
import clsx from "clsx";
import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/16/solid";
import {BlockItem} from "./BlockItem";
import {TypeGuards} from "../types/Guards";
import {Constructors} from "../types/Constructors";
import {Value} from "../types/study/Values";

// Empty -> ""
// SingleValue -> "SingleValue"
//

type BlockComboBoxProps = {
    items: BlockItem[],
    selected: string,
    setSelected: (item: BlockItem) => void,
    keyValue: (item: BlockItem) => string,
    className?: string,
    placeHolder?: string,
    onContainerClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
    textStyle?: string,
    onClear?: () => void,
    alwaysOn?: boolean,
    onDelete?: () => void
}

export function BlockComboBox(props: BlockComboBoxProps) {
    const getDisplayValue = ((item: string | BlockItem) => typeof item === "string" ? item : item.itemName)
    const [val, setVal] = useState(props.selected);
    const [query, setQuery] = useState("");
    const getKey = props.keyValue
    const filtered =
        query === ""
            ? props.items
            : props.items.filter((item) => {
                return getDisplayValue(item.itemName).toLowerCase().includes(query.toLowerCase())
            })

    const [isFocused, setIsFocused] = useState(false);

    return (
        <div onClick={props.onContainerClick}
            className={clsx("", props.className)}>
            <Combobox as={"div"} className="flex" value={props.selected} onChange={(item) => {
                if (item) {
                    props.setSelected(item)
                    setVal(getDisplayValue(item))
                }
            }}>
                <div className={clsx("", props.alwaysOn? "bg-zinc-800/30": "",(isFocused && val !== "") ? "border border-zinc-400 bg-zinc-800/30" : "")}>
                    <ComboboxInput
                        className={clsx(
                            'rounded-lg bg-transparent text-center border-none py-1 text-md focus:text-zinc-200 outline-none',
                            props.textStyle
                        )}
                        onFocus={(e) => {
                            setIsFocused(true)
                            e.target.select()
                        }}
                        onBlur={() => {
                            setIsFocused(false)
                            setVal(getDisplayValue(props.selected))
                        }}
                        style={{width: (val.length+1) + "ch"}}
                        placeholder={props.placeHolder}
                        displayValue={getDisplayValue}
                        onChange={(event) => {
                            if (event.target.value === ""){
                                props.onClear?.()
                            }
                            setQuery(event.target.value)
                            setVal(event.target.value)
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Backspace" && val === "") {
                                // Execute desired action here
                                props.onDelete?.()
                            }
                        }}
                    />
                </div>
                <Transition
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <ComboboxOptions
                        anchor="bottom start"
                        className="w-48 z-50 mt-1 rounded-xl border border-white/5 bg-zinc-600 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                    >
                        {/* Dynamic configurables for Number constant value */}
                        {
                            query.length > 0 && TypeGuards.StudyTypePrimitives.Number(Number(query)) &&
                            <ComboboxOption
                                key={query}
                                value={{
                                    itemName: query,
                                    blockFactory: () => Constructors.Block.Constant({value: {type:"Number", value: Number(query)}})
                                }}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible"/>
                                <div className="text-sm/6 text-white">{query}</div>
                            </ComboboxOption>
                        }

                        {filtered.map((item) => (
                            <ComboboxOption
                                key={getKey(item)}
                                value={item}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible"/>
                                <div className="text-sm/6 text-white">{getDisplayValue(item.itemName)}</div>
                            </ComboboxOption>
                        ))}
                        {
                            filtered.length === 0 &&
                            <ComboboxOption
                                key={"No Option Available"}
                                value={undefined}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none "
                            >
                                <div className="text-sm/6 italic text-zinc-200/80">{"Not Available"}</div>
                            </ComboboxOption>
                        }
                    </ComboboxOptions>
                </Transition>
            </Combobox>
        </div>
    )
}

type TempProps = {
    isVisible: boolean,
    items: BlockItem[]
    setSelected: (item: BlockItem) => void,
    className?: string,
    onContainerClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const ClearableBlockComboBox = forwardRef<HTMLInputElement, TempProps>((props, ref) => {
    const [abc, setAbc] = useState(undefined);
    const [val, setVal] = useState<string>("");
    const [query, setQuery] = useState("");
    const filtered =
        query === ""
            ? props.items
            : props.items.filter((item) => {
                return item.itemName.toLowerCase().includes(query.toLowerCase());
            });

    const [isFocused, setIsFocused] = useState(false)

    return (
        <div onClick={props.onContainerClick} className={clsx("", props.className, props.isVisible ? "" : "hidden")}>
            <Combobox
                onClose={() => setVal("")}
                value={abc}
                onChange={(item) => {
                    if (item) {
                        props.setSelected(item);
                        setVal("");
                        setAbc(undefined);
                    }
                }}
            >
                <div className="relative mx-1">
                    <div className={clsx(" ", props.isVisible ? "bg-zinc-800/50" : "", isFocused ? "border border-zinc-400" : "")}>
                        <ComboboxInput
                            ref={ref}
                            onFocus={() => {
                                setIsFocused(true)
                            }}
                            onBlur={() => {
                                setIsFocused(false)
                            }}
                            className={clsx('text-center italic py-1.5 bg-transparent text-sm/6 text-white outline-none')}
                            style={{ width: (val.length + 1) + "ch" }}
                            value={val}
                            displayValue={(i?: BlockItem) => i?.itemName ?? ""}
                            onChange={(event) => {
                                setQuery(event.target.value);
                                setVal(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <Transition
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <ComboboxOptions
                        anchor="bottom start"
                        className="w-48 z-50 mt-1 rounded-xl border border-white/5 bg-zinc-600 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                    >
                        {/* Dynamic configurables for Number constant value */}
                        {
                            val.length > 0 && TypeGuards.StudyTypePrimitives.Number(Number(val)) &&
                            <ComboboxOption
                                key={`ConstantNumber-${val}`}
                                value={{
                                    itemName: val,
                                    blockFactory: () => Constructors.Block.Constant({value: {type:"Number", value: Number(val)}})
                                }}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible"/>
                                <div className="text-sm/6 text-white">{val}</div>
                            </ComboboxOption>
                        }
                        {filtered.map((item) => (
                            <ComboboxOption
                                key={item.uid}
                                value={item}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible"/>
                                <div className="text-sm/6 text-white">{item.itemName}</div>
                            </ComboboxOption>
                        ))}
                        {/*{
                            filtered.length === 0 &&
                            <ComboboxOption
                                key={"No Option Available"}
                                value={undefined}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none "
                            >
                                <div className="text-sm/6 italic text-zinc-200/80">{"Not Available"}</div>
                            </ComboboxOption>
                        }*/}
                    </ComboboxOptions>
                </Transition>
            </Combobox>
        </div>
    );
});