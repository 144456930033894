import {StudyType, StudyTypeValue} from "./StudyType";
import {Value} from "./Values";
import {Operator} from "./Operators";
import {ValuesOf} from "../../lib/utils/Type";
import {TypeGuard} from "../Guards";
import _ from "lodash";
import {Constructor} from "../Constructors";
import {DbBlock} from "./db/DbBlock";
import {DbTypeConverters, DbTypeConvertersWithExtras} from "../Converters";
import {StudyContext, EquationScopedStudyContext} from "./StudyContext";

type SingleValueTypeMap = {
    Constant: StudyTypeValue,
    Series: Value.Single.SeriesValue.SeriesElement,
    Study: string
}
type CompositeValueTypeMap = {
    Empty: undefined, // TODO: Place empty somewhere better
    BinaryComposite: {
        op: Value.Composite.BinaryComposite.Operations
        lhs: Block<BlockType>, // TODO: Replace blocks with values
        rhs: Block<BlockType>
    },
    Expression: Block<BlockType>[],
    ConditionalValue: {
        conditions: {
            condition: Block<BlockType>,
            value: Block<BlockType>
        }[],
        elseValue: Block<BlockType>
    }
}
type OperatorTypeMap = {
    BooleanOperator: Operator.Logical.LogicalOperation,
    ComparisonOperator: Operator.Comparison.ComparisonOperation,
    ArithmeticOperator: Operator.Arithmetic.ArithmeticOperation
}
export type SingleValueType = keyof SingleValueTypeMap
export const SingleValueTypes: ValuesOf<SingleValueType> = ["Constant", "Series", "Study"];

export type CompositeValueType = keyof CompositeValueTypeMap
export const CompositeValueTypes: ValuesOf<CompositeValueType> = ["Expression", "Empty", "BinaryComposite", "ConditionalValue"];

export type OperatorType = keyof OperatorTypeMap
export const OperatorTypes: ValuesOf<OperatorType> = ["BooleanOperator", "ComparisonOperator", "ArithmeticOperator"];

export type BlockType = SingleValueType | CompositeValueType | OperatorType
export type BlockTypeMap = SingleValueTypeMap & CompositeValueTypeMap & OperatorTypeMap

export interface Block<T extends BlockType> {
    id: string,
    type: T,
    value: BlockTypeMap[T]
}

export function getTypeGuard<OUT extends Block<any>>(typeToCheck: OUT["type"]): TypeGuard<Block<any>, OUT> {
    return (b): b is OUT => b.type === typeToCheck
}

type BlockFunctionsConstructorArgs<T extends Block<BlockType>> = { id?: string, value: T["value"] }
export interface BlockFunctions<T extends Block<BlockType>, C extends BlockFunctionsConstructorArgs<T> = BlockFunctionsConstructorArgs<T>> {
    constructor: Constructor<C, T>
    converters: DbTypeConvertersWithExtras<T, DbBlock<T["type"]>, EquationScopedStudyContext, EquationScopedStudyContext>
    checkType: TypeGuard<Block<BlockType>, T>
    /**
     * Calculates the outStudyType, or undefined in case of Type Mismatch or Missing Value
     * */
    // getOutStudyType: T extends Block<OperatorType> ? (context: StudyContext, b: T, v1: Block<BlockType>, v2: Block<BlockType>) => StudyType | undefined : (context: StudyContext, b: T) => StudyType | undefined
}

export const AllBlockFunctions = {
    Series: Value.Single.SeriesValue.functions,
    Study: Value.Single.StudyValue.functions,
    Constant: Value.Single.ConstantValue.functions,
    Empty: Value.Composite.EmptyBlock.functions,
    Expression: Value.Composite.Expression.functions,
    ArithmeticOperator: Operator.Arithmetic.functions,
    ComparisonOperator: Operator.Comparison.functions,
    BooleanOperator: Operator.Logical.functions,
    BinaryComposite: Value.Composite.BinaryComposite.functions,
    ConditionalValue: Value.Composite.ConditionalValue.functions
} as const;
type AllBlockFunctionsType = typeof AllBlockFunctions

export const BlockConstructors = _.mapValues(AllBlockFunctions, f => f.constructor) as { [k in BlockType]: AllBlockFunctionsType[k]["constructor"]};
export const BlockConverters = _.mapValues(AllBlockFunctions, f => f.converters) as { [k in BlockType]: AllBlockFunctionsType[k]["converters"] };
// export const BlockGetOutStudyType = _.mapValues(AllBlockFunctions, f => f.getOutStudyType) as { [k in BlockType]: AllBlockFunctionsType[k]["getOutStudyType"] };
//
// const UnsafeBlockGetOutStudyType = _.mapValues(AllBlockFunctions, f => f.getOutStudyType) as Record<BlockType, BlockFunctions<Block<BlockType>>["getOutStudyType"]>;
// export function GetValueOutStudyType(context: StudyContext, block: Block<SingleValueType | CompositeValueType>): StudyType | undefined {
//     return UnsafeBlockGetOutStudyType[block.type](context, block)
// }
// export function GetOperatorOutStudyType(context: StudyContext, block: Block<OperatorType>, v1: Block<BlockType>, v2: Block<BlockType>): StudyType | undefined {
//     return UnsafeBlockGetOutStudyType[block.type](context, block, v1, v2)
// }

export const UnsafeBlockConverters = _.mapValues(AllBlockFunctions, f => f.converters) as { [k in BlockType]: DbTypeConvertersWithExtras<Block<BlockType>, DbBlock<BlockType>, StudyContext, StudyContext> };

export const BlockTypeGuards = _.mapValues(AllBlockFunctions, f => f.checkType) as { [k in BlockType]: AllBlockFunctionsType[k]["checkType"] };
