import React from "react";

export function SimpleButton(props: { className?: string, children?: React.ReactNode, onClick?: () => void }) {
    return (
        <>
            <div
                className={"w-fit px-3 py-2 rounded-md cursor-pointer dark:bg-zinc-700/70 hover:dark:bg-zinc-700/40 dark:text-zinc-200 hover:dark:text-zinc-400 " + props.className}
                onClick={props.onClick}>
                {props.children}
            </div>
        </>
    )
}