import {BuildStudyDependencyRecursiveFn, Study} from "./Study";
import {TypeConverter} from "../Converters";
import {MappedReferencedConstructor, Referenced} from "../../crafter/Utils";
import {ReadStudyParam, ReadStudyParamConverters} from "./ReadStudyParam";
import {ArrayUtils} from "../../lib/utils/Lodash";


export type StudySeries = {
    inputs: Referenced<ReadStudyParam>[],
    otherStudyInputs: Referenced<ReadStudyParam>[],
    otherStudyEquations: Referenced<ReadStudyParam>[],
    equations: Referenced<ReadStudyParam>[]
}

export type StudyContext = {
    study: {
        id: string,
        name: string
    },
    deps: {
        series: StudySeries,
        allSeries: Referenced<ReadStudyParam>[]
    }
}

export type EquationScopedStudyContext = StudyContext & {
    selfEquation: Referenced<ReadStudyParam>
}

export const StudyContextFunctions = {
    findSeriesBy: (context: EquationScopedStudyContext, predicate: (d: Referenced<ReadStudyParam>) => boolean) => {
        return [...context.deps.allSeries, context.selfEquation].find(predicate)
    },
    findSeriesById: (context: StudyContext, refId: string) => ArrayUtils.findByField(context.deps.allSeries, "refId", refId),
    fromStudy: ((study: Study): StudyContext => {
        const series = StudySeriesConverter(study)
        return {
            study: {
                id: study.id,
                name: study.name
            },
            deps: {
                series: series,
                allSeries: ReadStudyParamConverters.fromStudySeries(series)
            },
        }
    }) as TypeConverter<Study, StudyContext>,
} as const;

export const StudySeriesConverter: TypeConverter<Study, StudySeries> =
    study => {
        const currentStudyInputs: Referenced<ReadStudyParam>[] =
            study.inputs.map(MappedReferencedConstructor(i => ({paramName: i.name, studyDepId: study.id, valueType: i.value.type})))

        const depInputs: Referenced<ReadStudyParam>[] =
            study.dependencies.map(BuildStudyDependencyRecursiveFn(
                d1 => d1.inputs.map(
                    MappedReferencedConstructor(i => ({paramName: i.name, studyDepId: d1.id, valueType: i.value.type}))
                ))
            ).flat(2)

        // const depEquationsX: Referenced<ReadStudyParam>[] =
        //     study.dependencies.map(d =>
        //         MapStudyDependencyRecursively(d,
        //                 d1 => d1.equations.map(
        //                     MappedReferencedConstructor(({paramName, studyDepId, valueType}) => ({paramName, studyDepId, valueType}))
        //                 )
        //         ).flat()
        //     ).flat()

        const depEquations: Referenced<ReadStudyParam>[] =
            study.dependencies.map(BuildStudyDependencyRecursiveFn(
                d1 => d1.equations.map(
                    MappedReferencedConstructor(({paramName, studyDepId, valueType}) => ({paramName, studyDepId, valueType}))
                )
            )).flat(2)


        /**
         * For current study equations, the referent of the study will be itself.
         * */
        const currentStudyEquations: Referenced<ReadStudyParam>[] = study.equations.map(
            MappedReferencedConstructor(eq => ({paramName: eq.name, studyDepId: study.id, valueType: eq.valueType}))
        )

        return {
            inputs: currentStudyInputs,
            otherStudyInputs: depInputs,
            otherStudyEquations: depEquations,
            equations: currentStudyEquations
        }
    }