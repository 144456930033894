import {OptionalStudyTypeValue, StudyType, StudyTypeValue} from "../../types/study/StudyType";
import {PencilIcon, PlusIcon, TrashIcon} from "@heroicons/react/16/solid";
import React, {useMemo, useState} from "react";
import {uniqueId} from "../Utils";
import {useCrafterDispatch, useCrafterSelector} from "../data/Store";
import {deleteInput, setInput} from "../data/Slice";
import {AppButtonDialog} from "../components/ui/AppDialog";
import {Labeled} from "../components/ui/Labeled";
import {AppInput} from "../components/ui/AppInput";
import {NonNullableTypeSelector} from "../components/TypeSelector";
import {TypeEditor} from "../components/TypeEditor";
import {PrimaryButton} from "../components/ui/PrimaryButton";
import {selectContext} from "../data/Selectors";
import {Input} from "../../types/study/Input";
import {Constructors} from "../../types/Constructors";


// Dialog to add/edit input values
// Show List of inputs
// Name, Type Default value

// Controls - Edit, Delete
export function InputComponent() {
    const [hovered, setHovered] = useState<Input<any>>()
    const [isOpen, setIsOpen] = useState(false);
    const [initialInput, setInitialInput] = useState<Input<any>>();

    const inputs = useCrafterSelector(state => state.crafter.study.inputs)

    const dispatch = useCrafterDispatch();

    return (
        <div className="bg-zinc-800 flex flex-col pb-4 h-full">
            <ul className="flex-1">
                {
                    inputs.length === 0 ?
                        <div className="flex-1 italic text-zinc-400 p-4">No Inputs Added</div>
                    :
                    inputs.map((i) =>
                        <li onMouseOver={() => {
                            setHovered(i)
                        }} onMouseOut={() => {
                            setHovered(undefined)
                        }} className="space-x-4 dark:text-zinc-200 flex">
                            <span>{i.name}</span>
                            <span className="dark:text-zinc-200/60">{i.value.type}</span>
                            <span className="dark:text-zinc-200/80">{String(i.value.value)}</span>
                            {
                                hovered?.name === i.name &&
                                <div className="flex pl-4 dark:text-zinc-400">
                                    <PencilIcon onClick={() => {
                                        setIsOpen(true)
                                        setInitialInput(i)
                                    }} className="w-5 cursor-pointer"/>
                                    <PlusIcon onClick={() => dispatch(deleteInput(i._id))}
                                              className="w-5 rotate-45 cursor-pointer"/>
                                </div>
                            }
                        </li>
                    )
                }
            </ul>
            <div className="flex px-4">
                <div className="flex-1"></div>
                <AppButtonDialog triggerTitle="Add Input" title="Add Input" isOpen={isOpen} setIsOpen={(b) => {
                    setIsOpen(b)
                    if (!b) setInitialInput(undefined);
                }}>
                    {/*<InputEditor index={inputs.length + 1} initialInput={initialInput}*/}
                    {/*             setInput={(i) => {*/}
                    {/*                 dispatch(setInput(i))*/}
                    {/*                 setIsOpen(false)*/}
                    {/*                 setInitialInput(undefined);*/}
                    {/*             }}/>*/}
                </AppButtonDialog>
            </div>
        </div>
    )
}

type InputEditorProps = {
    index: number,
    initialInput?: Input,
    setInput: (i: Input) => void,
    deleteInput: (i: Input) => void
}

export function InputEditor(props: InputEditorProps) {
    const [value, setValue] = useState<OptionalStudyTypeValue>(props.initialInput?.value ?? Constructors.StudyValue("Number"))
    const [selectedType, setSelectedType] = useState<StudyType>(props.initialInput?.value.type ?? "Number");
    const [name, setName] = useState(props.initialInput?.name ?? ("Input " + props.index));
    const context = useCrafterSelector(selectContext)

    const nameError = useMemo(() => {
        if (name === "")
            return "Name cannot be empty."
        else if (name === props.initialInput?.name)
            return undefined
        else if (context.deps.allSeries.filter(({refValue}) => refValue.paramName === name && refValue.studyDepId === context.study.id).length > 0)
            return "Name already exists."
    }, [name]);

    const valueError = useMemo(() => {
        if (value.value === undefined)
            return "Value cannot be empty."
    }, [value]);

    const isValid = useMemo(() => {
        return nameError === undefined && valueError === undefined
    }, [nameError, valueError]);

    return (
        <>
            <div className="space-y-4">
                <Labeled label="Name">
                    <AppInput
                        autoFocus
                        value={name} onChange={(e) => setName(e.target.value)} error={nameError}/>
                </Labeled>
                <div className="flex space-x-2">
                    <Labeled label="Type">
                        <NonNullableTypeSelector selected={selectedType} setSelected={(t) => {
                            setSelectedType(t)
                            setValue(Constructors.StudyValue(t))
                        }}/>
                    </Labeled>
                    <Labeled label="Default Value" className="w-32">
                        <TypeEditor value={value} setValue={setValue} showInvalidError/>
                    </Labeled>
                </div>
                <div className="flex justify-end pt-8">
                    {/*TODO: Proper Button */}
                    {
                        props.initialInput &&
                        <button
                            onClick={() => {
                                if (props.initialInput)
                                    props.deleteInput(props.initialInput)
                            }}
                            className="text-zinc-200 inline-flex data-[disabled]:bg-gray-500 items-center gap-2 rounded-md dark:bg-red-400 py-1.5 px-2 text-md shadow-inner shadow-white/10 focus:outline-none data-[hover]:dark:bg-[#5F57FF]/70 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                            <TrashIcon className="w-4 h-4"/>
                        </button>
                    }
                    <div className="flex-1"></div>
                    <PrimaryButton text={props.initialInput ? "Apply": "Add"} disabled={!isValid} onClick={() => {
                        if (value.value !== undefined)
                            props.setInput(
                                {
                                    value: value as StudyTypeValue,
                                    _id: props.initialInput?._id ?? uniqueId(),
                                    name: name,
                                    isFixed: true
                                }
                            )
                        else {
                            // TODO: Show error
                        }
                    }}/>
                </div>
            </div>
        </>
    )
}

