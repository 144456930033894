import {StudyType} from "./StudyType";
import {ValuesOf} from "../../lib/utils/Type";
import {Value} from "./Values";
import {Referent, ReferentConstructor, uniqueId} from "../../crafter/Utils";
import {Constructor, Constructors} from "../Constructors";

export type CalculationMode = "OnEachBar" | "OnFirstBar"
export const CalculationModes: ValuesOf<CalculationMode> = ["OnEachBar", "OnFirstBar"];

export type Equation = Referent<{
    name: string,
    valueType: StudyType,
    mode: CalculationMode,
    expression: Value.Composite.Expression.Type
}>

export const EquationConstructor: Constructor<{name: string, mode: CalculationMode, valueType: StudyType}, Equation> = ({name, mode, valueType}) => {
    return ReferentConstructor({ name, mode, valueType, expression: Constructors.Block.Expression({value: []}) })
}