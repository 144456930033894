import {ValuesOf} from "../../lib/utils/Type";
import {Referent, uniqueId} from "../../crafter/Utils";
import {Constructor, Constructors} from "../Constructors";
import {ConfigurableOption, ConfigurableOptionConstructor} from "../configurables/ConfigurableOption";
import {ConfigurableType} from "../configurables/ConfigurableType";
import {LineStyle, LineType} from "lightweight-charts";


export type StyleTypeOptionsMap = {
    Plot: {
        Series: ConfigurableOption<[ConfigurableType<"Study", "Number">]>,
        Color: ConfigurableOption<[ConfigurableType<"Constant", "Color">, ConfigurableType<"Study", "Color">]>
    },
    Mark: {
        Series: ConfigurableOption<[ConfigurableType<"Study", "Boolean">]>,
        Color: ConfigurableOption<[ConfigurableType<"Constant", "Color">, ConfigurableType<"Study", "Color">]>,
        Position: ConfigurableOption<[ConfigurableType<"Constant", "Position">, ConfigurableType<"Study", "Position">]>,
        Shape: ConfigurableOption<[ConfigurableType<"Constant", "Shape">, ConfigurableType<"Study", "Shape">]>
    }
};
export type StaticOptionItem<V, R extends string> = { value: V, rep: R };
export type StaticOption<OP extends StaticOptionItem<any, any>[]> = {
    validValues: OP,
    value: OP[number]
}
// key -> options -> option { value:string, rep:string }[]
export type StyleTypeStaticOptionsMap = {
    Plot: {
        LineWidth: StaticOption<[StaticOptionItem<"1", "1">, StaticOptionItem<"2", "2">, StaticOptionItem<"3", "3">, StaticOptionItem<"4", "4">]>,
        LineStyle: StaticOption<[StaticOptionItem<"0", "Solid">, StaticOptionItem<"1", "Dotted">, StaticOptionItem<"2", "Dashed">, StaticOptionItem<"3", "Large Dashed">, StaticOptionItem<"4", "Sparse Dotted">]>,
        LineType: StaticOption<[StaticOptionItem<"0", "Simple">, StaticOptionItem<"1", "With Steps">, StaticOptionItem<"2", "Curved">]>,
    },
    Mark: {

    }
}
export type StyleType = keyof StyleTypeOptionsMap;
export type StyleOptionKeys<T extends StyleType = StyleType> = keyof StyleTypeOptionsMap[T];
export const StyleTypes: ValuesOf<StyleType> = ["Plot", "Mark"];

/**
 * Base Style
 * */
export type Style<T extends StyleType = StyleType> = Referent<{
    /**
     * Name for the style
     * */
    name: string,

    /**
     * Type of the style.
     * */
    type: T,

    /**
     * Configurable Options for the style
     * */
    configurableOpts: StyleTypeOptionsMap[T],

    staticOpts: StyleTypeStaticOptionsMap[T]
}>


// TODO: Move to options file
export type GetConfigurableValues<T extends {[k: string]: ConfigurableOption<any>}> = { [key in keyof T]: T[key]["value"] }

export type StyleTypeConfigurableValues = {
    [key in StyleType]: Partial<GetConfigurableValues<StyleTypeOptionsMap[key]>>
}

// TODO: static typings
type StyleConstructor<T extends StyleType> = Constructor<{id?: string, name: string, values: StyleTypeConfigurableValues[T], staticOpts?: Record<string, any>}, Style<T>>
const LineWidthValidValues = [
    {value: "1", rep: "1"},
    {value: "2", rep: "2"},
    {value: "3", rep: "3"},
    {value: "4", rep: "4"}
] as const;
const LineStyleValidValues = [
    {value: "0", rep: "Solid"},
    {value: "1", rep: "Dotted"},
    {value: "2", rep: "Dashed"},
    {value: "3", rep: "Large Dashed"},
    {value: "4", rep: "Sparse Dotted"}
] as const;
const LineTypeValidValues = [
    {value: "0", rep: "Simple"},
    {value: "1", rep: "With Steps"},
    {value: "2", rep: "Curved"}
] as const;
export const StyleConstructors: {[k in StyleType]: StyleConstructor<k> } = {
    Mark(args): Style<"Mark"> {
        return {
            _id: args.id ?? uniqueId(),
            name: args.name,
            type: "Mark",
            configurableOpts: {
                Color: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "Color"), Constructors.ConfigurableType("Study", "Color")], args.values.Color),
                Series: ConfigurableOptionConstructor([Constructors.ConfigurableType("Study", "Boolean")], args.values.Series),
                Position: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "Position"), Constructors.ConfigurableType("Study", "Position")], args.values.Position),
                Shape: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "Shape"), Constructors.ConfigurableType("Study", "Shape")], args.values.Shape)
            },
            staticOpts: {

            }
        };
    },

    Plot(args): Style<"Plot"> {
        return {
            _id: args.id ?? uniqueId(),
            name: args.name,
            type: "Plot",
            configurableOpts: {
                Color: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "Color"), Constructors.ConfigurableType("Study", "Color")], args.values.Color),
                Series: ConfigurableOptionConstructor([Constructors.ConfigurableType("Study", "Number")], args.values.Series)
            },
            staticOpts: {
                LineWidth: {
                    validValues: [
                        {value: "1", rep: "1"},
                        {value: "2", rep: "2"},
                        {value: "3", rep: "3"},
                        {value: "4", rep: "4"}
                    ],
                    value: LineWidthValidValues.find(v => v.value === args.staticOpts?.LineWidth) ?? LineWidthValidValues[0]
                },
                LineStyle: {
                    validValues: [
                        {value: "0", rep: "Solid"},
                        {value: "1", rep: "Dotted"},
                        {value: "2", rep: "Dashed"},
                        {value: "3", rep: "Large Dashed"},
                        {value: "4", rep: "Sparse Dotted"}
                    ],
                    value: LineStyleValidValues.find(v => v.value === args.staticOpts?.LineStyle) ?? LineStyleValidValues[0]
                },
                LineType: {
                    validValues: [
                        {value: "0", rep: "Simple"},
                        {value: "1", rep: "With Steps"},
                        {value: "2", rep: "Curved"}
                    ],
                    value: LineTypeValidValues.find(v => v.value === args.staticOpts?.LineType) ?? LineTypeValidValues[0]
                }
            }
        };
    }
}