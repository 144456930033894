import {DbStyle, DbStyleConverters} from "./study/db/DbStyle";
import {Style, StyleConstructors} from "./study/Style";
import _ from "lodash";
import {Input} from "./study/Input";
import {DbInput, DbInputConverters} from "./study/db/DbInput";
import {uniqueId} from "../crafter/Utils";
import {Equation} from "./study/Equation";
import {DbEquation, DbEquationConverters} from "./study/db/DbEquation";
import {BlockConverters} from "./study/Block";
import {StudyContext, StudyContextFunctions, StudySeriesConverter} from "./study/StudyContext";
import {ReadStudyParamConverters} from "./study/ReadStudyParam";
import {ReadStudyDependencyConverter, ReadyStudyConverter} from "./study/db/ReadStudy";
import {DbStudyConverter} from "./study/db/DbStudy";
import {ConfigurableType} from "./configurables/ConfigurableType";

export type TypeConverter<I, O> = (arg: I) => O
export type TypeConverterWithExtras<I, O, EXTRA> = (arg: I, extras: EXTRA) => O

export type DbTypeConverters<T, DT> = {
    toDb: TypeConverter<T, DT>,
    fromDb: TypeConverter<DT, T>
}
export type DbTypeConvertersWithExtras<T, DT, TE, DTE> = {
    toDb: TypeConverterWithExtras<T, DT, TE>,
    fromDb: TypeConverterWithExtras<DT, T, DTE>
}

export const Converters = {
    Input: DbInputConverters,
    Equation: DbEquationConverters,
    Style: DbStyleConverters,
    Study: {
        Read: ReadyStudyConverter,
        Db: DbStudyConverter,
        Series: StudySeriesConverter,
        Param: ReadStudyParamConverters
    },
    ReadStudy: ReadStudyDependencyConverter,
    StudyContext: {
        fromStudy: StudyContextFunctions.fromStudy,
    },
    Block: BlockConverters
} as const;