import {ConfigurableType} from "./ConfigurableType";
import {ConfigurableValue} from "./ConfigurableValue";

export type ConfigurableOption<CL extends ConfigurableType[]> = {
    validTypes: CL,
    value: { [K in keyof CL]: ConfigurableValue<CL[K]> }[number]
}

/**
 * Constructs a ConfigurableOption.
 * */
export function ConfigurableOptionConstructor<CL extends ConfigurableType[]>(values: CL, initialValue?: { [K in keyof CL]: ConfigurableValue<CL[K]> }[number]): ConfigurableOption<CL> {
    if (values.length === 0) throw new Error("No Configurable Types");

    if (initialValue) {
        if (values.some(v => v.configType === initialValue.type.configType && v.studyType === initialValue.type.studyType))
            return {
                validTypes: values,
                value: initialValue
            }
        else throw new Error("Invalid Configurable Value");
    } else return {
        validTypes: values,
        value: {
            type: values[0],
            value: undefined
        }
    }
}