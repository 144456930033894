import React, {useMemo, useState} from "react";
import {useMainSelector} from "../data/Store";
import {hasValidState} from "../data/HomeSlice";
import {useNavigate} from "react-router-dom";
import {PrimaryButton} from "../../crafter/components/ui/PrimaryButton";
import {Labeled} from "../../crafter/components/ui/Labeled";
import {AppInput} from "../../crafter/components/ui/AppInput";
import {AppDialog} from "../../crafter/components/ui/AppDialog";
import {createStudy} from "../Home";
import {ApiResponse} from "../data/Thunks";
import {TRALY_CORE_URL} from "../../constants";


// Study name
// Description
// Number of dependencies
// Thumbnail
// Number of Calculations ???
// Number of Styles ???

export function Studies(){
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [name, setName] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const studiesState = useMainSelector(state => state.home.userStudies)
    const studies = useMemo(() => {
        return hasValidState(studiesState) ? studiesState : [];
    }, [studiesState]);

    return (
        <div className="text-zinc-200 mr-8 flex">
            <ul className="flex-1 space-y-2">
            {
                studies.map(s =>
                    <div
                        onClick={() => window.open(`/crafter/${s.id}`, '_blank', 'noopener,noreferrer')}
                        className="p-2 bg-zinc-700/50 hover:bg-zinc-700/80 hover:shadow rounded cursor-pointer">
                        <h2>
                            {s.name}
                        </h2>
                    </div>
                )
            }
            </ul>
            <div className="w-48 pl-4 rounded-lg">
                <div className="bg-zinc-800 h-40 p-4 flex flex-col items-center">
                    <h1>Crafter</h1>
                    <div className="text-zinc-400 text-sm flex-1 ">Create a new study</div>
                    <PrimaryButton text={"Craft"} onClick={() => setIsOpen(true)}/>
                </div>
            </div>
            <AppDialog title={"Create a Study"} isOpen={isOpen} onClose={setIsOpen}>
                <Labeled label={"Name"}>
                    <AppInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="name..."
                    />
                    <div className="flex justify-end">
                        <PrimaryButton text={"Craft"} disabled={isLoading} onClick={() => {
                            setIsLoading(true)
                            // TODO: Use thunk
                            createStudy(name).then(async (res) => {
                                const dt = await res.json() as ApiResponse
                                setIsLoading(false)
                                setIsOpen(false)
                                console.log("Create study response:", dt)
                                if (dt.status)
                                    navigate(`/crafter/${dt.data}`)
                            })
                        }}/>
                    </div>
                </Labeled>
            </AppDialog>
        </div>
    )
}