import {getAuth, GoogleAuthProvider, signInWithPopup, Unsubscribe, User} from "firebase/auth";
import {FirebaseApp} from "./app";

// TODO: Hide export
export const auth = getAuth(FirebaseApp);

export const Auth = {
    onAuthStateChanged: async (callback: (user: User | null) => void): Promise<Unsubscribe> => {
        await auth.authStateReady();
        return auth.onAuthStateChanged(callback);
    },
    signInWithPopup(){
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .catch(e => console.log(e));
    },
    signOut(){
        auth.signOut()
            .catch(e => console.log(e));
    }
}