import {CrafterState, CrafterStoreSelector} from "./Store";
import {Converters} from "../../types/Converters";
import {Equation} from "../../types/study/Equation";
import {StudyContext, EquationScopedStudyContext} from "../../types/study/StudyContext";
import _ from "lodash";
import {ReadStudyParam, ReadStudyParamConverters} from "../../types/study/ReadStudyParam";
import { Referenced } from "../Utils";
import {ArrayUtils} from "../../lib/utils/Lodash";
import {createSelector} from "@reduxjs/toolkit";

export const selectContext: CrafterStoreSelector<StudyContext> = state => {
    return Converters.StudyContext.fromStudy(state.crafter.study)
}

export function tempContext(context: StudyContext, equation: Equation): EquationScopedStudyContext {
    const index = ArrayUtils.findIndexByField(context.deps.allSeries, "refId", equation._id)
    if (index < 0) return {
        ...context,
        selfEquation: ReadStudyParamConverters.fromEquation(equation, context.study.id)
    };
    const updatedSeries = {
        ...context.deps.series,
        equations: _.take(context.deps.series.equations, index+2),
    }
    return {
        ...context,
        deps: {
            series: updatedSeries,
            allSeries: Converters.Study.Param.fromStudySeries(updatedSeries)
        },
        selfEquation: ReadStudyParamConverters.fromEquation(equation, context.study.id)
    }
}

// TODO: use createSelector
export function selectContextByEquation(equation: Equation): CrafterStoreSelector<EquationScopedStudyContext> {
    return (state): EquationScopedStudyContext => {
        const context = Converters.StudyContext.fromStudy(state.crafter.study)
        const index = ArrayUtils.findIndexByField(context.deps.allSeries, "refId", equation._id)
        if (index < 0) return {
            ...context,
            selfEquation: ReadStudyParamConverters.fromEquation(equation, context.study.id)
        };
        const updatedSeries = {
            ...context.deps.series,
            equations: _.take(context.deps.series.equations, index+2),
        }
        return {
            ...context,
            deps: {
                series: updatedSeries,
                allSeries: Converters.Study.Param.fromStudySeries(updatedSeries)
            },
            selfEquation: ReadStudyParamConverters.fromEquation(equation, context.study.id)
        }
    }
}

// TODO: Remove if unnecessary
export const selectStudySeries: CrafterStoreSelector<Referenced<ReadStudyParam>[]> = state => {
    const dt = Converters.Study.Series(state.crafter.study)
    return Converters.Study.Param.fromStudySeries(dt)
}

export function selectWriteStudyAsJson(state: CrafterState): string {
    const study = Converters.Study.Db(state.crafter.study)
    // console.log(JSON.stringify(study, undefined, 2));
    return JSON.stringify(study)
}