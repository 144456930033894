import {createAsyncThunk} from "@reduxjs/toolkit";
import {_authorizedFetch, getAuthorizationHeader} from "../Home";
import {TRALY_BACKEND_URL} from "../../constants";
import {setStudiesState, StudyInfo} from "./HomeSlice";

export type ApiResponse = {
    status: true,
    data: any
} | {
    status: false,
    error: string
}

export const getUserStudies = createAsyncThunk<StudyInfo[]>("study/getUserStudies", async (_, thunkAPI) => {
    thunkAPI.dispatch(setStudiesState("Loading"));
    const res = await _authorizedFetch(TRALY_BACKEND_URL + "/user/studies");
    const data: ApiResponse = await res.json();
    if (data.status)
        return data.data;
    else
        return thunkAPI.rejectWithValue(data.error);
})