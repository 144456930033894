// import React, {useEffect, useRef, useState} from "react";
// import {SimpleButton} from "./SimpleButton";
// import {Equation, getBlockData, newEquation} from "./Types";
// import {EquationBuilder} from "./EquationBuilder";
// import {Chart} from "../lib/chart/Chart";
// import {
//     CandlestickData,
//     CrosshairMode,
//     DeepPartial, IChartApi,
//     ISeriesApi,
//     LineData,
//     Time,
//     TimeChartOptions,
//     UTCTimestamp
// } from "lightweight-charts";
// import {CandleStickSeries, LineSeries} from "../lib/chart/Series";
//
//
import {CrosshairMode, DeepPartial, Time, TimeChartOptions, UTCTimestamp} from "lightweight-charts";

export function Builder() {}
//     const [equations, setEquations] = useState<Equation[]>([newEquation()]);
//     const [ohlc, setOhlc] = useState<CandlestickData[]>([])
//     const [data, setData] = useState<LineData[][]>([])
//     const chartRef = useRef<IChartApi>(null);
//     const mainSeries = useRef<ISeriesApi<"Candlestick">>(null);
//     const imaSeries = useRef<ISeriesApi<"Line">>(null);
//
//     useEffect(() => {
//         fetch("http://127.0.0.1:8080/ohlc/NIFTY?from=1709251200&timeframe=15m")
//             .then(async (res) => {
//                 const dt = await res.json()
//                 const ohlcData = dt.data.map((r: number[]) => {
//                     return {
//                         open: r[0] / 100,
//                         high: r[1] / 100,
//                         low: r[2] / 100,
//                         close: r[3] / 100,
//                         time: r[4] as UTCTimestamp,
//                     }
//                 })
//                 setOhlc(ohlcData);
//             })
//     }, []);
//
//     useEffect(() => {
//     }, []);
//
//     useEffect(() => {
//         mainSeries.current?.setData(ohlc)
//         mainSeries.current?.priceScale().applyOptions({
//             autoScale:true
//         });
//     }, [ohlc]);
//     useEffect(() => {
//         imaSeries.current?.setData(data.at(0) ?? [])
//         imaSeries.current?.priceScale().applyOptions({
//             autoScale:true
//         });
//     }, [data]);
//     return (
//         <div
//             className="w-screen h-screen p-8 dark:bg-zinc-900 select-none">
//
//             <Chart ref={chartRef} className="h-72" options={ChartOptions} >
//                 <CandleStickSeries
//                     ref={mainSeries}
//                     initialData={ohlc}
//                 />
//                 {
//                     data.map((od) =>
//                         <LineSeries
//                             options={{
//                                 lineWidth: 1,
//                                 color: '#F2AF4C',
//                                 crosshairMarkerVisible:false
//                             }}
//                             initialData={od}
//                         />
//                     )
//                 }
//                 {/*<LineSeries*/}
//                 {/*    ref={imaSeries}*/}
//                 {/*    options={{*/}
//                 {/*        lineWidth: 1,*/}
//                 {/*        color: '#F2AF4C',*/}
//                 {/*        crosshairMarkerVisible:false*/}
//                 {/*    }}*/}
//                 {/*    initialData={[]}*/}
//                 {/*/>*/}
//             </Chart>
//
//             <h1 className="ml-6 text-3xl font-semibold dark:text-zinc-200">Builder</h1>
//             <h2 className="mt-4 mb-2 ml-6 text-xl font-semibold dark:text-zinc-300">Calculations</h2>
//             <ul className="flex flex-col space-y-4">
//                 {
//                     equations.map((equation, i) =>
//                         <li key={equation.id}>
//                             <EquationBuilder idx={i} equation={equation} setEquation={(eq: Equation) => {
//                                 setEquations(equations.map((e) => e.id === eq.id ? eq : e))
//                             }}/>
//                         </li>
//                     )
//                 }
//             </ul>
//             <div className="flex">
//                 <SimpleButton className="ml-6 mt-4"
//                               onClick={() => setEquations([...equations, newEquation()])}>Add</SimpleButton>
//                 <div className="flex-1"/>
//                 <SimpleButton className="ml-6 mt-4" onClick={() => {
//                     fetch("http://127.0.0.1:8080/build/NIFTY?from=1709251200&timeframe=15m", {
//                         method: "post",
//                         headers: {
//                             'Accept': '*/*',
//                             'Content-Type': 'application/json'
//                         },
//                         body: JSON.stringify({
//                             name: "Study 1",
//                             outputs: equations.map((eq) => {
//                                 return {
//                                     types: "Plot",
//                                     param: {
//                                         studyName: "Study 1",
//                                         paramName: eq.name
//                                     }
//                                 }
//                             }),
//                             equations: equations.map((eq) => {
//                                 return {
//                                     name: eq.name,
//                                     expression: getBlockData(eq)
//                                 }
//                             })
//                         })
//                     }).then(async (response) => {
//                         const dt = await response.json()
//                         setData(dt);
//                         console.log(dt)
//                         console.log(ohlc)
//                     })
//                 }}>Chart</SimpleButton>
//                 <SimpleButton className="ml-6 mt-4" onClick={() => setEquations([newEquation()])}>Reset</SimpleButton>
//             </div>
//         </div>
//     )
// }
const darkMode = true;
const formatter = new Intl.DateTimeFormat('en-US', {
    dateStyle: "short",
    timeStyle: "short",
    timeZone: '+05:30',

});
export const ChartOptions: DeepPartial<TimeChartOptions> = {
    layout: {
        background:{
            color: 'transparent',
        },
        textColor: darkMode ? '#c9d0d4' : '#343C44',
        fontFamily: '"Inter var", sans-serif',
    },
    rightPriceScale: {
        visible: true,
        borderColor: darkMode ? 'rgb(63,63,70)' : '#c9d0d4',
    },
    timeScale: {
        timeVisible: true,
        secondsVisible: false,
        visible: true,
        borderColor: darkMode ? 'rgb(63,63,70)' : '#c9d0d4',
    },
    localization: {
        timeFormatter: (time: Time) => {
            if (typeof time == "string")
                return time;
            return formatter.format((time as UTCTimestamp) * 1000);
        }
    },
    grid: {
        vertLines: {visible:false},
        horzLines: {visible:false}
    },
    crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: {
            labelBackgroundColor: "#343C44"
        },
        horzLine: {
            labelBackgroundColor: "#343C44"
        }
    }
}
//
// //    const [isDragging, setIsDragging] = useState(false);
// //
// //     const handleMouseDown = (event: any) => {
// //         setIsDragging(true);
// //         console.log("downing")
// //     };
// //
// //     const handleMouseMove = (event: any) => {
// //         if (isDragging) {
// //             // Perform actions while dragging
// //             console.log('Dragging...', event.clientX, event.clientY);
// //         }
// //     };
// //
// //     const handleMouseUp = (event: any) => {
// //         setIsDragging(false);
// //         console.log("Upping")
// //     };
// //
// // onMouseDown={handleMouseDown}
// // onMouseMove={isDragging ? handleMouseMove : undefined}
// // onMouseUp={isDragging ? handleMouseUp : undefined}