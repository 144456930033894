import {z} from "zod";
import {Input} from "./Input";

export type Instrument = {
    id: string,
    name: string,
    exchange: string,
    symbol: string
}

export const TimeframeUnitSchema = z.enum(["m"])

export const TimeframeSchema = z.object({
    multiplier: z.number(),
    unit: TimeframeUnitSchema
})

export type Timeframe = z.infer<typeof TimeframeSchema>
export const GetTimeframeRepresentation = (timeframe: Timeframe) => {
    return `${timeframe.multiplier}${timeframe.unit}`
}

export type TimeSeries = {
    instrument: Instrument,
    timeframe: Timeframe
}

export type StudyConfig = {
    timeSeries: TimeSeries,
    inputs: Input[]
}

// TODO: Move
export const defaultStudyConfig: StudyConfig = {
    timeSeries: {
        instrument: { // TODO
            id: "NSE:HDFCBANK:EQ",
            name: "HDFCBANK",
            exchange: "NSE",
            symbol: "HDFCBANK"
        },
        timeframe: {
            multiplier: 1,
            unit: "m"
        }
    },
    inputs: [] // TODO: Build from study
}