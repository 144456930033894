import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type AppUser = {
    uid: string,
    name: string,
    email: string,
    image?: string
}

export const AuthSlice =  createSlice({
    name: "Auth",
    initialState: {
        user: "NOT_INITIALIZED" as "NOT_INITIALIZED" | AppUser | null
    },
    reducers: {
        setUser: (state, action: PayloadAction<AppUser | null>) => {
            state.user = action.payload
        }
    },
    extraReducers: builder => {

    }
})

export const { setUser } = AuthSlice.actions