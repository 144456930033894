import {_authorizedFetch} from "../Home";
import {TRALY_CORE_URL} from "../../constants";
import {Instrument} from "../../types/study/Config";
import {createAsyncThunk} from "@reduxjs/toolkit";

async function _getSymbols(query: string): Promise<Instrument[] | undefined> {
    // TODO: remove hardcoded angelone
    const res = await _authorizedFetch(TRALY_CORE_URL + `/instruments/angelone?q=${query}`);
    const data = await res.json() as Instrument[];
    // console.log(data)
    // return data.status ? data.data : undefined;
    return data;
}

export const getSymbols = createAsyncThunk<Instrument[] | undefined, string>("symbol/getSymbols", _getSymbols)