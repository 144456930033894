import {
    BrokerList,
    BrokerIds,
    BrokerInfo,
    BrokerId,
    StepUI,
    Brokers,
    BrokerInputs,
    NO_REDIRECT_URI, LOADING_REDIRECT_URI
} from "../../profile/BrokerPage";
import clsx from "clsx";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useMainDispatch, useMainSelector} from "../data/Store";
import {
    configureBroker,
    getLoginUri,
    getRedirectUri,
    getUserBrokers,
    removeBroker,
    UserBrokerInfo
} from "../data/BrokerApi";
import { hasValidState} from "../data/HomeSlice";
import {PrimaryButton, SecondaryButton} from "../../crafter/components/ui/PrimaryButton";
import {AppDialog} from "../../crafter/components/ui/AppDialog";
import {Button} from "@headlessui/react";

// Fetch connected brokers, and setup done brokers

export function BrokersPage(){
    const dispatch = useMainDispatch();
    const brokers = useMainSelector(state => state.home.brokers);
    const uriCache = useMainSelector(state => state.home.uriCache);
    const { configured, others } = useMemo(() => {
        const list = hasValidState(brokers) ? brokers : []
        const setupList = list.filter(broker => broker.isSetupCompleted)
        const [conf, notConf]: [(BrokerInfo & UserBrokerInfo)[], BrokerInfo[]] = [[], []]
        BrokerList.forEach(broker => {
            const res = setupList.find(b => b.brokerId === broker.id)
            if (res)
                conf.push({...res, ...broker})
            else
                notConf.push(broker)
        })
        return {
            configured: conf,
            others: notConf
        }
    }, [brokers]);

    const [setupInputs, setSetupInputs] = useState<BrokerInputs>()
    const [selected, setSelected] = useState<BrokerInfo>()
    const [conSelected, setConSelected] = useState<BrokerInfo>()

    useEffect(() => {
        if (selected && uriCache[selected.id] === undefined)
            dispatch(getRedirectUri(selected.id))
    }, [selected, uriCache]);

    useEffect(() => {
        if (brokers === "Idle")
            dispatch(getUserBrokers())
    }, [brokers]);
    
    return(
        <div className="h-full text-zinc-200">
            {
                brokers === "Loading" && <img className="size-12" alt="Loading" src="/loading.svg"/>
            }
            {
                hasValidState(brokers) &&
                <>
                    <h2 className="text-zinc-400">Configured Brokers</h2>
                    <ul className="mt-4 flex space-x-4">
                        {
                            configured.map(broker =>
                                <ConnectedBrokerItem onClick={() => setConSelected(broker)} broker={broker} isConnected={broker.isConnected} isSetupDone={broker.isSetupCompleted}/>
                            )
                        }
                    </ul>
                    <h2 className="mt-8 text-zinc-400">All Brokers</h2>
                    <ul className="mt-4 flex space-x-4">
                        {
                            others.map(broker => <BrokerListItem onClick={() => setSelected(broker)} broker={broker}/>)
                        }
                    </ul>
                </>
            }
            <AppDialog isOpen={conSelected !== undefined} onClose={() => setConSelected(undefined)} title={conSelected?.name ?? ""}>
                {
                    conSelected &&
                    <Button
                        onClick={() => {
                            setConSelected(undefined)
                            dispatch(removeBroker(conSelected.id))
                        }}
                        className={`text-zinc-200 inline-flex items-center gap-2 rounded-md dark:bg-red-500 py-1.5 px-3 text-md shadow-inner shadow-white/5 focus:outline-none data-[hover]:dark:bg-red-500/90 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}>
                        Remove
                    </Button>
                }
            </AppDialog>

            <AppDialog isOpen={selected !== undefined} onClose={() => {
                setSelected(undefined)
                setSetupInputs(undefined)
            }} title={selected?.name ?? ""}>
                {
                    selected &&
                    <div className="w-[40rem]">
                        {/*<h1 className="text-2xl font-bold mb-4 dark:text-zinc-300">{selected.name}</h1>*/}
                        <img src={selected.image?.url} alt={selected.name} className="w-40  object-contain mb-4"/>
                        {
                            <ul className={`${selected.orderedSteps ? "list-decimal" : "list-disc"} mb-4 text-zinc-300 text-[0.9em] space-y-2`}>
                                {selected.steps.map(step => <StepUI uri={uriCache[selected.id]}  step={step}/>)}
                            </ul>
                        }
                        {
                            selected.inputs.map(input => {
                                return (
                                    <div className="mb-4">
                                        <label htmlFor={input.name}
                                               className="block text-sm font-medium text-gray-700 dark:text-zinc-300">{input.text || input.name}:</label>
                                        <input
                                            id={input.name}
                                            type="text"
                                            value={setupInputs?.inputs[input.name] ?? ""}
                                            placeholder={input.placeholder || `Enter your ${input.name}`}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-zinc-700 dark:border-zinc-600 dark:text-zinc-200"
                                            onChange={(e) => {
                                                const inputs = setupInputs?.inputs || {}
                                                setSetupInputs({
                                                    brokerName: selected?.id!,
                                                    inputs: {
                                                        ...inputs,
                                                        [input.name]: e.target.value.trim()
                                                    }
                                                })
                                            }}
                                        />
                                        <p className="mt-2 text-sm text-gray-500 dark:text-zinc-300">{input.extra}</p>
                                    </div>
                                )
                            })
                        }
                        <PrimaryButton onClick={() => {
                            setupInputs && dispatch(configureBroker(setupInputs))
                            setSelected(undefined)
                        }} text={"Add"}/>
                    </div>
                }
            </AppDialog>
        </div>
    )
}

type BrokerListItemProps = {
    broker: BrokerInfo,
    onClick: () => void
}

export function BrokerListItem(props: BrokerListItemProps) {
    return (
        <div
            onClick={props.onClick}
            className="flex flex-col justify-center items-center size-48 bg-zinc-800 rounded cursor-pointer hover:bg-zinc-700/60 hover:shadow-xl">
            <img
                className={clsx("object-contain ", props.broker.image.tailwindWidth)}
                draggable={false}
                alt={props.broker.name}
                src={props.broker.image.url}
            />
        </div>
    )
}

type ConnectedBrokerItemProps = {
    broker: BrokerInfo,
    isConnected: boolean,
    isSetupDone: boolean,
    onClick?: () => void
}

//  If Setup, but not connected -> Connect, Edit
// If connected -> Sign out, Edit
export function ConnectedBrokerItem(props: ConnectedBrokerItemProps) {
    const [isLoading, setIsLoading] = useState(false)

    const onConnect = useCallback(() => {
        setIsLoading(true)
        getLoginUri(props.broker.id).then((loginUri) => {
            setIsLoading(false)
            // TODO: Handle cancellation
            window.location.href = loginUri
        })
    }, [props]);

    return (
        <div>
            <div
                onClick={props.onClick}
                className="relative flex flex-col items-center size-48 bg-zinc-800 rounded cursor-pointer hover:bg-zinc-700/60 hover:shadow-xl">
                <img
                    className={clsx("object-contain flex-1", props.broker.image.tailwindWidth)}
                    draggable={false}
                    alt={props.broker.name}
                    src={props.broker.image.url}
                />
            </div>
            <div className="flex items-center justify-center pt-4">
                {
                    !props.isConnected ?
                        isLoading ?
                            <img className="size-8" src={"/loading.svg"} alt="Loading"/>
                            :
                            <PrimaryButton
                                onClick={onConnect}
                                className="text-sm " text={"Connect"}/>
                        : <p className="text-sm space-x-1"><span>Connected</span> <span
                            className="inline-block w-2 h-2 bg-green-500 rounded-full"></span></p>

                }
            </div>
        </div>
    )
}