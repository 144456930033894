import _ from "lodash";

export namespace ArrayUtils {
    export function map<T, R>(arr: T[], converter: (item: T) => R): R[] {
        return _.map(arr, converter);
    }

    export function mapByField<T, R extends keyof T>(arr: T[], field: R): T[R][] {
        return _.map(arr, field) as T[R][];
    }

    export function filterByField<T, R extends keyof T>(arr: T[], field: R, value: T[R]): T[] {
        return _.filter(arr, (item) => item[field] === value);
    }

    export function filterNotByField<T, R extends keyof T>(arr: T[], field: R, value: T[R]): T[] {
        return _.filter(arr, (item) => item[field] !== value);
    }

    export function removeByField<T, R extends keyof T>(arr: T[], field: R, value: T[R]): void {
        _.remove(arr, (item) => item[field] === value);
    }

    export function findByField<T, R extends keyof T>(arr: T[], field: R, value: T[R]): T | undefined {
        return _.find(arr, (item) => item[field] === value);
    }

    export function findIndexByField<T, R extends keyof T>(arr: T[], field: R, value: T[R]): number {
        return _.findIndex(arr, (item) => item[field] === value);
    }

    export function keyByField<T, R extends keyof T>(arr: T[], field: R): { [key: string]: T } {
        return _.keyBy(arr, field);
    }
}