import React, {useState} from "react";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Transition
} from "@headlessui/react";
import clsx from "clsx";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/16/solid";
import {TypeGuards} from "../../../types/Guards";
import {Constructors} from "../../../types/Constructors";

export type AppComboBoxProps<T> = {
    items: T[],
    selected: T,
    setSelected: (item: T) => void,
    displayValue?: (item: T) => string,
    keyValue?: (item: T) => string,
    className?: string,
    placeHolder?: string
}

type NameComboBoxProps = {
    selected: string,
    className?: string,
    placeHolder?: string,
    setSelected?: (s: string) => void
}
export function NameComboBox<T>(props: NameComboBoxProps) {
    const [query, setQuery] = useState("");

    return (
        <div className={clsx("", props.className)}>
            <Combobox value={props.selected} onChange={(item) => {
                if (item && props.setSelected)
                    props.setSelected(item)
            }}>
                <div className="relative">
                    <ComboboxInput
                        className={clsx(
                            'w-full rounded-sm border-none bg-zinc-800 py-0.5 px-3 text-sm/6 text-white',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                        )}
                        placeholder={props.placeHolder}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                </div>
                <Transition
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <ComboboxOptions
                        anchor="bottom"
                        className="w-[var(--input-width)] z-50 mt-1 rounded-xl border border-white/5 bg-zinc-600 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                    >
                        {
                            query.length > 0 &&
                            <ComboboxOption
                                key={query}
                                value={query}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <div className="text-sm/6 text-white">{`Apply ${query}`}</div>
                            </ComboboxOption>
                        }
                    </ComboboxOptions>
                </Transition>
            </Combobox>
        </div>
    )
}

export function AppComboBox<T>(props: AppComboBoxProps<T>) {
    const [query, setQuery] = useState("");
    const getDisplayValue = props.displayValue ?? ((item: T) => typeof item === "string" ? item : "")
    const getKey = props.keyValue ?? getDisplayValue
    const filtered =
        query === ""
            ? props.items
            : props.items.filter((item) => {
                return getDisplayValue(item).toLowerCase().includes(query.toLowerCase())
            })

    return (
        <div className={clsx("", props.className)}>
            <Combobox value={props.selected} onChange={(item) => {
                if (item)
                    props.setSelected(item)
            }}>
                <div className="relative">
                    <ComboboxInput
                        className={clsx(
                            'w-full rounded-lg border-none bg-zinc-600 py-1.5 pr-8 pl-3 text-sm/6 text-white',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                        )}
                        placeholder={props.placeHolder}
                        displayValue={getDisplayValue}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                        <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white"/>
                    </ComboboxButton>
                </div>
                <Transition
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <ComboboxOptions
                        anchor="bottom"
                        className="w-[var(--input-width)] z-50 mt-1 rounded-xl border border-white/5 bg-zinc-600 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                    >
                        {filtered.map((item) => (
                            <ComboboxOption
                                key={getKey(item)}
                                value={item}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                            >
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible"/>
                                <div className="text-sm/6 text-white">{getDisplayValue(item)}</div>
                            </ComboboxOption>
                        ))}
                        {
                           filtered.length === 0 &&
                            <ComboboxOption
                                key={"No Option Available"}
                                value={undefined}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none "
                            >
                                <div className="text-sm/6 italic text-zinc-200/80">{"Not Available"}</div>
                            </ComboboxOption>
                        }
                    </ComboboxOptions>
                </Transition>
            </Combobox>
        </div>
    )
}