
export function Support(){
    return (
        <div className="text-zinc-300 pr-8 space-y-8 pb-8">
            <div className="bg-zinc-800 rounded-lg p-4">
                For feature request, bug reports, or any other support, please email
                <a className="px-2 text-blue-500 underline" href="mailto:support@tralyanalytics.som">support@tralyanalytics.com</a>
            </div>
            <div>
                <h1 className="text-zinc-400 italic pb-1">Scheduled updates and features</h1>
                <div className="bg-zinc-800 rounded-lg p-4">
                    <ul className="divide-y divide-zinc-700 space-y-2">
                        {
                            FeatureGroups.map(({groupName, options}) => (
                                <li className="pt-4 pb-4">
                                    <p className="text-zinc-400 italic pb-1">{groupName}</p>
                                    <ul className="list-disc px-4">
                                        {
                                            options.map(option => (
                                                <li className="text-sm">
                                                    {option}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export type Group<OPTION> = {
    groupName: string,
    options: OPTION[]
}

const FeatureGroups: Group<string>[] = [
    {
        groupName: "Chart Drawing Tools",
        options: [
            "Trendline and shapes",
            "Fibonacci retracement",
            "Text and misc"
        ]
    },
    {
        groupName: "Builtin Studies (Indicators)",
        options: [
            "Volume",
            "Open Interest",
            "RSI",
            "MACD",
            "Bollinger Bands",
            "Stochastic Oscillators"
        ]
    },
    {
        groupName: "Crafter",
        options: [
            "If else statements",
            "FILL (Style) - Fill colors in area between two lines",
            "HIGHLIGHT (Style) - Highlight a region based on a condition",
            "Improved Calculation editor",
            "Max and min functions",
            "Crosses up or down functions",
            "Crafter Help - Documentation about all types of values and functions available"
        ]
    },
    {
        groupName: "Watchlists",
        options: [
            "Add and customize your own watchlists"
        ]
    },
    {
        groupName: "Dashboards",
        options: [
            "BUILDER - Create your own dashboards on a drag and drop interface",
            "Customize your dashboard with widgets",
            "Add built in or custom studies to your dashboard"
        ]
    },
    {
        groupName: "Analyzer - A sandbox for your analysis",
        options: [
            "Add calculations and styles",
            "Show/Hide or Highlight specific regions based on Time",
            "EON - AI Assistant to help you on the platform"
        ]
    },
    {
        groupName: "Scanner",
        options: [
            "Get Realtime scans on multiple symbols simultaneously",
            "Add built in or custom studies to your scans"
        ]
    },
]