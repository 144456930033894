import {DbInput, DbInputConverters} from "./DbInput";
import {DbStyle, DbStyleConverters} from "./DbStyle";
import {DbEquation, DbEquationConverters} from "./DbEquation";
import {TypeConverter} from "../../Converters";
import {Study, StudyDependency} from "../Study";
import {ReferentConstructor} from "../../../crafter/Utils";
import {ReadStudyParam} from "../ReadStudyParam";
import {StudyContextFunctions} from "../StudyContext";

export type ReadStudyDependency = {
    id: string,
    studyId: string,
    name: string,
    inputValues: Omit<DbInput, "isFixed">[],
    inputs: DbInput[],
    dependencies: ReadStudyDependency[],
    equations: ReadStudyParam[],
    styles: DbStyle[]
}

export type ReadStudy = {
    id: string,
    name: string,
    dependencies: ReadStudyDependency[],
    inputs: DbInput[],
    equations: DbEquation[],
    styles: DbStyle[]
}

export const ReadStudyDependencyConverter: TypeConverter<ReadStudyDependency, StudyDependency> =
    (dep) => {
        const RefConstructor = <T>(item: T) => ReferentConstructor(item)
        return {
            id: dep.id,
            studyId: dep.studyId,
            name: dep.name,
            inputValues: dep.inputValues.map(RefConstructor),
            inputs: dep.inputs.map(RefConstructor),
            dependencies: dep.dependencies.map(ReadStudyDependencyConverter),
            equations: dep.equations.map(RefConstructor),
            styles: dep.styles.map(RefConstructor)
        }
    }

export const ReadyStudyConverter: TypeConverter<ReadStudy, Study> =
    (item) => {
        let study: Study = {
            id: item.id,
            name: item.name,
            dependencies: item.dependencies.map(ReadStudyDependencyConverter),
            inputs: item.inputs.map(DbInputConverters.fromDb),
            equations: [],
            styles: item.styles.map(DbStyleConverters.fromDb)
        }
        item.equations.forEach(eq => {
            const context = StudyContextFunctions.fromStudy(study)
            const equation = DbEquationConverters.fromDb(eq, context)
            study = {
                ...study,
                equations: [...study.equations, equation]
            }
        })
        return study;
    }