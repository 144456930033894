import React, {createContext, useContext} from "react";

export function createNullableContext<T>(defValue: T | null = null) {
    return createContext<T | null>(defValue)
}

export function useNotNullContext<T>(context: React.Context<T | null>, errorMessage: string = "Context not provided.") : T {
    const obj: T | null = useContext(context)
    if (!obj) throw new Error(errorMessage);
    return obj
}