import {PrimaryButton} from "../crafter/components/ui/PrimaryButton";
import {useNavigate} from "react-router-dom";

const features = [
    {
        name: 'Build custom dashboards',
        description:
            'Add charts, widgets and studies to your dashboard to create a custom trading experience, on a drag and drop interface.',
    },
    {
        name: 'Craft your own studies',
        description: 'Create your own indicators with Crafter. Add custom calculations and visualizations to your studies.',
    },
    {
        name: 'Analyze markets',
        description: 'Use our powerful analytics tools to analyze markets and make informed decisions.',
    },
]


export function About(){
    const navigate = useNavigate();

    return (
        <div className=" w-[100%]  max-w-screen min-h-screen bg-[#222222] font-rubik">
            <div className="h-16 bg-[#2b2b2b] flex items-center px-8">
                <img src="/logo.svg" alt="Logo" className="w-24"/>
                <div className="flex-1"></div>
                <PrimaryButton text={"Login"} onClick={() => {
                    navigate("/login")
                }}/>
            </div>
            <h1 className="pt-16 text-center text-[60px] font-semibold text-[#5F57FF]">Next-Gen</h1>
            <h1 className="text-center text-[48px] font-semibold text-[#EEEEEE]">Charting & Analytics Platform</h1>
            <p className="text-center pt-8 text-zinc-400">
                Build from custom dashboards to powerful analytics tools, on a no code interface,
                to empower your technical analysis.
            </p>

            <div className="mt-24 max-w-[100%] pb-24">
                <div
                    className=" grid  grid-cols-1 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 overflow-hidden">
                    <div className=" lg:pt-4">
                        <div className="px-24">
                            <dl className="mt-10 space-y-8 text-base leading-7 text-zinc-400 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative">
                                        <dt className="inline font-semibold text-[#5F57FF]">
                                            {feature.name}
                                        </dt>
                                        {' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>

                    </div>

                    <img
                        alt="Product screenshot"
                        src="/demo1.jpeg"
                        className="mx-auto rounded-xl shadow-xl"
                    />
                </div>
            </div>
            <div className="bg-zinc-700/50 h-16 flex items-center">
                <a className="text-center flex-1 text-blue-500 underline" href={"mailto:support@tralyanalytics.com"}>support@tralyanalytics.com</a>
            </div>
        </div>
    )
}