import GridLayout, {Responsive, WidthProvider} from "react-grid-layout";
import "/node_modules/react-resizable/css/styles.css"
import "/node_modules/react-grid-layout/css/styles.css";
import React, {useEffect, useState} from "react";
import {WidgetList} from "./types/Widgets";
import {PlusIcon} from "@heroicons/react/16/solid";
import {useBuilderDispatch, useBuilderSelector} from "./data/Store";
import {AddStudyDialog} from "./AddStudyDialog";
import {useNavigate} from "react-router-dom";
import {fetchStudyRef} from "../crafter/data/Thunks";
import {addWidget, deleteWidget, updateLayout, updateWidget} from "./data/Slice";
import {saveDashboard} from "./data/Thunks";
import {Tab, TabList, TabPanel, TabPanels} from "@headlessui/react";
import {TabGroup} from "@headlessui/react";
import {
    Widget
} from "./types/Widget";
import _ from "lodash";
import {Labeled} from "../crafter/components/ui/Labeled";
import {AppComboBox} from "../crafter/components/ui/AppComboBox";
import {TypeEditor} from "../crafter/components/TypeEditor";
import {ConvertToNullable, NonNullableStudyTypes, StudyType} from "../types/study/StudyType";
import {PrimaryButton} from "../crafter/components/ui/PrimaryButton";
import {StudyDependency} from "../types/study/Study";
import {ReadStudyParam} from "../types/study/ReadStudyParam";
import {Referenced} from "../crafter/Utils";
import {ConfigurableOption} from "../types/configurables/ConfigurableOption";
import {ConfigurableValueType} from "../types/configurables/ConfigurableValueType";
import {ConfigurableType} from "../types/configurables/ConfigurableType";
import {checkConfigurableType, ConfigurableValue} from "../types/configurables/ConfigurableValue";

export const Grid = WidthProvider(Responsive);

// UI: Dashboard Name Editor -> SimpleStringEditor Component
export function Builder() {
    const navigate = useNavigate();

    const [addStudyDialogOpen, setAddStudyDialogOpen] = useState(false);

    // const [layout, setLayout] = useState<Layout[]>([]);
    const [height, setHeight] = useState(0)
    const [dropping, setDropping] = useState<{ w: number, h: number, i: string }>()

    const dispatch = useBuilderDispatch()
    const dash = useBuilderSelector(state => {
        return JSON.stringify({
            id: state.builder.dashboard.id,
            displayName: state.builder.dashboard.displayName,
            studies: state.builder.dashboard.studies.map((s) => ({
                study: s.study.id,
                config: s.config
            })),
            widgets: state.builder.dashboard.widgets
        })
    })
    const id = useBuilderSelector(state => state.builder.dashboard.id)

    const status = useBuilderSelector(state => state.builder.saveStatus)
    const name = useBuilderSelector(state => state.builder.dashboard.displayName)
    const studies = useBuilderSelector(state => state.builder.dashboard.studies)
    const widgets = useBuilderSelector(state => state.builder.dashboard.widgets)

    const [selectedWidgetId, setSelectedWidgetId] = useState<string>()
    const selectedWidget = widgets.find((w) => w.id === selectedWidgetId)

    useEffect(() => {
        dispatch(saveDashboard(dash))
        console.log(dash)
    }, [dispatch, dash]);

    return (
        <div
            className="w-screen min-h-screen flex flex-col font-rubik dark:bg-zinc-900 dark:text-zinc-200 overflow-y-hidden">
            {/*TODO*/}
            <AddStudyDialog
                currentStudyId={""}
                isOpen={addStudyDialogOpen}
                setIsOpen={setAddStudyDialogOpen}
                onStudyAdded={(id) => dispatch(fetchStudyRef(id))}
            />
            <div className="w-full h-16 dark:bg-zinc-800 flex items-center px-4 border-zinc-700 border-b">
                <div
                    onClick={() => navigate("/")}
                    className="absolute font-rubik-mono text-2xl cursor-pointer tracking-tight">
                    <span className="text-zinc-200">TR</span>
                    <span className="text-[#5F57FF]">ALY</span>
                </div>
                <div className="flex-1 flex items-center justify-center">
                    <div>{name}</div>
                    {
                        status !== "upToDate" &&
                        <div className="text-sm pl-4">Saving...</div>
                    }
                </div>
                <div>
                    <PrimaryButton text={"Launch"} disabled={false} onClick={() => {
                        window.open(`/dashboard/${id}`, '_blank')
                    }}/>
                </div>
            </div>

            <div className="flex-1 flex w-full" ref={(div) => {
                if (div)
                    setHeight((div.clientHeight - (2 * 16)) / 12)
            }}>
                <div className="bg-zinc-800 w-48 flex flex-col">
                    <div className="flex-1">
                        <div className="flex border-zinc-700 border-b py-1 px-2 text-sm">
                            <span>Studies</span>
                            <div className="flex-1"></div>
                            <PlusIcon onClick={() => setAddStudyDialogOpen(true)}
                                      className="w-5 text-zinc-200 cursor-pointer"/>
                        </div>
                        <ul className="text-zinc-200/80">
                            {
                                studies.map((s) =>
                                    <StudyTreeLeaf s={s.study}/>
                                )
                            }
                        </ul>
                    </div>
                    <div className="flex-1 border-zinc-700 border-b py-1 px-2 text-sm">
                        <span>Widget Tree</span>
                    </div>

                </div>
                <div className="flex-1 p-4">
                    <div className="">
                        <Grid
                            className="max-h-full "
                            cols={{xxs: 12, lg: 12, md: 12}}
                            compactType={null}
                            margin={[0, 0]}
                            rowHeight={height}
                            autoSize={false}
                            useCSSTransforms={false}
                            preventCollision={true}
                            isBounded={true}
                            style={{height: height * 12}}
                            isDroppable={true}
                            droppingItem={dropping}
                            onDrop={(l, item) => {
                                const widget = WidgetList.find((wl) => wl.name === item.i.replace("dropping-", ""))?.getWidget(item)
                                if (widget) dispatch(addWidget(widget))
                            }}
                            onLayoutChange={(lay) => {
                                dispatch(updateLayout(lay))
                            }}
                        >
                            {
                                widgets.map((w) =>
                                    <div

                                        className={"p-1"}
                                        data-grid={{...w.gridOpts, i: w.id}}
                                        key={w.id}>
                                        <div
                                            className="w-full h-full bg-zinc-800"
                                            onMouseDown={() => {
                                                setSelectedWidgetId(w.id)
                                            }}>
                                            {
                                                w.type === "Chart" &&
                                                <div className="flex justify-between items-center px-2 py-1.5">
                                                    <span>{w.displayName}</span>
                                                    <PlusIcon
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onClick={() => dispatch(deleteWidget(w.id))}
                                                        className="w-5 text-zinc-200 cursor-pointer rotate-45"/>
                                                </div>
                                            }
                                            {
                                                w.type === "StickyNote" &&
                                                <div className="flex justify-between items-center px-2 py-1.5">
                                                    <span>{w.displayName}</span>
                                                    <PlusIcon
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onClick={() => dispatch(deleteWidget(w.id))}
                                                        className="w-5 text-zinc-200 cursor-pointer rotate-45"/>
                                                </div>
                                            }
                                            {
                                                w.type === "InfoBox" &&
                                                <div
                                                    className="flex justify-between items-center px-2 py-1.5">
                                                    <span>{w.displayName}</span>
                                                    <PlusIcon
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onClick={() => dispatch(deleteWidget(w.id))}
                                                        className="w-5 text-zinc-200 cursor-pointer rotate-45"/>
                                                </div>
                                            }
                                            {
                                                w.type !== "InfoBox" && w.type !== "StickyNote" && w.type !== "Chart" &&
                                                <div className="flex justify-between items-center px-2 py-1.5">
                                                    <span>{w.displayName}</span>
                                                    <PlusIcon
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onClick={() => dispatch(deleteWidget(w.id))}
                                                        className="w-5 text-zinc-200 cursor-pointer rotate-45"/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </Grid>
                    </div>
                </div>
                <div className="bg-zinc-800 w-48">
                    <TabGroup className="flex flex-col h-full">
                        <TabList className="flex border-b border-zinc-700 py-1 px-4 text-sm">
                            <Tab className="flex-1">Config</Tab>
                            <Tab className="flex-1">Widgets</Tab>
                        </TabList>
                        <TabPanels className="flex-1">
                            <TabPanel>
                                {
                                    selectedWidget ?
                                        <Config studies={studies.map(s => s.study)} widget={selectedWidget}
                                                setWidget={(w) => dispatch(updateWidget(w))}/>
                                        :
                                        <div className="text-sm text-zinc-200/70 italic text-center py-4">Select a
                                            Widget...</div>
                                }
                            </TabPanel>
                            <TabPanel>
                                <div className="flex flex-col p-4 space-y-4">
                                    {
                                        WidgetList.map((w) =>
                                            <div
                                                className="droppable-element bg-zinc-700 text-center py-8"
                                                draggable={true}
                                                unselectable="on"
                                                onDragStart={e => e.dataTransfer.setData("text/plain", "")}
                                                onMouseDown={(e) => {
                                                    setDropping({
                                                        h: w.initialGridOpts.height,
                                                        i: `dropping-${w.name}`,
                                                        w: w.initialGridOpts.width
                                                    })
                                                }}
                                            >
                                                {w.name}
                                            </div>
                                        )
                                    }
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </TabGroup>
                </div>
            </div>
        </div>
    )
}

type ConfigProps = {
    studies: StudyDependency[],
    widget: Widget,
    setWidget: (w: Widget) => void,
}

export function Config(props: ConfigProps) {
    return (
        <>
            <div>{props.widget.displayName}</div>
            <ul className="divide-zinc-700 divide-y">
                {
                    Object.keys(props.widget.configurableOpts).map((k) =>
                        <div className="p-2">
                            {/*<OptionEditor params={props.studies.map(getStudyDependencyParams).flat()} name={k}*/}
                            {/*              configurables={props.widget.configurableOpts[k]} setOption={(op) => {*/}
                            {/*    props.setWidget({*/}
                            {/*        ...props.widget,*/}
                            {/*        configurableOpts: {*/}
                            {/*            ...props.widget.configurableOpts,*/}
                            {/*            [k]: op*/}
                            {/*        }*/}
                            {/*    })*/}
                            {/*}}/>*/}
                        </div>
                    )
                }
            </ul>
        </>
    )
}


type StudyTreeLeafProps = {
    s: StudyDependency
}

export function StudyTreeLeaf(props: StudyTreeLeafProps) {
    return (
        <li className="" key={props.s.id}>
            <div
                className="hover:bg-zinc-600 overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer px-2 py-1.5">
                {props.s.name}
            </div>
            {
                props.s.dependencies.length > 0 &&
                <ul className="px-2">
                    {
                        props.s.dependencies.map((d) =>
                            <StudyTreeLeaf s={d}/>
                        )
                    }
                </ul>
            }
        </li>
    )
}

type func<CT extends ConfigurableType> = (ct: CT) => ConfigurableValue<CT> | undefined

type OptionEditorProps = {
    name: string,
    // studies: JsonStudyRef[],
    params: Referenced<ReadStudyParam>[],
    option: ConfigurableOption<ConfigurableType[]>,
    setOption: (o: ConfigurableOption<ConfigurableType[]>) => void,
    initialValues?: func<ConfigurableType>
};

// TODO: Fix valid types and all
// TODO: Multiple Study types for constant
export function OptionEditor(props: OptionEditorProps) {
// write a function to create an objects of ConfigType as keys and their studyTypes as values from props.configurables.validTypes
    const obj = props.option.validTypes.reduce((acc: { [k in ConfigurableValueType]: StudyType[] }, curr) => {
        return {
            ...acc,
            [curr.configType]: [...acc[curr.configType], curr.studyType]
        }
    }, {Constant: [], Study: []})

    //
    const validStudyTypes = props.option.validTypes.filter(t => t.configType === "Study").map(t => t.studyType)
    const validConstantTypes = props.option.validTypes.filter(t => t.configType === "Constant").map(t => t.studyType)
    const configTypeList = _.uniq(props.option.validTypes.map(t => t.configType))
    const dropdownVisible = configTypeList.length > 1

    const selectedConfig = props.option.value.type.configType

    function setSelectedConfig(config: ConfigurableValueType) {
        const vf = _.first(obj[config])
        if (!vf) throw new Error("")
        props.setOption({
            ...props.option,
            value: props.initialValues?.({
                configType: config,
                studyType: vf
            }) as ConfigurableValue<any> | undefined ?? {
                type: {
                    configType: config,
                    studyType: vf
                },
                value: undefined
            }
        })
    }

    const params = props.params.filter(p => validStudyTypes.includes(p.refValue.valueType) || validStudyTypes.includes(ConvertToNullable[p.refValue.valueType]))
    // if only one config type
    // -> Constant -> StudyTypeEditor
    // -> Series -> Dropdown with studyType[] filter

    // if multiple config type
    // -> Dropdown for Config Type
    // -> Above rules
    return (
        <Labeled label={props.name}>
            <div className="flex space-x-4 items-center">

            {
                dropdownVisible &&
                <div className="flex-1">
                    <AppComboBox items={configTypeList} selected={selectedConfig} setSelected={setSelectedConfig}/>
                </div>

            }

            <div className="flex-1">
                {
                    checkConfigurableType("Constant", props.option.value) &&
                    <TypeEditor
                        value={props.option.value.value ? props.option.value.value : {type: props.option.value.type.studyType}}
                        setValue={(v) => {
                            props.setOption({
                                ...props.option,
                                value: {
                                    type: props.option.value.type,
                                    // @ts-ignore TODO
                                    value: v
                                }
                            })
                        }}/>
                }
                {
                    checkConfigurableType("Study", props.option.value) &&
                    <div className="flex">
                        <AppComboBox className="flex-1" items={params.map(q => ({valueType: q.refValue.valueType, paramName: q.refValue.paramName, studyDepId: q.refValue.studyDepId}))} selected={props.option.value.value} setSelected={(p) => {
                            if (p)
                                props.setOption({
                                    ...props.option,
                                    value: {
                                        type: props.option.value.type,
                                        value: p
                                    }
                                })
                        }} displayValue={item => item?.paramName ?? ""}/>
                    </div>
                }
            </div>
            </div>
        </Labeled>
    );
}

type StudyEditorProps = {}

export function StudyEditor(props: StudyEditorProps) {
    return (
        <>

        </>
    )
}