import {EquationConstructor} from "./study/Equation";
import {StudyTypeConstructors, StudyValueConstructor} from "./study/StudyType";
import {StyleConstructors} from "./study/Style";

import {BlockConstructors} from "./study/Block";
import {ConfigurableTypeConstructor} from "./configurables/ConfigurableType";
import {ConfigurableOptionConstructor} from "./configurables/ConfigurableOption";
import {EmptyStudyConstructor} from "./study/Study";

export type Constructor<A, R> = (args: A) => R;

export const Constructors = {
    StudyType: StudyTypeConstructors, // TODO: Change to defaults
    StudyValue: StudyValueConstructor,
    Equation: EquationConstructor,
    Style: StyleConstructors,
    Block: BlockConstructors,
    Study: {
        Empty: EmptyStudyConstructor
    },
    ConfigurableType: ConfigurableTypeConstructor,
    ConfigurableOption: ConfigurableOptionConstructor
} as const;