import {_authorizedFetch} from "../Home";
import {z} from "zod";
import {BrokerId, BrokerIds, BrokerInputs, LOADING_REDIRECT_URI} from "../../profile/BrokerPage";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {setBrokersState, setRedirectUri} from "./HomeSlice";
import {TRALY_BACKEND_URL} from "../../constants";


const urls = {
    getUserBrokers: TRALY_BACKEND_URL + '/user/brokers',
}

const UserBrokerInfoSchema = z.object({
    brokerId: z.enum(BrokerIds),
    isSetupCompleted: z.boolean(),
    isConnected: z.boolean()
})
const UserBrokerListSchema = z.array(UserBrokerInfoSchema);

export type UserBrokerInfo = z.infer<typeof UserBrokerInfoSchema>

export const getUserBroker: () => Promise<UserBrokerInfo[]> = async () => {
    const res = await _authorizedFetch(urls.getUserBrokers)
    const dt = await res.json();
    return UserBrokerListSchema.parse(dt);
}
export const getLoginUri: (brokerId: string) => Promise<string> = async (brokerId) => {
    const res = await _authorizedFetch(`${TRALY_BACKEND_URL}/broker/loginUri/${brokerId}`)
    return res.text();
}
export const _getRedirectUri: (brokerId: BrokerId) => Promise<string> = async (brokerId) => {
    const res = await _authorizedFetch(`${TRALY_BACKEND_URL}/broker/redirectUri/${brokerId}`);
    return res.text();
}
export const _configureBroker = async (inputs: BrokerInputs) => {
    const res = await _authorizedFetch(`${TRALY_BACKEND_URL}/broker/setup`, {
        method: "POST",
        body: JSON.stringify(inputs),
        headers:{
            "Content-Type": "application/json"
        }
    })
    if (!res.ok) {
        const js = await res.json();
        if (js.message === "Broker already setup")
            alert("The broker details you entered are already connected to another account. " +
                "One broker API can be connected to only one account at a time.")
    }
    else
        return (await res.json()) as UserBrokerInfo; // TODO: To ApiResponse
}
export const _removeBroker = async (brokerId: BrokerId) => {
    const res = await _authorizedFetch(`${TRALY_BACKEND_URL}/broker/remove`, {
        method: "POST",
        body: JSON.stringify({brokerId}),
        headers:{
            "Content-Type": "application/json"
        }
    })
    return await res.text()
}

export const getUserBrokers = createAsyncThunk<UserBrokerInfo[]>("broker/getUserBrokers", async (_, thunkAPI) => {
    thunkAPI.dispatch(setBrokersState("Loading"));
    return await getUserBroker();
})
export const getRedirectUri = createAsyncThunk<string, BrokerId>("broker/getLoginUri", async (brokerId, thunkAPI) => {
    thunkAPI.dispatch(setRedirectUri({brokerId, uri: LOADING_REDIRECT_URI}));
    return await _getRedirectUri(brokerId);
})
export const configureBroker = createAsyncThunk<UserBrokerInfo, BrokerInputs>("broker/configureBroker", async (inputs, thunkAPI) => {
    return await _configureBroker(inputs) ?? thunkAPI.rejectWithValue("Broker already setup");
})
export const removeBroker = toThunk("broker/removeBroker", _removeBroker);

// TODO: Implement and move
function toThunk<Arg, Rtn>(typePrefix: string, func: (args: Arg) => Promise<Rtn>) {
    return createAsyncThunk<Rtn, Arg>(typePrefix, async (args, thunkAPI) => {
        // TODO: Handle Loading
        // TODO: Handle Error
        return await func(args);
    })
}