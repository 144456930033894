import {CloseButton, Dialog, DialogPanel} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/16/solid";
import React, {useEffect, useMemo} from "react";
import {useMainDispatch, useMainSelector} from "../home/data/Store";
import {hasValidState, StudyInfo} from "../home/data/HomeSlice";
import {ArrayUtils} from "../lib/utils/Lodash";
import {getUserStudies} from "../home/data/Thunks";

type AddStudyDialogProps = {
    currentStudyId: string,
    onStudyAdded?:(studyId: string) => void,
    isOpen: boolean,
    setIsOpen: (b: boolean) => void,
}

// TODO: Move
/**
 * @returns True, if there is a collision, else False.
 * */
export function checkStudyCollision(currentStudyId: string, studyList: StudyInfo[]): boolean {
    return studyList.some(s => {
        return s.id === currentStudyId || checkStudyCollision(currentStudyId, s.dependencies)
    })
}

export function AddStudyDialog(props: AddStudyDialogProps) {
    const dispatch = useMainDispatch();
    const studiesState = useMainSelector(state => state.home.userStudies);
    const studies = useMainSelector(state => hasValidState(state.home.userStudies) ? state.home.userStudies : []);

    const filteredStudies = useMemo(() => {
        return studies.filter(s => s.id !== props.currentStudyId && !checkStudyCollision(props.currentStudyId, s.dependencies))
    }, [studies]);

    useEffect(() => {
        if (studiesState === "Idle")
            dispatch(getUserStudies())
    }, [dispatch, studies]);
    
    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={() => props.setIsOpen(false)}
                className="relative z-50">

                <div className="fixed inset-0 bg-black/50" aria-hidden="true"/>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className="max-w-lg rounded-lg bg-zinc-700 dark:text-zinc-200 py-4">
                        <div className="flex pb-4 px-4">
                            <span className="flex-1 min-w-96 text-lg">Add Study</span>
                            <CloseButton>
                                <div className="hover:dark:bg-zinc-800/75 p-0.5 rounded-md">
                                    <PlusIcon className="w-6 rotate-45 cursor-pointer"/>
                                </div>
                            </CloseButton>
                        </div>
                        <div className="border-y border-zinc-200/20">
                            <input
                                className="w-full p-2 px-4 bg-zinc-700 dark:text-zinc-200 border-0 focus:outline-none"
                                placeholder={"Search"}
                            />
                        </div>
                        <ul className="py-4">
                            {
                                filteredStudies.map((s) =>
                                    <div
                                        onClick={() => {
                                            props.onStudyAdded && props.onStudyAdded(s.id)
                                            props.setIsOpen(false)
                                        }}
                                        className="px-4 py-1.5 cursor-pointer hover:bg-zinc-600">
                                        {s.name}
                                    </div>
                                )
                            }
                        </ul>
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}