import {Block, BlockType} from "../Block";
import {Operator} from "../Operators";
import {TypeGuards} from "../../Guards";
import {StudyType} from "../StudyType";
import {StudyContext} from "../StudyContext";
import {Value} from "../Values";


function toPostfix(blocks: Block<BlockType>[]): Block<BlockType>[] {
    const postfix: Block<BlockType>[] = []
    let operators: Operator.Type[] = []

    blocks.forEach(b => {
        if (TypeGuards.Block.Value(b)) postfix.push(b)
        else if (TypeGuards.Block.Operator(b)) {
            let top = operators.at(0)
            while(top != undefined && Operator.GetAssociativityPopCondition(b.associativity)(top, b)){
                postfix.push(operators.shift()!)
                top = operators.at(0)
            }
            operators = [b, ...operators]
        }
    })
    return postfix.concat(operators)
}

export function calculateOutType(context: StudyContext, blocks: Block<BlockType>[]): StudyType | undefined {
    const postfix = toPostfix(blocks)
    let stack: StudyType[] = []

    for (let i = 0; i < postfix.length; i++) {
        const b = postfix[i];
        if (TypeGuards.Block.Value(b)) {
            const out = Value.GetValueOutStudyType(context, b)
            if (!out){
                console.log("TODO: out cannot be determined value: ", b)
                return undefined
            }
            stack = [out , ...stack]
        }
        else if (TypeGuards.Block.Operator(b)) {
            const t1 = stack.shift()
            const t2 = stack.shift()
            if (!t1 || !t2) {
                console.log("TODO: malformed expression inside operator while popping stack")
                return undefined
            }
            const res = Operator.GetOperatorOutStudyType(context, b, t1, t2)
            if (!res){
                console.log("TODO: out cannot be determined operator: ", b)
                return undefined
            }
            stack.push(res)
        } else{
            console.log("TODO: unknown block type")
            return undefined
        }
    }

    if (stack.length !== 1){
        console.log("TODO: malformed expression")
        return undefined
    }
    return stack[0]
}