import React from "react";
import {Checkbox} from "@headlessui/react";
import {CheckIcon} from '@heroicons/react/16/solid'
import {AppComboBox} from "./ui/AppComboBox";
import {
    ConvertToNonNullable,
    isNullable,
    StudyType,
    ToggleNullable, ConvertToNullable, NonNullableStudyTypes
} from "../../types/study/StudyType";


type TypeSelectorProps = {
    selected: StudyType,
    setSelected: (t: StudyType) => void
}

export function TypeSelector(props: TypeSelectorProps) {
    // const [nullable, setNullable] = useState(isNullable(props.selected));
    const nullable = isNullable(props.selected);
    function onCheckboxChanged(){
        props.setSelected(ToggleNullable[props.selected])
    }

    return (
        <>
            <AppComboBox
                className="w-32"
                items={NonNullableStudyTypes}
                selected={ConvertToNonNullable[props.selected]}
                setSelected={(t) => props.setSelected(nullable ? ConvertToNullable[t] : t)}
                displayValue={(t) => t}/>
            <div className="flex items-center pt-2 space-x-2">
                <Checkbox
                    checked={nullable}
                    onChange={onCheckboxChanged}
                    className="flex cursor-pointer group size-5 rounded-md bg-zinc-600 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-white"
                >
                    <CheckIcon className="hidden fill-black group-data-[checked]:block" />
                </Checkbox>
                <div className="text-sm cursor-pointer" onClick={onCheckboxChanged}>Nullable</div>
            </div>
        </>
    )
}

export function NonNullableTypeSelector(props: TypeSelectorProps) {
    return (
        <>
            <AppComboBox
                className="w-32"
                items={NonNullableStudyTypes}
                selected={props.selected}
                setSelected={props.setSelected}
                displayValue={(t) => t}/>
        </>
    )
}
