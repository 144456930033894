import {initializeApp} from "firebase/app";

// TODO: Review all config options
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// } as const;
// const firebaseConfig = {
//     apiKey: "AIzaSyCSg6bmtnouyN-9NiGiEduWqNyM_JJwkGY",
//     authDomain: "traly-dev.firebaseapp.com",
//     databaseURL: "https://traly-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "traly-dev",
//     storageBucket: "traly-dev.appspot.com",
//     messagingSenderId: "402600652473",
//     appId: "1:402600652473:web:06b1dc7e67cd311a4fb568",
//     measurementId: "G-LYDJ5BVJT4"
// };
const firebaseConfig = {
    apiKey: "AIzaSyAQ9QjelDBoWarurmv3ciW7yPJXFZo47y0",
    authDomain: "tralyprod.firebaseapp.com",
    databaseURL: "https://tralyprod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "tralyprod",
    storageBucket: "tralyprod.appspot.com",
    messagingSenderId: "859482536001",
    appId: "1:859482536001:web:973a7a841335b000472ec0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const FirebaseApp = initializeApp(firebaseConfig);