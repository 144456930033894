import {StaticOption, Style, StyleType, StyleTypeOptionsMap, StyleTypeStaticOptionsMap} from "../Style";
import {StudyType, StudyTypePrimitive, StudyTypePrimitiveMap} from "../StudyType";
import _ from "lodash";
import {TypeGuard, TypeGuards} from "../../Guards";
import {ValuesOf} from "../../../lib/utils/Type";
import {Constructors} from "../../Constructors";
import {LineData, SeriesMarker, SeriesMarkerShape, Time, UTCTimestamp} from "lightweight-charts";
import {transpose} from "../../../lib/utils/Array";
import {ArrayUtils} from "../../../lib/utils/Lodash";
import {ConfigurableOption} from "../../configurables/ConfigurableOption";

import {checkConfigurableType} from "../../configurables/ConfigurableValue";

// TODO: File Cleanup
// One StyleData -> StudyTypePrimitive[]
// One TS Data -> StyleData[]
// Plot -> LineData
// Mark -> Marker

export type ParsedStyle = {
    Plot: LineData,
    Mark: SeriesMarker<Time>
}

export type MarkData = ParsedStyle["Mark"]
export type PlotData = ParsedStyle["Plot"]

export function checkPlot(value: ParsedStyle[StyleType]): value is ParsedStyle["Plot"] {
    return "value" in value;
}

const stbv: { [k in StudyTypePrimitiveMap["Shape"]]: SeriesMarkerShape } = {
    ArrowUp: "arrowUp",
    ArrowDown: "arrowDown",
}
const abdc: { [k in StudyTypePrimitiveMap["Position"]]: "aboveBar" | "belowBar" } = {
    AboveBar: "aboveBar",
    BelowBar: "belowBar"
}

const PreparedStyleConverters : { [k in keyof ParsedStyle]: (s: StyleData<k>, time: number) => ParsedStyle[k] | null } = {
    Mark(s: StyleData<"Mark">, time: number): ParsedStyle["Mark"] | null {
        if (s.Series)
            return {
                time: time as UTCTimestamp,
                shape: stbv[s.Shape],
                color: s.Color,
                position: abdc[s.Position]
            };
        else return null
    },
    Plot(s: StyleData<"Plot">, time: number): ParsedStyle["Plot"] {
        return {
            color: s.Color, time: time as UTCTimestamp, value: s.Series
        };
    }
}

// TODO: Won't give CTE if more style types added
type StyleDataMap = {
    [T in keyof StyleTypeOptionsMap]: T extends "Plot" ?
        { [k in keyof StyleTypeOptionsMap["Plot"]]: GetStudyTypePrimitive<StyleTypeOptionsMap["Plot"][k]> } :
        { [k in keyof StyleTypeOptionsMap["Mark"]]: GetStudyTypePrimitive<StyleTypeOptionsMap["Mark"][k]> }
}

type StyleDataStudyTypeMap = {
    [T in keyof StyleTypeOptionsMap]: T extends "Plot" ?
        { [k in keyof StyleTypeOptionsMap["Plot"]]: ValuesOf<GetStudyType<StyleTypeOptionsMap["Plot"][k]>> } :
        { [k in keyof StyleTypeOptionsMap["Mark"]]: ValuesOf<GetStudyType<StyleTypeOptionsMap["Mark"][k]>> }
}

export type StyleData<T extends StyleType> = StyleDataMap[T]
type StyleDataKeys<T extends StyleType> = keyof StyleDataMap[T] & string
const StyleDataKeys: { [T in StyleType]: ValuesOf<StyleDataKeys<T>> } = {
    Mark: ["Series", "Color", "Position", "Shape"],
    Plot: ["Series", "Color"]
}

type GetStudyTypePrimitive<CO extends ConfigurableOption<any>> = CO extends ConfigurableOption<infer CL> ? { [k in keyof CL]: StudyTypePrimitiveMap[CL[k]["studyType"]] }[number] : never
type GetStudyType<CO extends ConfigurableOption<any>> = CO extends ConfigurableOption<infer CL> ? { [k in keyof CL]: CL[k]["studyType"] }[number] : never

const StyleDataStudyTypes: StyleDataStudyTypeMap = {
    Mark: { Series: ["Boolean"], Color: ["Color"], Position: ["Position"], Shape: ["Shape"] },
    Plot: { Series: ["Number"], Color: ["Color"]}
}

export type ParsedStyleMap = {
    [k in StyleType]: { statics: Record<keyof StyleTypeStaticOptionsMap[k], any>, parsed: ParsedStyle[k][], styleId: string }[]
}

export function emptyParsedMap(): ParsedStyleMap {
    return {
        Mark: [],
        Plot: []
    }
}

// export type ParsedStyleMap = { [k: string]: ParsedStyle[StyleType][] }

export class StyleParser {

    /**
     * Sorted(Alphabetically Ascending) Styles
     * */
    styles: Style[]

    constructor(styles: Style[]) {
        // TODO: Review sorted
        // this.styles = styles.sort((a, b) => a.name.localeCompare(b.name));
        this.styles = styles
    }

    parseList(raw: [number, ...(StudyTypePrimitive[][])][]): ParsedStyleMap {
        const res = raw.map(r => this.parseStyles(r))
        const transposed = transpose(res)
        let map = emptyParsedMap();
        const a = _.groupBy(this.styles, "type");

        const b = _.mapValues(a, (groupedStyles) => {
            return groupedStyles.map(style => {
                const idx = this.styles.findIndex(s => s._id === style._id)
                return {
                    statics: _.mapValues(style.staticOpts, (x: StaticOption<any>) => x.value.value),
                    parsed: transposed[idx].filter((v): v is ParsedStyle[StyleType] => v !== null),
                    styleId: style._id
                }
            })
        })
        _.entries(b).map(([k, v]) => {
            map = {
                ...map,
                [k]: v
            }
        });
        return map;
    }

    /** Parse data of a single tick. */
    parseStyles(raw: [number, ...(StudyTypePrimitive[][])]): (ParsedStyle[StyleType] | null)[] {
        // Seperate first element and rest array from raw
        const [time, ...data] = raw
        if (data.length !== this.styles.length)
            throw new Error("Invalid Raw Data Array Length")
        // as keyword is required due to typescript issue, typescript 5.4+ should fix it.
        return this.styles.map((style, i) => this.parseStyle(style, time, data[i]))
    }

    parseTick(raw: [number, ...(StudyTypePrimitive[][])]): { parsed: ParsedStyle[StyleType], styleId: string, styleType: StyleType }[] {
        // Seperate first element and rest array from raw
        const [time, ...data] = raw
        if (data.length !== this.styles.length)
            throw new Error("Invalid Raw Data Array Length")
        // as keyword is required due to typescript issue, typescript 5.4+ should fix it.
        // @ts-ignore
        return this.styles.map((style, i) => ({
            parsed:  this.parseStyle(style, time, data[i]),
            styleId: style._id,
            styleType: style.type
        })).filter(({parsed}) => parsed!== null)
    }

    //
    parseStyle(style: Style, time: number, raw: StudyTypePrimitive[]): ParsedStyle[StyleType] | null {
        // sortedStudy and raw length should match
        const sortedStudyOptions = _.toPairs(style.configurableOpts)
            .filter(([, v]) => v.value.type.configType === "Study" && v.value.value)
            .sort(([k1], [k2]) => k1.localeCompare(k2))

        if (sortedStudyOptions.length !== raw.length)
            throw new Error("Invalid Raw Data Length")

        const opts = style.configurableOpts as { [k: string]: ConfigurableOption<any> }
        const data = _.mapValues(opts, (v, k) => {
            if (checkConfigurableType("Constant", v.value))
                return v.value.value?.value
            else
                return raw[sortedStudyOptions.findIndex(([k1]) => k1 === k)]
        })
        if (StyleDataTypeGuards[style.type](data))
            return PreparedStyleConverters[style.type](
                /*@ts-ignore*/
                data,
                time
            )
        else
            return null
    }
}

export const StyleDataTypeGuards: { [ST in StyleType]: TypeGuard<{ [p: string]: StudyTypePrimitive | undefined }, StyleData<ST>> } = {
    Mark: getTypeGuard("Mark"),
    Plot: getTypeGuard("Plot")
}

function getTypeGuard<ST extends StyleType>(type: ST):TypeGuard<{ [p: string]: StudyTypePrimitive | undefined }, StyleData<ST>> {
    return (arg): arg is StyleData<ST> => {
        return StyleDataKeys[type as StyleType].every(k => {
            const value = arg[k]
            if (value === undefined)
                return false

            // @ts-ignore TODO: remove ignore
            const studyTypes = StyleDataStudyTypes[type as StyleType][k] as StudyType[]
            return studyTypes.every(st => TypeGuards.StudyTypePrimitives[st](value));
        })
    }
}

export function testStyleParser(){
    const parser = new StyleParser([Constructors.Style.Plot({
        name: "Test",
        values: {
            Series: {
                type: {
                    configType: "Study", studyType: "Number"
                },
                value: {
                    paramName: "", studyDepId: "", valueType: "Number"
                }
            },
            Color: {
                type: {
                    configType: "Study", studyType: "Color"
                },
                value: {
                    paramName: "", studyDepId: "", valueType: "Color"
                }
            }
        }
    }),
        Constructors.Style.Mark({
            name: "Test2",
            values: {
                Series: {
                    type: {
                        configType: "Study", studyType: "Boolean"
                    },
                    value: {
                        paramName: "", studyDepId: "", valueType: "Boolean"
                    }
                },
                Color: {
                    type: {
                        configType: "Constant", studyType: "Color"
                    },
                    value: {
                        value: "FFFFFF",
                        type: "Color"
                    }
                }
            }
        }),
    ])

    console.log(parser.parseList(
        [
            [100, ["X", 1234], [false]],
            [101, ["X", 2345], [true]],
            [102, ["X", 416], [false]],
            [103, ["X", 234], [false]]
        ]
    ))
}

export function testStyleParser1(){
    const parser = new StyleParser([
        Constructors.Style.Plot({
            name: "Test",
            values: {
                Series: {
                    type: {
                        configType: "Study", studyType: "Number"
                    },
                    value: {
                        paramName: "", studyDepId: "", valueType: "Number"
                    }
                },
                Color: {
                    type: {
                        configType: "Study", studyType: "Color"
                    },
                    value: {
                        paramName: "", studyDepId: "", valueType: "Color"
                    }
                }
            }
        }),
        Constructors.Style.Mark({
            name: "Test2",
            values: {
                Series: {
                    type: {
                        configType: "Study", studyType: "Boolean"
                    },
                    value: {
                        paramName: "", studyDepId: "", valueType: "Boolean"
                    }
                },
                Color: {
                    type: {
                        configType: "Constant", studyType: "Color"
                    },
                    value: {
                        value: "FFFFFF",
                        type: "Color"
                    }
                }
            }
        }),
    ])

    console.log(parser.parseStyles(
        [100, ["X", 1234], [false]]
    ))
}
