import {Value} from "../types/study/Values";
import {TypeGuards} from "../types/Guards";
import {BlockComboBox, ClearableBlockComboBox} from "./BlockComboBox";
import {addBlocks, removeBlock, replaceBlock} from "./data/Slice";
import {Operator} from "../types/study/Operators";
import React, {forwardRef, useRef} from "react";
import {BlockItem} from "./BlockItem";
import {EquationScopedStudyContext} from "../types/study/StudyContext";
import {useCrafterDispatch} from "./data/Store";
import {Constructors} from "../types/Constructors";

type ExpressionComponentProps = {
    expression: Value.Composite.Expression.Type,
    blockItems: BlockItem[],
    context: EquationScopedStudyContext,
    isSelected: boolean
}

function Expression(props: ExpressionComponentProps, ref: any){
    const {blockItems, context, isSelected} = props;
    const dispatch = useCrafterDispatch()
    const internal = useRef<any>();
    return (
        <>
            {
                props.expression.value.map(b =>
                <>
                    {
                        TypeGuards.Blocks.Empty(b) &&
                        <BlockComboBox
                            onContainerClick={(e) => {
                                if (props.isSelected) e.stopPropagation()
                            }}
                            alwaysOn
                            selected={""}
                            items={blockItems}
                            keyValue={() => b.id}
                            onDelete={() => dispatch(removeBlock(b.id))}
                            setSelected={(i) => {
                                dispatch(replaceBlock({id: b.id, block: i.blockFactory()}))
                            }}/>
                    }
                    {
                        TypeGuards.Blocks.Expression(b) &&
                        <>
                            <p className="text-lg text-zinc-200 ">(</p>
                            <ExpressionComponent {...props} ref={internal} expression={b}/>
                            <p className="text-lg text-zinc-200">)</p>
                        </>
                    }
                    {
                        TypeGuards.Blocks.ConditionalValue(b) &&
                        <>

                        </>
                    }
                    {
                        TypeGuards.Blocks.BinaryComposite(b) &&
                        <>
                            {
                                TypeGuards.Block.SingleValue(b.value.lhs) &&
                                <BlockComboBox
                                    textStyle={TypeGuards.Blocks.Series(b.value.lhs) ? "text-[#C19BFF]" : TypeGuards.Blocks.Constant(b.value.lhs) ? "text-zinc-200" : "text-[#8DDEFF]"}
                                    onContainerClick={(e) => {
                                        if (props.isSelected) e.stopPropagation()
                                    }}
                                    selected={Value.Single.GetStringRepresentation(b.value.lhs, context)}
                                    items={blockItems}
                                    keyValue={() => b.id}
                                    setSelected={(i) => {
                                        dispatch(replaceBlock({id: b.value.lhs.id, block: i.blockFactory()}))
                                        // TODO: Set focus to next
                                        // dispatch(addBlocks({id: props.equation.expression.id, blocks: [i.blockFactory()]}))
                                    }}
                                    placeHolder={props.expression.value.length === 0 ? "Enter an expression..." : "..."}/>

                            }
                            <div className="h-px w-4 bg-zinc-400 mr-1"></div>
                            <p className="text-zinc-300 italic text-sm">{Value.Composite.BinaryComposite.GetOperationDisplayName(b.value.op)}</p>
                            <div className="h-px w-4 bg-zinc-400 ml-1"></div>
                            {
                                TypeGuards.Block.SingleValue(b.value.rhs) &&
                                <BlockComboBox
                                    textStyle={TypeGuards.Blocks.Series(b.value.rhs) ? "text-[#C19BFF]" : TypeGuards.Blocks.Constant(b.value.rhs) ? "text-zinc-200" : "text-[#8DDEFF]"}
                                    onContainerClick={(e) => {
                                        if (props.isSelected) e.stopPropagation()
                                    }}
                                    selected={Value.Single.GetStringRepresentation(b.value.rhs, context)}
                                    items={blockItems}
                                    keyValue={() => b.id}
                                    setSelected={(i) => {
                                        dispatch(replaceBlock({id: b.value.rhs.id, block: i.blockFactory()}))
                                        // TODO: Set focus to next
                                        // dispatch(addBlocks({id: props.equation.expression.id, blocks: [i.blockFactory()]}))
                                    }}
                                    onClear={() => {
                                        dispatch(replaceBlock({id: b.value.rhs.id, block: Constructors.Block.Empty({value: undefined})}))
                                    }}
                                    placeHolder={props.expression.value.length === 0 ? "Enter an expression..." : "..."}/>

                            }
                            {
                                TypeGuards.Blocks.Empty(b.value.rhs) &&
                                <ClearableBlockComboBox
                                    items={blockItems}
                                    onContainerClick={(e) => {
                                        if (props.isSelected) e.stopPropagation()
                                    }}
                                    isVisible={props.isSelected}
                                    setSelected={(i: BlockItem) => {
                                        // TODO
                                        dispatch(replaceBlock({id: b.value.rhs.id, block: i.blockFactory()}))
                                    }}/>
                            }
                        </>
                    }
                    {
                        TypeGuards.Block.SingleValue(b) &&
                        <BlockComboBox
                            textStyle={TypeGuards.Blocks.Series(b) ? "text-[#C19BFF]" : TypeGuards.Blocks.Constant(b) ? "text-zinc-200" :"text-[#8DDEFF]"}
                            onContainerClick={(e) => {if (props.isSelected) e.stopPropagation()}}
                            selected={Value.Single.GetStringRepresentation(b, context)}
                            items={blockItems}
                            keyValue={() => b.id}
                            setSelected={(i) => {
                                dispatch(replaceBlock({id: b.id, block: i.blockFactory()}))
                                // TODO: Set focus to next
                                // dispatch(addBlocks({id: props.equation.expression.id, blocks: [i.blockFactory()]}))
                            }}

                            onClear={() => {
                                dispatch(replaceBlock({id: b.id, block: Constructors.Block.Empty({value: undefined})}))
                            }}
                            placeHolder={props.expression.value.length === 0 ? "Enter an expression..." : "..."}/>
                    }
                    {
                        TypeGuards.Block.Operator(b) &&
                        <BlockComboBox
                            textStyle="text-zinc-300 italic"
                            onContainerClick={(e) => {if (props.isSelected) e.stopPropagation()}}
                            selected={Operator.GetStringRepresentation(b)}
                            items={blockItems}
                            keyValue={() => b.id}
                            setSelected={(i) => {
                                dispatch(replaceBlock({id: b.id, block: i.blockFactory()}))
                                // TODO: Set focus to next
                                // dispatch(addBlocks({id: props.equation.expression.id, blocks: [i.blockFactory()]}))
                            }}
                            onClear={() => {
                                dispatch(replaceBlock({id: b.id, block: Constructors.Block.Empty({value: undefined})}))
                            }}
                            placeHolder={props.expression.value.length === 0 ? "Enter an expression..." : "..."}/>

                    }
                </>
                )
            }
            <ClearableBlockComboBox
                ref={ref}
                items={blockItems}
                onContainerClick={(e) => {if (props.isSelected) e.stopPropagation()}}
                isVisible={props.isSelected}
                setSelected={(i: BlockItem) => {
                    // TODO: Set focus to next
                    dispatch(addBlocks({id: props.expression.id, block: i.blockFactory()}))
                }}/>
        </>
    )
}

export const ExpressionComponent = forwardRef(Expression)