import {PrimaryButton} from "../../crafter/components/ui/PrimaryButton";
import {Auth} from "../../firebase/auth";
import {useMainSelector} from "../data/Store";

type ProfilePageProps = {

}

export function ProfilePage(props: ProfilePageProps){
    const user = useMainSelector(state => state.auth.user === "NOT_INITIALIZED" ? null : state.auth.user)

    return (
        <div className="">
            <h1 className="text-zinc-200 text-2xl mb-1">{user?.name}</h1>
            <h2 className="text-zinc-400 italic text mb-4">{user?.email}</h2>

            <PrimaryButton
                onClick={() => Auth.signOut()}
                text={"Sign Out"} disabled={false}/>
        </div>
    )
}