import React, {useEffect, useMemo, useState} from "react";
import {useCrafterDispatch, useCrafterSelector} from "../data/Store";
import {AppButtonDialog, AppDialog} from "../components/ui/AppDialog";
import {Labeled} from "../components/ui/Labeled";
import {AppInput} from "../components/ui/AppInput";
import {deleteStyle, setStyle} from "../data/Slice";
import {PrimaryButton} from "../components/ui/PrimaryButton";
import {selectStudySeries} from "../data/Selectors";
import {PencilIcon, PlusIcon, TrashIcon} from "@heroicons/react/16/solid";
import {OptionEditor} from "../../builder/Builder";
import {AppComboBox} from "../components/ui/AppComboBox";
import {Style, StyleConstructors, StyleType, StyleTypes} from "../../types/study/Style";
import {Constructors} from "../../types/Constructors";
import {ConfigurableType} from "../../types/configurables/ConfigurableType";
import {ConfigurableValue} from "../../types/configurables/ConfigurableValue";

type StyleComponentProps = {}

// Name Already Exists in Style
// No Name
// Duplicate style exists
//TODO: Validations
export function StyleComponent(props: StyleComponentProps) {
    const [hovered, setHovered] = useState<string>()
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState<Style>()

    const styles = useCrafterSelector(state => state.crafter.study.styles);
    const dispatch = useCrafterDispatch();

    return (
        <>
            <ul className="flex-1">
                {
                    styles.length === 0 ?
                        <div className="flex-1 italic text-zinc-400 p-4">No Styles...</div>
                        :
                        styles.map((s) =>
                            <li onMouseOver={() => {
                                setHovered(s._id)
                            }} onMouseOut={() => {
                                setHovered(undefined)
                            }} className="space-x-4 dark:text-zinc-200 flex">
                                <span>{s.name}</span>
                                <span className="dark:text-zinc-200/60">{s.type}</span>
                                {
                                    hovered === s._id &&
                                    <div className="flex pl-4 dark:text-zinc-400">
                                        <PencilIcon onClick={() => {
                                            setSelected(s)
                                        }} className="w-5 cursor-pointer"/>
                                        <PlusIcon
                                            onClick={() => dispatch(deleteStyle(s._id))}
                                            className="w-5 rotate-45 cursor-pointer"/>
                                    </div>
                                }
                            </li>
                        )
                }
            </ul>
            <div>
                <AppButtonDialog triggerTitle={"Add Style"} title={"Add Style"} isOpen={isOpen} setIsOpen={setIsOpen}>
                    <StyleEditor setStyle={(s) => {
                        setIsOpen(false)
                        dispatch(setStyle(s))
                    }}/>
                </AppButtonDialog>
            </div>
            {
                selected &&
                <AppDialog isOpen={true} onClose={(b) => {if (!b) setSelected(undefined)}} title={"Add Style"}>
                    <StyleEditor key={selected._id} style={selected} setStyle={(s) => {
                        setSelected(undefined)
                        dispatch(setStyle(s))
                    }} deleteStyle={(s) => {
                        setSelected(undefined)
                        dispatch(deleteStyle(s._id))
                    }}
                    />
                </AppDialog>
            }
        </>
    )
}


// Type => Plot (Number, Nullable Number), Mark (boolean, Nullable boolean)
// Series
// Title -> By default from Series
// Options -> Color...

// Plot Color -> Constant, Color Series
type StyleEditorProps = {
    style?: Style,
    setStyle: (s: Style) => void,
    deleteStyle?: (s: Style) => void
}


// TODO: Use dynamic new style name
const newStyleName = "New Style";
export function StyleEditor(props: StyleEditorProps) {
    const allStyles = useCrafterSelector(state => state.crafter.study.styles);

    const [style, setStyle] = useState(props.style ?? Constructors.Style.Plot({name: newStyleName, values: {
            Color: {
                type: {
                    configType: "Constant", studyType: "Color"
                },
                value: {
                    type: "Color",
                    value: "#FF8A65"
                }
            }
        }}))

    const nameError = useMemo(() => {
        if (style.name === "")
            return "Name is required"
        else if(style.name === props.style?.name)
            return undefined
        else if (allStyles.some(s => s.name === style.name))
            return "Name already exists"
        return undefined
    }, [style, allStyles])

    const isValid = useMemo(() => {
        return !Object.values(style.configurableOpts).some(v => v.value.value === undefined) && nameError === undefined
    }, [style, nameError]);
    console.log("isValid", isValid)

    const allSeries = useCrafterSelector(selectStudySeries);
    return (
        <div className={"space-y-2"}>
            <Labeled label={"Name"}>
                <AppInput error={nameError} value={style.name} onChange={(e) => setStyle({...style, name: e.target.value})} type={"text"} placeholder="Name..."/>
            </Labeled>
            <Labeled label={"Style"}>
                <AppComboBox items={StyleTypes} selected={style.type} setSelected={(t: StyleType) => {
                    setStyle(StyleConstructors[t]({
                        id: style._id,
                        name: style.name,
                        values: {
                            Color: {
                                type: {
                                    configType: "Constant", studyType: "Color"
                                },
                                value: {
                                    type: "Color",
                                    value: "#FF8A65"
                                }
                            }
                        }
                    }))
                }}/>
            </Labeled>
            {
                Object.entries(style.configurableOpts).map(([k, v]) =>
                    <div className="">
                        <OptionEditor params={allSeries} name={k}
                                      initialValues={(ct) => {
                                          if (ct.configType === "Constant" && ct.studyType === "Color")
                                              return {
                                                    type: {
                                                        configType: "Constant", studyType: "Color"
                                                    },
                                                    value: {
                                                        type: "Color",
                                                        value: "#FF8A65"
                                                    }
                                              } as ConfigurableValue<ConfigurableType<"Constant", "Color">>
                                              else
                                          return undefined
                                      }}
                                      option={v} setOption={(op) => {
                                          setStyle({
                                              ...style,
                                              configurableOpts: {
                                                  ...style.configurableOpts,
                                                  [k]: op
                                              }
                                          })
                        }}/>
                    </div>
                )
            }
            {
                Object.entries(style.staticOpts).map(([k, opt]) =>
                    <Labeled label={k}>
                        <AppComboBox items={opt.validValues.map(a => a.rep)} selected={opt.value.rep} setSelected={(v) => {
                            setStyle({
                                ...style,
                                staticOpts: {
                                    ...style.staticOpts,
                                    [k]: {
                                        ...opt,
                                        value: (opt.validValues as {rep: string, value: any}[]).find(x => x.rep === v) ?? opt.validValues[0]
                                    }
                                }
                            })
                        }}/>
                    </Labeled>
                )
            }
            {
                props.style &&
                <button
                    onClick={() => {
                        if (props.deleteStyle && props.style)
                            props.deleteStyle(props.style)
                    }}
                    className="text-zinc-200 inline-flex data-[disabled]:bg-gray-500 items-center gap-2 rounded-md dark:bg-red-400 py-1.5 px-2 text-md shadow-inner shadow-white/10 focus:outline-none data-[hover]:dark:bg-[#5F57FF]/70 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                    <TrashIcon className="w-4 h-4"/>
                </button>
            }
            <div className="flex justify-end">
                <PrimaryButton text={props.style ? "Apply": "Add"} disabled={!isValid} onClick={() => {
                    props.setStyle(style)
                }}/>
            </div>
        </div>
    )
}
