import {Input} from "../types/study/Input";
import {TypeEditor} from "../crafter/components/TypeEditor";
import {PrimaryButton} from "../crafter/components/ui/PrimaryButton";
import {useState} from "react";

type InputsEditorProps = {
    inputs: Input[],
    setInputs: (inputs: Input[]) => void
}

// number -> Number Editor
// string -> Text Editor
// boolean -> Checkbox Editor
// color -> Color Editor

export function InputsEditor(props: InputsEditorProps){
    const [newInputs, setNewInputs] = useState<Input[]>(props.inputs)

    return (
        <>
            {
                newInputs.map(i =>
                    <div className="flex space-x-4 items-center">
                        <span>{i.name}</span>
                        <TypeEditor value={i.value} setValue={(v) => {
                            // TODO
                            // setNewInputs(newInputs.map(ni => ni.name === i.name ? {...ni, value: v} : ni))
                        }}/>
                    </div>
                )
            }
            <div className="text-end mt-8">
                <PrimaryButton text={"Apply"} disabled={false} onClick={() => props.setInputs(newInputs)}/>
            </div>
        </>
    )
}