import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {
    ChevronDownIcon
} from '@heroicons/react/16/solid'
import {BrokerInfo} from "../../profile/BrokerPage";
import {useEffect} from "react";

type BrokerDropDownProps = {
    connectedBrokers: BrokerInfo[],
    selectedBroker?: BrokerInfo,
    onBrokerSelected: (b: BrokerInfo) => void,
    onNoBrokerAvailable?: () => void
}

export function BrokerDropDown(props: BrokerDropDownProps) {
    return (
        <div className="z-20">
            <Menu>
                <MenuButton
                    onClick={props.selectedBroker === undefined ? props.onNoBrokerAvailable : undefined}
                    className="flex items-center gap-2 rounded-md bg-zinc-700 py-1.5 px-3 text-sm/6 text-zinc-200 data-[hover]:bg-zinc-700/70 data-[open]:bg-zinc-700">
                    {
                        props.selectedBroker ?
                            <>
                                <img alt={"Broker Image"} src={`/${props.selectedBroker.miniImage}`}
                                     className={"size-5 mr-1"}/>
                                {props.selectedBroker.name}
                            </>
                            :
                            <span className="italic font-light text-zinc-400">Connect a broker</span>
                    }
                    {
                        props.connectedBrokers.length > 0 &&
                        <ChevronDownIcon className="size-4 fill-white/60"/>
                    }
                </MenuButton>
                {
                    props.connectedBrokers.length > 0 &&
                    <MenuItems
                        transition
                        anchor="bottom end"
                        className="w-52 origin-top-right mt-1 rounded-xl border border-white/5 bg-zinc-700 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                    >
                        {
                            props.connectedBrokers.map(b =>
                                <MenuItem>
                                    <button
                                        onClick={() => props.onBrokerSelected(b)}
                                        className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                        <img alt={"Broker Image"} src={`/${b.miniImage}`} className={"size-5 mr-1"}/>
                                        {b.name}
                                    </button>
                                </MenuItem>
                            )
                        }
                        {/*<div className="my-1 h-px bg-white/5" />*/}
                    </MenuItems>
                }
            </Menu>
        </div>
    )
}
