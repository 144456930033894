import {AddStudyDialog} from "../builder/AddStudyDialog";
import {fetchStudyRef} from "./data/Thunks";
import {PrimaryButton} from "./components/ui/PrimaryButton";
import React, {useMemo, useState} from "react";
import {useCrafterDispatch, useCrafterSelector} from "./data/Store";
import {StudyDependency} from "../types/study/Study";
import {ArrayUtils} from "../lib/utils/Lodash";
import {PencilIcon, PlusIcon} from "@heroicons/react/16/solid";
import {deleteStudyDependency} from "./data/Slice";
import clsx from "clsx";
import {AppDialog} from "./components/ui/AppDialog";
import {InputsEditor} from "../components/InputsEditor";
import {Input} from "../types/study/Input";
import {StudyConfig} from "../types/study/Config";


// Analyzer, Builder, Launched Dashboard, Screener, Craft Strategy, BuiltIn studies catalogue

/**
 * TODO
 * Tasks:
 * - DONE: Frontend New Backend API migration ****
 * - Input management  **** BIGGG
 * - Source Type *v1
 * - DONE: Type editor fix  ****
 * - DONE: Show empty block ****
 * - study input, description edit
 * - add Binary Composite operations
 * - Functional, Multi-Functional Composite value *1
 * - DONE: if, else statements  ****
 * - if, else statements UI  ****
 * - when statements
 * - DONE: Static Opts for styles ****
 * - DONE: Plot Style options ****
 * - DONE: Mark Style options ****
 * - DONE: Shape and Position StudyTypes ****
 * - DONE: Add Style shortcuts, from equation editor panel ****
 * - DONE: Auto types ****
 * - Change Style on delete of equations, inputs or dependencies
 * - Equation type error show ****
 * - DONE: Restrict invalid inputs for styles and inputs ****
 * - Show errors - Crafter Connection errors, equation errors ****
 * - equation visibility
 * - equation short description
 * - Study name desc editor
 * - DONE: Delete Study, inputs, equations, with warning, if any. ****
 * - Style imports
 * - Builtin Studies Few *v1
 * - Extra Chart Panes *v1
 * - DONE: Top App Bar
 * - DONE: Crafter UI Fixes **** TEDIOUS
 * - Equations type editor UI Fixes
 *
 * - DONE Symbol Selector ****
 * - DONE: Symbol - Add Debouncing ****
 * - DONE: Symbol - Add cache ****
 * - DONE Timeframe Selector ****
 *
 * - DONE: Fav Icon and name ****
 * - DONE: Sidebar Icons ****
 * - Study delete ****
 *
 * - DONE: Home add loading ****
 * - Home Back navigation handle
 *
 * - Feedback Support
 * - DONE: Support UI ****
 *
 * Analyzer
 * - AI assistant
 * - TimeSeries filters -> Specific day, day range
 *
 * - Skip/Hide/Show/Ghost candles
 * - Highlight background
 *
 * - DONE: Top Bar ****
 * - DONE: Connection, Login Status ****
 * - DONE: Broker Setup new UI ****
 *
 * - Broker Instructions ****
 * - DONE: Select connected broker ****
 *
 * Base Server
 * Implement user limits *V1
 *  - Instrument Name proper *V1
 *  - DONE: Instrument worker Angel One
 - DONE: Implement broker setup already in use mechanism ****
 *
 *
 * Core Server
 * - DONE: temporary Websocket Authentication ****
 * - DONE: temporary Max user sessions authentication ****
 * - Ticket based websocket authentication *V1
 * - DONE: Fix broker data sources ****
 * - DONE: Broker data source realtime fixes and test ****
 * - DONE: If else Blocks ****
 * - DONE: Fyers Shared Socket store ****
 * - DONE: Cache broker sources ****
 * - DONE: Configurable Connected Broker ****
 * - DONE: Implement hard limits **** Number of calculations, number of studies
 * - Implement hard limits on number of timeseries ****
 * - Implement razorpay *V1
 * - DONE: Implement symbol master system **** BIGGGG
 * - DONE: Instrument Master update
 * - Instrument Master for AngelOne ****
 * - DONE: Revamp instrument models ****
 * - DONE: Broker Errors **** -> instrument not supported, broker not connected, internal error
 * - DONE: handle Crafter Socket Errors properly ****
 * - DONE: handle database exceptions ****
 * - Fix serializers *V1
 * - Improve search *V1
 * */

type CrafterStudiesSectionProps = {
    studyId: string,
    toggleVisibility: () => void
}

// List of studies -> name, inputs, configs
// Study -> params
// Add, remove, edit inputs, edit configs


export function CrafterStudiesSection(props: CrafterStudiesSectionProps){
    const dispatch = useCrafterDispatch();
    const studies = useCrafterSelector(state => state.crafter.study.dependencies)

    const initial = useMemo(() => {
        return studies.reduce((obj, s) => {
            return {
                ...obj,
                [s.id]: {
                    timeseries: {
                        instrument: "NSE:HDFCBANK:EQ",
                        timeframe: "15"
                    },
                    inputs: s.inputs
                }
            }
        }, {})
    }, [studies]);

    const [config, setConfig] = useState<Record<string, StudyConfig>>(initial)

    const [isAddStudyOpen, setIsAddStudyOpen] = useState(false);
    const [selectedStudyDep, setSelectedStudyDep] = useState<StudyDependency>();

    return (
        <>
            <div className="flex-1 flex divide-x divide-zinc-500/80">
                <div className="flex-1">
                    <p
                        onClick={() => props.toggleVisibility()}
                        className="w-fit rounded-br rounded-tl cursor-pointer bg-zinc-700/80 py-0.5 px-1 text-zinc-300 text-xs">
                        Studies
                    </p>
                    <ul className="">
                        {
                            studies.map(s =>
                                <StudyDepComponent
                                    key={s.id}
                                    config={config[s.id]}
                                    updateStudyConfig={(id, inputs) => {
                                        // setConfig({...config, [id]: {timeseries: config[id].timeSeries, inputs}})
                                    }}
                                    isTopLevel
                                    studyDep={s}
                                    deleteStudyDep={(s) => {
                                        dispatch(deleteStudyDependency(s.id))
                                        if (selectedStudyDep?.id === s.id)
                                            setSelectedStudyDep(undefined)
                                    }}
                                    setSelected={(s) => setSelectedStudyDep(s.id === selectedStudyDep?.id ? undefined : s)}
                                />
                            )
                        }
                    </ul>
                    <PrimaryButton
                        onClick={() => {
                            setIsAddStudyOpen(true)
                        }}
                        className="ml-2 mt-8 text-xs" text={"Add Study"} disabled={false}/>
                </div>
                <div className="flex-1 text-zinc-300">
                    {
                        selectedStudyDep
                            ?
                            <div>
                                <p className="text-lg p-2">{selectedStudyDep.name}</p>
                                <div className="h-px bg-zinc-600 mb-2"></div>
                                {
                                    selectedStudyDep.equations.map(eq =>
                                        <div className="px-2">
                                            <span>{eq.paramName}</span>
                                            <span className="italic pl-2 text-sm text-zinc-400">{eq.valueType}</span>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <p className="italic text-zinc-400 text-sm p-4 text-center">Select a study...</p>
                    }
                </div>
            </div>
            <AddStudyDialog currentStudyId={props.studyId} isOpen={isAddStudyOpen} setIsOpen={setIsAddStudyOpen}
                            onStudyAdded={s => {
                                dispatch(fetchStudyRef(s))
                            }}/>
        </>
    )
}

type StudyDepComponentProps = {
    studyDep: StudyDependency,
    config?: StudyConfig,
    isTopLevel: boolean,
    deleteStudyDep?: (s: StudyDependency) => void,
    setSelected?: (s: StudyDependency) => void,
    updateStudyConfig?: (studyDepId: string, inputs: Input[]) => void
}

export function StudyDepComponent(props: StudyDepComponentProps){
    const [hovered, setHovered] = useState(false);
    const [isEditInputOpen, setIsEditInputOpen] = useState(false)

    const inputDisplay = useMemo(() => {
        const rep = ArrayUtils.mapByField(props.config?.inputs ?? props.studyDep.inputs, "value").map(i => String(i.value)).join(", ")
        return `( ${rep} )`
    }, [props.studyDep.inputs]);

    return (
        <div
            className=""
            onClick={() => props.setSelected?.(props.studyDep)}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
        >
            <div
                className={clsx("flex px-2 py-0.5 text-zinc-300  cursor-pointer", hovered ? "bg-zinc-800/30": "")}>
                {props.studyDep.name}
                <span className="px-1 text-zinc-400 italic">{inputDisplay}</span>
                <div className="flex-1"></div>
                {
                    hovered && props.isTopLevel &&
                    <div className="flex pl-4 dark:text-zinc-400">
                        <PencilIcon
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsEditInputOpen(true)
                            }}
                            className="w-5 cursor-pointer"/>
                        <PlusIcon
                            onClick={(e) => {
                                e.stopPropagation()
                                props.deleteStudyDep?.(props.studyDep)
                            }}
                            className="w-5 rotate-45 cursor-pointer"/>
                    </div>
                }
            </div>
            <ul className="pl-4">
                {
                    props.studyDep.dependencies.map(d => <StudyDepComponent isTopLevel={false} studyDep={d}/>)
                }
            </ul>
            {
                props.config &&
                <AppDialog isOpen={isEditInputOpen} onClose={() => setIsEditInputOpen(false)} title={props.studyDep.name}>
                    <div className='pt-2'>
                        <InputsEditor
                            inputs={props.config.inputs}
                            setInputs={(inputs) => {
                                setIsEditInputOpen(false)
                                props.updateStudyConfig?.(props.studyDep.id, inputs)
                            }}
                        />
                    </div>
                </AppDialog>
            }
        </div>
    )
}