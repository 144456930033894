import {ConfigurableValueType} from "./ConfigurableValueType";
import {StudyType} from "../study/StudyType";

export type ConfigurableType<C extends ConfigurableValueType = ConfigurableValueType, S extends StudyType = StudyType> = {
    configType: C,
    studyType: S
}

/**
 * Constructs a ConfigurableType.
 * */
export const ConfigurableTypeConstructor: <C extends ConfigurableValueType, S extends StudyType>(c: C, s: S) => ConfigurableType<C, S> = (c, s) => {
    return {
        configType: c, studyType: s
    }
}

