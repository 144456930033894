import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Dashboard} from "../types/Dashboard";
import {fetchStudyRef} from "../../crafter/data/Thunks";
import {Widget} from "../types/Widget";
import GridLayout from "react-grid-layout";
import {saveDashboard} from "./Thunks";
import {Drafts} from "../../lib/utils/Array";

export type BuilderConfigState = {
    dashboard: Dashboard,
    saveStatus: "upToDate" | "loading" | "failed"
}

const initialState: BuilderConfigState = {
    dashboard : {
        id: "",
        displayName: "",
        studies: [],
        widgets: []
    },
    saveStatus: "upToDate"
}

export const BuilderSlice = createSlice({
    name: "Builder",
    initialState: initialState,
    reducers: {
        addWidget: (state, action: PayloadAction<Widget>) => {
            state.dashboard.widgets.push(action.payload)
        },
        updateWidget: (state, action: PayloadAction<Widget>) => {
            const index = state.dashboard.widgets.findIndex((w) => w.id === action.payload.id)
            if (index >= 0) state.dashboard.widgets[index] = action.payload
            // Drafts.replaceOne(state.dashboard.widgets, w => w.id === action.payload.id, action.payload)
        },
        deleteWidget: (state, action: PayloadAction<string>) => {
            const index = state.dashboard.widgets.findIndex((w) => w.id === action.payload)
            if (index >= 0) state.dashboard.widgets.splice(index, 1)
        },
        updateLayout: (state, action: PayloadAction<GridLayout.Layout[]>) => {
            state.dashboard.widgets = state.dashboard.widgets.map((w) => {
                const layout = action.payload.find((l) => l.i === w.id)
                if (layout) {
                    w.gridOpts = {
                        x: layout.x,
                        y: layout.y,
                        w: layout.w,
                        h: layout.h
                    }
                }
                return w
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudyRef.fulfilled ,(state, action) => {
            state.dashboard.studies.push({
                //@ts-ignore TODO
                study: action.payload,
                config: action.payload.inputs
            })
        }).addCase(saveDashboard.pending, (state, action) => {
            state.saveStatus = "loading"
        }).addCase(saveDashboard.rejected, (state, action) => {
            state.saveStatus = "failed"
        }).addCase(saveDashboard.fulfilled, (state, action) => {
            state.saveStatus = "upToDate"
        })
    }
})

export const {addWidget, updateWidget, deleteWidget, updateLayout} = BuilderSlice.actions