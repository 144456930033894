import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TRALY_BACKEND_URL} from "../../constants";
import {auth} from "../../firebase/auth";
import {getAuthorizationHeader} from "../Home";
import {configureBroker, getRedirectUri, getUserBrokers, removeBroker, UserBrokerInfo} from "./BrokerApi";
import {
    BrokerId,
    BrokerInfo,
    BrokerList,
    Brokers,
    LOADING_REDIRECT_URI,
    NO_REDIRECT_URI
} from "../../profile/BrokerPage";
import {getUserStudies} from "./Thunks";
import {Instrument} from "../../types/study/Config";
import {getSymbols} from "./Symbol";
import {User} from "firebase/auth";

// export const Loading = Symbol("Represents a loading state.");

// TODO: Move
// TODO: Fix
export type StateFull<T> = T | "Loading" | "Error" | "Idle";

export function hasValidState<T>(state: StateFull<T>): state is T {
    return state !== "Loading" && state !== "Error" && state !== "Idle";
}
export type UriState = typeof LOADING_REDIRECT_URI | typeof NO_REDIRECT_URI | string;
type HomeSliceState = {
    userStudies: StateFull<StudyInfo[]>,
    userDashboards: { id: string, displayName: string }[] | undefined,
    brokers: StateFull<UserBrokerInfo[]>,
    /**
     * The broker that will be used to fetch data.
     * */
    selectedBroker: BrokerId | undefined,
    uriCache: { [key: string]: UriState },
    symbolsCache: { [key: string]: Instrument[] }
}

const initialState: HomeSliceState = {
    userStudies: "Idle",
    userDashboards: undefined,
    brokers: "Idle",
    selectedBroker: undefined,
    symbolsCache: {},
    uriCache: {}
}

export const HomeSlice = createSlice({
    name: "Home",
    initialState: initialState,
    reducers: {
        // TODO: Review
        clear: state => {
            state.userStudies = "Idle";
            state.userDashboards = undefined;
            state.brokers = "Idle"
        },
        setStudiesState: (state, action: PayloadAction<StateFull<StudyInfo[]>>) => {
            state.userStudies = action.payload
        },
        setBrokersState: (state, action: PayloadAction<StateFull<UserBrokerInfo[]>>) => {
            state.brokers = action.payload
        },
        setSelectedBroker: (state, action: PayloadAction<BrokerId>) => {
            state.selectedBroker = action.payload
        },
        setRedirectUri: (state, action: PayloadAction<{ brokerId: BrokerId, uri: UriState }>) => {
            state.uriCache[action.payload.brokerId] = action.payload.uri
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserStudies.fulfilled, (state, action) => {
            state.userStudies = action.payload
        }).addCase(getUserDashboards.fulfilled, (state, action) => {
            state.userDashboards = action.payload
        }).addCase(getUserBrokers.fulfilled, (state, action) => {
            state.brokers = action.payload
            if (!state.selectedBroker)
                state.selectedBroker = state.brokers.find(b => b.isConnected)?.brokerId
        }).addCase(getSymbols.fulfilled, (state, action) => {
            if (action.payload) {
                state.symbolsCache[action.meta.arg] = action.payload
                if (Object.keys(state.symbolsCache).length > 50) {
                    delete state.symbolsCache[Object.keys(state.symbolsCache)[0]]
                }
            }
        }).addCase(getRedirectUri.fulfilled, (state, action) => {
            state.uriCache[action.meta.arg] = action.payload
        }).addCase(configureBroker.rejected, (state, action) => {
            console.log("a", action.error)
        }).addCase(configureBroker.fulfilled, (state, action) => {
            if (hasValidState(state.brokers)) {
                const idx = state.brokers.findIndex(b => b.brokerId === action.payload.brokerId)
                if (idx !== -1)
                    state.brokers[idx] = action.payload
                else
                    state.brokers.push(action.payload)
            }
        }).addCase(removeBroker.fulfilled, (state, action) => {
            if (hasValidState(state.brokers)) {
                const idx = state.brokers.findIndex(b => b.brokerId === action.payload)
                if (idx !== -1)
                    state.brokers.splice(idx, 1)
            }
        })
    }
})

export const {clear, setStudiesState, setBrokersState, setSelectedBroker,
setRedirectUri} = HomeSlice.actions;

// TODO: Move to types
export type StudyInfo = {
    /**
     * ID of the study. This is not the instanceID.
     * */
    id: string,

    /**
     * Name of the study.
     * */
    name: string,

    /**
     * Dependencies of the study.
     * */
    dependencies: StudyInfo[]
}

export const getUserDashboards = createAsyncThunk<{ id: string, displayName: string }[]>("study/getUserDashboards", async () => {
    const authHeader = await getAuthorizationHeader();
    const res = await fetch(TRALY_BACKEND_URL+"/user/dashboards", {
        headers: authHeader
    });
    return await res.json();
})