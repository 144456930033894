import {WritableDraft} from "immer";

export function chunked<T>(items: T[], size: number): T[][] {
    const chunks: T[][] = [];
    const max = Math.ceil(items.length / size);
    for (let i = 0; i < max; i++) {
        chunks.push(items.slice(i*size, (i+1)*size))
    }
    return chunks
}

export namespace Drafts {
    export function removeElement<T>(arr: WritableDraft<T>[], toRemove: (value: WritableDraft<T>, index: number, obj: WritableDraft<T>[]) => unknown) {
        const index = arr.findIndex(toRemove);
        if (index !== -1) arr.splice(index, 1)
    }
    export function replaceOne<T>(arr: WritableDraft<T>[], toReplace: (value: WritableDraft<T>, index: number, obj: WritableDraft<T>[]) => unknown, replacement: T) {
        const index = arr.findIndex(toReplace);
        // @ts-ignore
        if (index !== -1) arr[index] = replacement
    }
}

export function transpose<T>(mat: T[][]): T[][] {
    if (mat.length < 1) return mat;
    return mat[0].map((_, i) => mat.map(row => row[i]));
}
