import {StudyType, StudyTypeValue} from "../StudyType";
import {DbTypeConverters} from "../../Converters";
import {Input} from "../Input";
import {ReferentConstructor, uniqueId} from "../../../crafter/Utils";

export type DbInput<T extends StudyType = StudyType> = {
    /**
     * Unique name for the input per Study.
     * */
    name: string,
    value: StudyTypeValue<T>,
    isFixed: boolean
}

export const DbInputConverters: DbTypeConverters<Input, DbInput> = {
    fromDb(args): Input {
        return ReferentConstructor({
            ...args
        });
    },
    toDb(args): DbInput {
        return {
            ...args
        };
    }
}