import {
    Block,
    BlockFunctions, CompositeValueType, CompositeValueTypes,
    getTypeGuard,
    SingleValueType, SingleValueTypes, UnsafeBlockConverters,
} from "./Block";
import {uniqueId} from "../../crafter/Utils";
import {ValuesOf} from "../../lib/utils/Type";
import {TypeGuard, TypeGuards} from "../Guards";
import {StudyContext, StudyContextFunctions} from "./StudyContext";
import {ArrayUtils} from "../../lib/utils/Lodash";
import {AllStudyTypes, ConvertToNonNullable, isNullable, StudyType} from "./StudyType";
import {TypeConverterWithExtras} from "../Converters";
import {calculateOutType} from "./utils/Auto";

export namespace Value {
    type ValueType = SingleValueType | CompositeValueType
    export interface Type<T extends ValueType = ValueType> extends Block<T> {}

    export const typeGuard: TypeGuard<Block<any>, Type<SingleValueType | CompositeValueType>> = (b): b is Type<SingleValueType | CompositeValueType> => SingleValueTypes.includes(b.type) || CompositeValueTypes.includes(b.type)

    type GetOutStudyTypeFunc<T extends Block<ValueType>> = (context: StudyContext, b: T) => StudyType | undefined

    export namespace Single {
        export interface Type<T extends SingleValueType> extends Value.Type<T> {
            offset?: number
        }

        export const typeGuard: TypeGuard<Block<any>, Type<SingleValueType>> = (b): b is Type<SingleValueType> => SingleValueTypes.includes(b.type)

        export const GetStringRepresentation: TypeConverterWithExtras<Single.Type<SingleValueType>, string, StudyContext> = (arg, context) => {
            if (TypeGuards.Blocks.Series(arg))
                return arg.value + (arg.offset ? `-${arg.offset}` : "")
            else if (TypeGuards.Blocks.Study(arg))
                return (StudyContextFunctions.findSeriesById(context, arg.value)?.refValue.paramName ?? "") + (arg.offset ? `-${arg.offset}` : "")
            else if (TypeGuards.Blocks.Constant(arg))
                return String(arg.value.value)
            else return ""
        }

        export function getDisplayValue(b: Single.Type<SingleValueType>, context: StudyContext): string | undefined {
            if (TypeGuards.Blocks.Series(b))
                return b.value
            else if (TypeGuards.Blocks.Study(b)){
                const ref = ArrayUtils.findByField(context.deps.allSeries, "refId", b.value)
                return ref?.refValue.paramName
            }
            else if (TypeGuards.Blocks.Constant(b))
                return String(b.value.value)
            else
                return undefined
        }

        export namespace SeriesValue {
            export const SeriesElementTypeMap = {
                Open: "Number",
                Close: "Number",
                High: "Number",
                Low: "Number",
                Index: "Number",
                IsFirstBar: "Boolean"
            } as const;
            export type SeriesElement = keyof typeof SeriesElementTypeMap
            export const SeriesElements = Object.keys(SeriesElementTypeMap) as ValuesOf<SeriesElement>

            export interface Type extends Single.Type<"Series"> {}

            export const GetOutStudyType: GetOutStudyTypeFunc<Type> = (_, b) => {
                return SeriesElementTypeMap[b.value]
            }

            export const functions: BlockFunctions<Type, { id?: string, value: SeriesElement, offset?: number }> = {
                constructor: (args) => {
                    return {
                        id: args.id ?? uniqueId(),
                        type: "Series",
                        value: args.value,
                        offset: args.offset
                    };
                },
                checkType: getTypeGuard<Type>("Series"),
                converters: {
                    fromDb: ({value, offset}) => {
                        return functions.constructor({ value, offset });
                    },
                    toDb: ({value, offset}) => {
                        return {
                            type: "Series",
                            value,
                            offset
                        }
                    }
                }
            }
        }

        export namespace StudyValue {
            export interface Type extends Single.Type<"Study"> {}

            export const GetOutStudyType: GetOutStudyTypeFunc<Type> = (context, b) => {
                const ref = StudyContextFunctions.findSeriesById(context, b.value)
                return ref?.refValue.valueType
            }

            export const functions: BlockFunctions<Type, { id?: string, value: string, offset?: number }> = {
                checkType: getTypeGuard<Type>("Study"),
                constructor: (args) => {
                    return {
                        type: "Study",
                        id: args.id ?? uniqueId(),
                        value: args.value,
                        offset: args.offset,
                    }
                },
                converters: {
                    fromDb: ({value, offset}, context) => {
                        const ref = StudyContextFunctions.findSeriesBy(context, d => d.refValue.studyDepId === value.studyDepId && d.refValue.paramName === value.paramName)
                        if (!ref) throw new Error("Study Reference not found")
                        return functions.constructor({ value: ref.refId, offset });
                    },
                    toDb: ({value, offset}, context) => {
                        const ref = context.deps.allSeries.find(d => d.refId === value)
                        if (!ref) throw new Error("Study Reference not found, while converting to DbBlock")
                        return {
                            type: "Study",
                            value: {
                                studyDepId: ref.refValue.studyDepId,
                                paramName: ref.refValue.paramName,
                                valueType: ref.refValue.valueType
                            },
                            offset
                        }
                    }
                }
            }
        }

        export namespace ConstantValue {
            export interface Type extends Single.Type<"Constant"> {
                offset?: undefined
            }

            export const GetOutStudyType: GetOutStudyTypeFunc<Type> = (_, b) => {
                return b.value.type
            }

            export const functions: BlockFunctions<Type> = {
                checkType: getTypeGuard<Type>("Constant"),
                constructor: (args) => {
                    return {
                        type: "Constant",
                        id: args.id ?? uniqueId(),
                        value: args.value,
                    }
                },
                converters: {
                    fromDb: ({value}) => {
                        return functions.constructor({ value });
                    },
                    toDb: ({value}) => {
                        return {
                            type: "Constant",
                            value
                        }
                    }
                }
            }
        }
    }

    export namespace Composite {

        export namespace EmptyBlock {
            export interface Type extends Value.Type<"Empty"> {}

            export function GetOutStudyType(): StudyType | undefined {
                return undefined
            }

            export const functions: BlockFunctions<Type> = {
                checkType: getTypeGuard<Type>("Empty"),
                constructor: (args) => {
                    return {
                        id: args.id ?? uniqueId(),
                        type: "Empty",
                        value: undefined
                    }
                },
                converters: {
                    fromDb: () => {
                        return functions.constructor({ value: undefined });
                    },
                    toDb: () => {
                        return {
                            type: "Empty"
                        }
                    }
                },
            }
        }

        export namespace BinaryComposite {
            export interface Type extends Value.Type<"BinaryComposite"> {}

            export type Operations = "NullSafe" | "Crosses"

            const BinaryCompositeOperationsAttrs: Record<Operations, { displayName: string, validInTypes: { lhs: StudyType[], rhs: StudyType[] }, getOutStudyType: (t1: StudyType, t2: StudyType) => StudyType | undefined }> = {
                "NullSafe": {
                    displayName: "if null",
                    validInTypes: {
                        lhs: AllStudyTypes,
                        rhs: AllStudyTypes
                    },
                    getOutStudyType: (t1, t2) => {
                        if (ConvertToNonNullable[t1] === ConvertToNonNullable[t2])
                            return t2
                        else return undefined
                    }
                },
                "Crosses": {
                    displayName: "crosses",
                    validInTypes: {
                        lhs: ["Number", "NullableNumber"],
                        rhs: ["Number", "NullableNumber"]
                    },
                    getOutStudyType: (t1, t2) => {
                        const {lhs, rhs} = BinaryCompositeOperationsAttrs["Crosses"].validInTypes
                        if (lhs.includes(t1) && rhs.includes(t2))
                            if (isNullable(t1) || isNullable(t2))
                                return "NullableBoolean"
                            else return "Boolean"
                        else return undefined
                    }
                }
            }

            export function GetOperationDisplayName(op: Operations): string {
                return BinaryCompositeOperationsAttrs[op].displayName
            }

            export const GetOutStudyValue: GetOutStudyTypeFunc<Type> = (context, b) => {
                const {lhs, rhs, op} = b.value
                if (Value.typeGuard(lhs) && Value.typeGuard(rhs)){
                    const lhsType = GetValueOutStudyType(context, lhs)
                    const rhsType = GetValueOutStudyType(context, rhs)
                    if (!lhsType || !rhsType) return undefined
                    return BinaryCompositeOperationsAttrs[op].getOutStudyType(lhsType, rhsType)
                }
                else return undefined
            }

            export const functions: BlockFunctions<Type> = {
                checkType: getTypeGuard<Type>("BinaryComposite"),
                constructor: (args) => {
                    return {
                        id: args.id ?? uniqueId(),
                        type: "BinaryComposite",
                        value: args.value
                    }
                },
                converters: {
                    fromDb: ({value}, context) => {
                        return functions.constructor({
                            value: {
                                op: value.op,
                                lhs: UnsafeBlockConverters[value.lhs.type].fromDb(value.lhs, context),
                                rhs: UnsafeBlockConverters[value.rhs.type].fromDb(value.rhs, context)
                            }
                        });
                    },
                    toDb: ({value}, context) => {
                        return {
                            type: "BinaryComposite",
                            value: {
                                op: value.op,
                                lhs: UnsafeBlockConverters[value.lhs.type].toDb(value.lhs, context),
                                rhs: UnsafeBlockConverters[value.rhs.type].toDb(value.rhs, context)
                            }
                        }
                    }
                }
            }
        }

        export namespace Expression {
            export interface Type extends Value.Type<"Expression"> {}

            export const GetOutStudyValue: GetOutStudyTypeFunc<Type> = (context, b) => {
                return calculateOutType(context, b.value)
            }

            export const functions: BlockFunctions<Type> = {
                checkType: getTypeGuard<Type>("Expression"),
                constructor: (args) => {
                    return {
                        id: args.id ?? uniqueId(),
                        type: "Expression",
                        value: args.value,
                    }
                },
                converters: {
                    fromDb: ({value}, context) => {
                        return functions.constructor({ value: value.map(v => UnsafeBlockConverters[v.type].fromDb(v, context)) });
                    },
                    toDb: ({value}, context) => {
                        return {
                            type: "Expression",
                            value: value.map(v => UnsafeBlockConverters[v.type].toDb(v, context) )
                        }
                    }
                }
            }
        }

        export namespace ConditionalValue {
            export interface Type extends Value.Type<"ConditionalValue"> {}

            export const GetOutStudyValue: GetOutStudyTypeFunc<Type> = (context, b) => {
                const {conditions, elseValue} = b.value
                if (Value.typeGuard(elseValue)){
                    const elseValueType = GetValueOutStudyType(context, elseValue)
                    if (!elseValueType) return undefined

                    const conditionTypes = conditions.map(({condition, value}) => {
                        if (Value.typeGuard(condition) && Value.typeGuard(value)){
                            const conditionType = GetValueOutStudyType(context, condition)
                            const valueType = GetValueOutStudyType(context, value)
                            if (!conditionType || !valueType) return undefined
                            return { conditionType, valueType }
                        }
                        else return undefined
                    })
                    // all conditions must have a boolean type
                    // all values and elseValue must have the same type
                    if (conditionTypes.every(ct => ct?.conditionType === "Boolean") && conditionTypes.every(ct => ct?.valueType === elseValueType))
                        return elseValueType
                    else return undefined
                }
                else return undefined
            }

            export const functions: BlockFunctions<Type> = {
                checkType: getTypeGuard<Type>("ConditionalValue"),
                constructor: (args) => {
                    return {
                        id: args.id ?? uniqueId(),
                        type: "ConditionalValue",
                        value: args.value,
                    }
                },
                converters: {
                    fromDb: ({value}, context) => {
                        const conditions = value.conditions.map(({condition, value}) => {
                            return {
                                condition: UnsafeBlockConverters[condition.type].fromDb(condition, context),
                                value: UnsafeBlockConverters[value.type].fromDb(value, context)
                            }
                        })
                        const elseValue = UnsafeBlockConverters[value.elseValue.type].fromDb(value.elseValue, context)
                        return functions.constructor({ value: { elseValue, conditions }});
                    },
                    toDb: ({value}, context) => {
                        return {
                            type: "ConditionalValue",
                            value: /*value.map(v => UnsafeBlockConverters[v.type].toDb(v, context) )*/{
                                conditions: value.conditions.map(({condition, value}) => {
                                    return {
                                        condition: UnsafeBlockConverters[condition.type].toDb(condition, context),
                                        value: UnsafeBlockConverters[value.type].toDb(value, context)
                                    }
                                }),
                                elseValue: UnsafeBlockConverters[value.elseValue.type].toDb(value.elseValue, context),
                            }
                        }
                    }
                }
            }
        }
    }
    const GetOutStudyTypeMap = {
        "Series": Single.SeriesValue.GetOutStudyType,
        "Study": Single.StudyValue.GetOutStudyType,
        "Constant": Single.ConstantValue.GetOutStudyType,
        "Empty": Composite.EmptyBlock.GetOutStudyType,
        "BinaryComposite": Composite.BinaryComposite.GetOutStudyValue,
        "Expression": Composite.Expression.GetOutStudyValue,
        "ConditionalValue": Composite.ConditionalValue.GetOutStudyValue,
    } as Record<ValueType, (context: StudyContext, b: Type) => (StudyType | undefined)>

    export function GetValueOutStudyType(context: StudyContext, b: Type): StudyType | undefined {
        return GetOutStudyTypeMap[b.type](context, b);
    }
}
