import {
    StaticOption,
    Style,
    StyleConstructors,
    StyleType, StyleTypeConfigurableValues, StyleTypeStaticOptionsMap
} from "../Style";
import _ from "lodash";
import {ConfigurableOption} from "../../configurables/ConfigurableOption";
import {ConfigTypeToValueMap} from "../../configurables/ConfigurableValueType";
import {ConfigurableType} from "../../configurables/ConfigurableType";
import {Constructor} from "../../Constructors";

export type DbStyle<T extends StyleType = StyleType> = {
    name: string,
    type: T,
    // configurableOpts: T extends "Plot" ? { [k in keyof StyleTypeOptionsMap["Plot"]]: Get<StyleTypeOptionsMap["Plot"][k]> }  : { [k in keyof StyleTypeOptionsMap["Mark"]]: Get<StyleTypeOptionsMap["Mark"][k]> }
    configurableOpts: StyleTypeConfigurableValues[T],
    staticOpts: Record<string, any>
}

// TODO: Rename
type x<CT extends ConfigurableType> = {
    type: CT["configType"],
    value: {
        type: CT["studyType"],
        value: ConfigTypeToValueMap<CT["studyType"]>[CT["configType"]]
    }
}

// TODO: Rename
type Get<CO extends ConfigurableOption<any>> = CO extends ConfigurableOption<infer CL> ? { [k in keyof CL]: x<CL[k]> }[number] : never

const UnsafeStyleConstructors = StyleConstructors as {[k in StyleType]: Constructor<{id?: string, name: string, values: StyleTypeConfigurableValues[StyleType], staticOpts: Record<string, any>}, Style<k>> }
export const DbStyleConverters = {
    toDb: <T extends StyleType>(style: Style<T>): DbStyle<T> => {
        return {
            name: style.name,
            type: style.type,
            configurableOpts: _.mapValues(style.configurableOpts, v => (v as ConfigurableOption<any>).value),
            staticOpts: _.mapValues(style.staticOpts, (x: StaticOption<any>) => x.value.value)
        }
    },
    fromDb: (dbStyle: DbStyle): Style => {
        return UnsafeStyleConstructors[dbStyle.type]({name: dbStyle.name, values: dbStyle.configurableOpts, staticOpts: dbStyle.staticOpts})
    }
}