import {createAsyncThunk} from "@reduxjs/toolkit";
import {TRALY_BACKEND_URL} from "../../constants";
import {getAuthorizationHeader} from "../../home/Home";


export const saveDashboard = createAsyncThunk<void, string>("dashboard/post", async (arg) => {
    const authHeader = await getAuthorizationHeader();
    await fetch(TRALY_BACKEND_URL+"/dashboard/update", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...authHeader
        },
        body: arg
    })
})