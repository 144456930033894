import useWebSocket, {ReadyState} from "react-use-websocket";
import {useEffect, useMemo, useRef} from "react";
import {ParsedStyle, ParsedStyleMap, StyleParser} from "../../types/study/utils/StyleParser";
import {Style, StyleType} from "../../types/study/Style";
import {CandlestickData, UTCTimestamp} from "lightweight-charts";
import _ from "lodash";
import {StudyConfig} from "../../types/study/Config";
import {BrokerId, BrokerInfo} from "../../profile/BrokerPage";
import {auth, Auth} from "../../firebase/auth";
import {TRALY_BACKEND_URL, TRALY_CORE_SOCKET_URL} from "../../constants";

// Tick -> List of ohlc -> [number, number, number, number, number]
type TickResponse = [number, number, number, number, number]
type DataFeedResponse = TickResponse | TickResponse[]

function checkTickResponse(response: DataFeedResponse | null): response is TickResponse {
    return response !== null && typeof response.at(0) === "number";
}
function checkCandleResponse(response: DataFeedResponse | null): response is TickResponse[] {
    return response !== null && !checkTickResponse(response)
}
export function useCrafterConnection(styles: Style[], studyId: string, config: StudyConfig, selectedBrokerId?: BrokerId): {
    candles: CandlestickData[];
    parsedStyles: ParsedStyleMap,
    ticks: CandlestickData | undefined,
    parsedUpdates?: { styleId: string, styleType: StyleType, parsed: ParsedStyle[StyleType] }[]
} {
    const query = useMemo(() => {
        if (!selectedBrokerId) return null;
        return async () => {
            const token = await auth.currentUser?.getIdToken();
            return `${TRALY_CORE_SOCKET_URL}/crafter?studyId=${studyId}&brokerId=${selectedBrokerId}&tempToken=${token}`;
        }
    }, [selectedBrokerId, studyId]);
    const { sendMessage, readyState, lastJsonMessage } = useWebSocket(query, {

    });
    useEffect(() => {
        const obj = {
            "timeSeriesConfig": {
                "instrument": config.timeSeries.instrument.id,
                "timeframe": config.timeSeries.timeframe.unit === "m" ? `${config.timeSeries.timeframe.multiplier}` : `${config.timeSeries.timeframe.multiplier}:${config.timeSeries.timeframe.unit}` // TODO: Unit
            },
            "studyInputs": config.inputs
        }
        if (readyState === ReadyState.OPEN)
            sendMessage(JSON.stringify(obj))
    }, [readyState, sendMessage, config]);

    const parser = useMemo(() => {
        return new StyleParser(styles);
    }, [styles]);

    const candleRef = useRef<CandlestickData[]>();

    const candles = useMemo(() => {
        if (lastJsonMessage && !_.isArray(lastJsonMessage) && (lastJsonMessage as {candles: TickResponse[]}).candles)
            return (lastJsonMessage as {candles: TickResponse[]}).candles.map(([time, open, high, low, close]) => {
                return { time: time as UTCTimestamp, open, high, low, close }
            })
        else return candleRef.current ?? [];

    }, [lastJsonMessage])

    const ticks = useMemo(() => {
        if (lastJsonMessage && !_.isArray(lastJsonMessage) && (lastJsonMessage as {candle: TickResponse}).candle){
            const [time, open, high, low, close] = (lastJsonMessage as {candle: TickResponse}).candle
            return { time: time as UTCTimestamp, open, high, low, close }
        }
        else return undefined
    }, [lastJsonMessage]);

    useEffect(() => {
        if (candles)
            candleRef.current = candles;
    }, [candles]);

    const styleRef = useRef<ParsedStyleMap>({
        // TODO: create a constructor
        Plot:[],
        Mark:[]
    });
    const parsedStyles = useMemo(() => {
        if (lastJsonMessage && _.isArray(lastJsonMessage) && lastJsonMessage.length > 0 && _.isArray(lastJsonMessage[0]))
            // TODO: Types for last json message
            // TODO: write for ticks and styles both
            return parser.parseList(lastJsonMessage as any);
        else return styleRef.current;
    }, [lastJsonMessage]);

    useEffect(() => {
        if (parsedStyles)
            styleRef.current = parsedStyles;
    }, [parsedStyles]);

    const parsedUpdates = useMemo(() => {
        if (lastJsonMessage && _.isArray(lastJsonMessage) && lastJsonMessage.length > 0 && !_.isArray(lastJsonMessage[0])){
            // TODO
            return parser.parseTick(lastJsonMessage as any);
        }
    }, [lastJsonMessage, parser]);

    return { candles, parsedStyles, ticks, parsedUpdates }
}