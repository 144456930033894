import {ConfigTypeToValueMap, ConfigurableValueType} from "./ConfigurableValueType";
import {ConfigurableType} from "./ConfigurableType";

/**
 * Represents a configurable value.
 * */
export type ConfigurableValue<CV extends ConfigurableType = ConfigurableType> = {
    type: CV,
    value: ConfigTypeToValueMap<CV["studyType"]>[CV["configType"]] | undefined
}

/**
 * Type guard for ConfigurableType.
 * */
export function checkConfigurableType<C extends ConfigurableValueType>(c: C, value: ConfigurableValue): value is ConfigurableValue<ConfigurableType<C>> {
    return value.type.configType === c
}