import {StudyType} from "../StudyType";
import {CalculationMode, Equation} from "../Equation";
import {DbBlock} from "./DbBlock";
import {uniqueId} from "../../../crafter/Utils";
import {BlockConverters} from "../Block";
import {DbTypeConvertersWithExtras} from "../../Converters";
import {StudyContext, EquationScopedStudyContext, StudyContextFunctions} from "../StudyContext";
import {tempContext} from "../../../crafter/data/Selectors";
import {Value} from "../Values";
import Expression = Value.Composite.Expression;

export type DbEquation = {
    name: string,
    mode: CalculationMode,
    valueType: StudyType,
    expression: DbBlock<"Expression">
}


export const DbEquationConverters: DbTypeConvertersWithExtras<Equation, DbEquation, EquationScopedStudyContext, StudyContext> = {
    fromDb({name, mode, valueType, expression}, context): Equation {
        const eq: Equation = {
            _id: uniqueId(),
            name,
            mode,
            valueType,
            expression: Expression.functions.constructor({value: []})
        };
        const eqCtx = tempContext(context, eq);
        return {
            ...eq,
            expression: BlockConverters.Expression.fromDb(expression, eqCtx)
        };
    },
    toDb({name, valueType, mode, expression}, context): DbEquation {
        return {
            name,
            valueType,
            mode,
            expression: BlockConverters.Expression.toDb(expression, context)
        };
    }
}