import {PayloadAction} from "@reduxjs/toolkit";
import {WritableDraft} from "immer";
import {CrafterConfigState, CrafterConfigStateReducer} from "./Slice";
import {Style} from "../../types/study/Style";
import {Input} from "../../types/study/Input";
import {Equation} from "../../types/study/Equation";
import {Value} from "../../types/study/Values";
import {TypeGuards} from "../../types/Guards";
import {Block} from "../../types/study/Block";
import {ArrayUtils} from "../../lib/utils/Lodash";
import {Constructors} from "../../types/Constructors";

function replaceRecursively(exp: WritableDraft<Value.Composite.Expression.Type>, list: string[]){
    exp.value.forEach((b, idx)=> {
        if (TypeGuards.Blocks.Study(b) && list.includes(b.value))
            exp.value[idx] = Constructors.Block.Empty({value: undefined})

        if (TypeGuards.Blocks.Expression(b))
            replaceRecursively(b, list)
    })
}
//
// function replaceStylesRecursively(style: Style, list: string[]){
//     Object.values(style.configurableOpts).forEach((opt) => {
//         if (opt.value.value)
//     })
//     if (style.type === "Plot"){
//         if (style.)
//     }
// }

export const studyReducers: {
    deleteStudyDependency: CrafterConfigStateReducer<string>
} = {
    deleteStudyDependency: (state, action) => {
        const dep = ArrayUtils.findByField(state.study.dependencies, "id", action.payload)
        if (!dep) return

        const series = ArrayUtils.mapByField([...dep.equations, ...dep.inputs], "_id")
        state.study.equations.forEach(eq => {
            replaceRecursively(eq.expression, series)
        })

        ArrayUtils.removeByField(state.study.dependencies, "id", action.payload)
    }
}

export const inputReducers: {
    setInput: CrafterConfigStateReducer<Input>,
    deleteInput: CrafterConfigStateReducer<string>,
} = {
    setInput: (state, action) => {
        const index = ArrayUtils.findIndexByField(state.study.inputs, "_id", action.payload._id)
        if (index !== -1)
            state.study.inputs[index] = action.payload
        else
            state.study.inputs.push(action.payload)
    },
    deleteInput: (state, action) => {
        state.study.equations.forEach(eq => {
            replaceRecursively(eq.expression, [action.payload])
        })
        ArrayUtils.removeByField(state.study.inputs, "_id", action.payload)
    }
}

export const equationReducers = {
    deleteEquation: (state: CrafterConfigState, action: PayloadAction<string>) => {
        state.study.equations.forEach(eq => {
            replaceRecursively(eq.expression, [action.payload])
        })
        ArrayUtils.removeByField(state.study.equations, "_id", action.payload)
    },
    updateEquation: (state: CrafterConfigState, action: PayloadAction<Equation>) => {
        const idx = ArrayUtils.findIndexByField(state.study.equations, "_id", action.payload._id)
        if (idx !== -1) state.study.equations[idx] = action.payload
    },
    addEquation: (state: CrafterConfigState, action: PayloadAction<Equation>) => {
        state.study.equations.push(action.payload)
    },
    resetEquations: (state: CrafterConfigState) => {
        state.study.equations = []
    },
    addBlocks: (state: CrafterConfigState, action: PayloadAction<{ id: string, block: Block<any> }>) => {
        function updateRecursively(exp: WritableDraft<Value.Composite.Expression.Type>) {
            if (exp.id === action.payload.id) {
                if (TypeGuards.Blocks.BinaryComposite(action.payload.block)) {
                    if (exp.value.length > 0){
                        const last = exp.value[exp.value.length - 1]
                        exp.value[exp.value.length - 1] = Constructors.Block.BinaryComposite({value: {op: action.payload.block.value.op, lhs: last, rhs: action.payload.block.value.rhs}})
                    }
                    else exp.value.push(action.payload.block)
                }
                else
                    exp.value.push(action.payload.block)
            } else {
                exp.value.forEach((e) => {
                    if (TypeGuards.Blocks.Expression(e))
                        updateRecursively(e)
                })
            }
        }

        state.study.equations.forEach((eq) => {
            updateRecursively(eq.expression)
        })
    },
    replaceBlock: (state: CrafterConfigState, action: PayloadAction<{ id: string, block: Block<any> }>) => {
        function updateRecursively(exp: WritableDraft<Value.Composite.Expression.Type>) {
            const idx = ArrayUtils.findIndexByField(exp.value, "id", action.payload.id)
            if (idx >= 0)
                exp.value[idx] = action.payload.block
            else
                exp.value.forEach((b) => {
                    if (TypeGuards.Blocks.Expression(b))
                        updateRecursively(b)
                    else if (TypeGuards.Blocks.BinaryComposite(b)){
                        if (b.value.lhs.id === action.payload.id)
                            b.value.lhs = action.payload.block
                        else if (b.value.rhs.id === action.payload.id)
                            b.value.rhs = action.payload.block
                    }
                })
        }

        state.study.equations.forEach((eq) => {
            updateRecursively(eq.expression)
        })
    },
    removeBlock: (state: CrafterConfigState, action: PayloadAction<string>) => {
        function removeRecursively(exp: WritableDraft<Value.Composite.Expression.Type>) {
            const idx = ArrayUtils.findIndexByField(exp.value, "id", action.payload)
            if (idx >= 0)
                exp.value.splice(idx, 1)
            else
                exp.value.forEach((b) => {
                    if (TypeGuards.Blocks.Expression(b))
                        removeRecursively(b)
                    else if (TypeGuards.Blocks.BinaryComposite(b)){
                        if (b.value.lhs.id === action.payload)
                            b.value.lhs = Constructors.Block.Empty({value: undefined})
                        else if (b.value.rhs.id === action.payload)
                            b.value.rhs = Constructors.Block.Empty({value: undefined})
                    }
                })
        }

        state.study.equations.forEach((eq) => {
            removeRecursively(eq.expression)
        })
    }
}

export const styleReducers: {
    setStyle: CrafterConfigStateReducer<Style>,
    deleteStyle: CrafterConfigStateReducer<string>
} = {
    setStyle:(state, action) => {
        const index = ArrayUtils.findIndexByField(state.study.styles, "_id", action.payload._id)
        if (index >= 0)
            state.study.styles[index] = action.payload
        else
            state.study.styles.push(action.payload)
    },
    deleteStyle: (state, action) => {
        ArrayUtils.removeByField(state.study.styles, "_id", action.payload)
    }
}
