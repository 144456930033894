import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CloseButton, Dialog, DialogPanel} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/16/solid";
import {_authorizedFetch} from "../home/Home";
import {ApiResponse} from "../home/data/Thunks";
import {Instrument} from "../types/study/Config";
import {useMainDispatch, useMainSelector} from "../home/data/Store";
import {getSymbols} from "../home/data/Symbol";

// TODO: Move
export function debounce(func: any, delay: number) {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any) => {
        clearTimeout(debounceTimer);
        // @ts-ignore
        const that = this;
        debounceTimer = setTimeout(() => func.apply(that, args), delay);
    };
}

type SymbolSelectorProps = {
    isOpen: boolean,
    setIsOpen: (b: boolean) => void,
    onSymbolSelected: (i: Instrument) => void,
    debounce?: number
}

export function SymbolSelector(props: SymbolSelectorProps){
    const dispatch = useMainDispatch();
    const symbols = useMainSelector(state => state.home.symbolsCache)

    const [query, setQuery] = useState("")

    const matchedSymbols: Instrument[] = useMemo(() => {
        return symbols[query] ?? []
    }, [symbols, query]);

    const debouncedCall = useCallback(debounce(async (q: string) => {
        dispatch(getSymbols(q))
    }, props.debounce || 500), []);

    useEffect(() => {
        const matched: Instrument[] | undefined = symbols[query]
        if (query !== "" && matched === undefined)
            debouncedCall(query)
    }, [query]);

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => {
                props.setIsOpen(false)
                setQuery("")
            }}
            className="relative z-50">

            <div className="fixed inset-0 bg-black/50" aria-hidden="true"/>
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <DialogPanel className="w-[48rem] rounded-lg bg-zinc-700 dark:text-zinc-200 py-4">
                    <div className="flex pb-4 px-4 items-center">
                        <span className="flex-1 text-lg font-light">Search Symbol</span>
                        <CloseButton>
                            <div className="hover:dark:bg-zinc-800/75 p-0.5 rounded-md">
                                <PlusIcon className="w-6 rotate-45 cursor-pointer"/>
                            </div>
                        </CloseButton>
                    </div>
                    <div className="border-y border-zinc-200/20 ">
                        <input
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            className="w-full p-2 px-4 bg-zinc-700 dark:text-zinc-200 border-0 focus:outline-none"
                            placeholder={"Search"}
                        />
                    </div>
                    <ul className="py-4 min-h-48 max-h-96 overflow-y-auto">
                        {
                            matchedSymbols.map(s => {
                                return (
                                    <li
                                        onClick={() => {
                                            props.onSymbolSelected(s)
                                            props.setIsOpen(false)
                                            setQuery("")
                                        }}
                                        key={s.id} className="p-2 hover:bg-zinc-800 cursor-pointer">
                                        <div className="flex space-x-2">
                                            <span className="w-32">{s.symbol}</span>
                                            <span className="flex-1 text-sm">{s.name}</span>
                                            <span>{s.exchange}</span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </DialogPanel>
            </div>
        </Dialog>
    )
}