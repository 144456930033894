import React from "react";
import clsx from "clsx";

export type LabeledProps = {
    label: string,
    className?: string,
    labelClassName?: string,
    children?: React.ReactNode
}

export function Labeled(props: LabeledProps) {
    return (
        <div className={props.className}>
            <div className={clsx("text-xs font-medium mb-2", props.labelClassName)}>
                {props.label}
            </div>
            {props.children}
        </div>
    )
}