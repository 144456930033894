import {Equation} from "../types/study/Equation";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {BlockComboBox, ClearableBlockComboBox} from "./BlockComboBox";
import {useCrafterDispatch, useCrafterSelector} from "./data/Store";
import {addBlocks, deleteEquation, replaceBlock} from "./data/Slice";
import {BlockItem, generateBlockItems} from "./BlockItem";
import {TypeGuards} from "../types/Guards";
import {Value} from "../types/study/Values";
import {selectContextByEquation} from "./data/Selectors";
import {Operator} from "../types/study/Operators";
import {ExpressionComponent} from "./ExpressionComponent";
import {PlusIcon} from "@heroicons/react/16/solid";


// Series -> Purple
// Study -> Blue
// Operators -> Gray, Italics
type EquationComponentProps = {
    equation: Equation,
    index: number,
    isSelected: boolean,
    setSelected: (eq: Equation) => void
}

export function EquationComponent(props: EquationComponentProps){
    const dispatch = useCrafterDispatch()
    const contextSelector = useCallback(selectContextByEquation(props.equation), [props.equation]);
    const context = useCrafterSelector(contextSelector)

    const blockItems = useMemo(() => generateBlockItems(context), []);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className="flex flex-1 space-x-2">
            {/*<h1 className="text-zinc-200 w-4">{props.index}</h1>*/}
            <div className="flex-1 ">
                <h1 className="text-zinc-400 italic text-xs">{props.equation.name}</h1>
                <div
                    onClick={(e) => {
                        props.setSelected(props.equation)
                        // if clicked target is this div, then only focus
                        if (!props.isSelected && e.target === e.currentTarget) {
                            inputRef.current?.focus()
                        }
                    }}
                    className={`h-12 flex items-center bg-zinc-700/80 rounded px-2 py-1 ring-zinc-500 ${props.isSelected ? "ring-1" : "ring-0"}`}>

                    {
                        props.equation.expression.value.length === 0 && !props.isSelected &&
                        <p className="pl-2 text-zinc-400 italic text-sm">Enter an expression...</p>
                    }
                    <ExpressionComponent ref={inputRef} expression={props.equation.expression} blockItems={blockItems} context={context} isSelected={props.isSelected}/>
                </div>
            </div>
            {
                props.isSelected &&
                <div
                    onClick={() => dispatch(deleteEquation(props.equation._id))}
                    className="flex flex-col">
                    <h1 className="text-transparent italic text-xs">_</h1>
                    <div className="flex-1 flex items-center text-zinc-400 cursor-pointer hover:text-zinc-200">
                        <PlusIcon className="w-5 h-5 rotate-45"/>
                    </div>
                </div>
            }
        </div>
    )
}

