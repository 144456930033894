import React, {Component} from "react";
import {CrafterState, CrafterStore, createCrafterStore} from "./crafter/data/Store";
import {Provider} from "react-redux";
import {Crafter} from "./crafter/CrafterUI";
import {useLoaderData} from "react-router-dom";
import {BuilderState, BuilderStore, createBuilderStore} from "./builder/data/Store";
import {Builder} from "./builder/Builder";
import {createDashboardStore, DashboardState} from "./dashboard/data/Store";
import {Dashboard} from "./dashboard/Dashboard";
import {Store} from "@reduxjs/toolkit";


function StoreWrappedComponent<STATE, STORE extends Store<STATE>>(storeFactory: (initialState: STATE) => STORE, children: React.ReactNode){
    return class WrappedComponent extends Component<{state: STATE}> {
        instanceStore: STORE

        constructor(props: {state: STATE}) {
            super(props);
            this.instanceStore = storeFactory(props.state)
        }

        render() {
            return (
                <>
                    <Provider store={this.instanceStore}>
                        {children}
                    </Provider>
                </>
            );
        }
    }
}
function LoaderWrappedComponent<STATE, STORE extends Store<STATE>>(StoreWrapped: ReturnType<typeof StoreWrappedComponent<STATE, STORE>>){
    const data = useLoaderData() as STATE | null;

    return (
        <>
            {
                data ?
                    <StoreWrapped state={data}/>
                    :<div>Cannot get Study</div>
            }
        </>
    )
}


export const LoaderWrappedCrafter = () => LoaderWrappedComponent(StoreWrappedComponent(createCrafterStore, <Crafter/>))
export const LoaderWrappedBuilder = () => LoaderWrappedComponent(StoreWrappedComponent(createBuilderStore, <Builder/>))
export const LoaderWrappedDashboard = () => LoaderWrappedComponent(StoreWrappedComponent(createDashboardStore, <Dashboard/>))