import {Input} from "./Input";
import {Equation} from "./Equation";
import {Style} from "./Style";
import {DbStyle} from "./db/DbStyle";
import {DbInput} from "./db/DbInput";
import {Referent} from "../../crafter/Utils";
import {ReadStudyParam} from "./ReadStudyParam";
import {Constructor} from "../Constructors";
import {StudyConfig} from "./Config";

/**
 * Type of StudyDependency used on the frontend.
 * */
export interface StudyDependency {
    /**
     * ID of the dependency. A study can have multiple dependencies of a same study, but will have a unique {@link id}.
     * That is, two or more {@link StudyDependency} can have the same {@link studyId} but will have different {@link id}.
     * */
    id: string,

    /**
     * ID of the study.
     * */
    studyId: string,
    name: string,
    inputValues: Referent<Omit<DbInput, "isFixed">>[],
    /**
     * For a study dependency, the referent of the input will be the {@link id}.
     * */
    inputs: Referent<DbInput>[],
    dependencies: StudyDependency[],

    /**
     * For a study dependency, the referent of the equation will be the {@link id}.
     * */
    equations: Referent<ReadStudyParam>[],

    /**
     * For a study dependency, the referent of the style will be the {@link id}.
     * */
    styles: Referent<DbStyle>[]
}

/**
 * Type of the study used on the frontend.
 * */
export interface Study {
    id: string,
    name: string,
    dependencies: StudyDependency[],
    inputs: Input[],
    equations: Equation[],
    styles: Style[]
}

export const EmptyStudyConstructor: Constructor<{id: string, name: string}, Study> = (args) => ({
    id: args.id,
    name: args.name,
    dependencies: [],
    inputs: [],
    equations: [],
    styles: []
})

export function MapStudyDependencyRecursively<R>(dep: StudyDependency, mapFn: (d: StudyDependency) => R): R[] {
    const res = mapFn(dep)
    const deps = dep.dependencies.map(d => MapStudyDependencyRecursively(d, mapFn)).flat()
    return [res, ...deps]
}

export function BuildStudyDependencyRecursiveFn<R>(mapFn: (d: StudyDependency) => R): (dep: StudyDependency) => R[] {
    return dep => MapStudyDependencyRecursively(dep, mapFn)
}