import {BlockTypeGuards} from "./study/Block";
import {Value} from "./study/Values";
import {Operator} from "./study/Operators";
import {StudyTypePrimitiveTypeGuards} from "./study/StudyType";

export type TypeGuard<A, T extends A> = (arg: A) => arg is T

export const TypeGuards = {
    StudyTypePrimitives: StudyTypePrimitiveTypeGuards,
    Block: {
        Value: Value.typeGuard,
        SingleValue: Value.Single.typeGuard,
        Operator: Operator.typeGuard
    },
    Blocks: BlockTypeGuards
} as const;