import React from "react";
import {
    autoUpdate, flip,
    FloatingFocusManager,
    FloatingPortal, offset,
    Placement, shift, useClick, useDismiss,
    useFloating, useInteractions,
    useMergeRefs, useRole
} from "@floating-ui/react";

interface PopoverTriggerProps {
    children: React.ReactNode;
    asChild?: boolean;
}

export const PopoverTrigger = React.forwardRef<
    HTMLElement,
    React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({children, asChild = false, ...props}, propRef) {
    const context = usePopoverContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                "data-state": context.open ? "open" : "closed"
            })
        );
    }
    return (<></>);
});

/**
 * By default, Stops click propagation.
 * */
export const PopoverContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>(function PopoverContent({style, ...props}, propRef) {
    const {context: floatingContext, ...context} = usePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) return null;

    return (
        <FloatingPortal>
            <FloatingFocusManager context={floatingContext} modal={context.modal}>
                <div
                    ref={ref}
                    style={{...context.floatingStyles, ...style}}
                    {...context.getFloatingProps(props)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {props.children}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    );
});

export function Popover({children, modal = false, ...restOptions}: { children: React.ReactNode; } & PopoverOptions) {
    const popover = usePopover({modal, ...restOptions});
    return (
        <PopoverContext.Provider value={popover}>
            {children}
        </PopoverContext.Provider>
    );
}

interface PopoverOptions {
    initialOpen?: boolean;
    placement?: Placement;
    modal?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export function usePopover({
                               initialOpen = false,
                               placement = "bottom",
                               modal,
                               open: controlledOpen,
                               onOpenChange: setControlledOpen
                           }: PopoverOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip({
                crossAxis: placement.includes("-"),
                fallbackAxisSideDirection: "end",
                padding: 5
            }),
            shift({padding: 5})
        ]
    });

    const context = data.context;

    const click = useClick(context, {
        enabled: controlledOpen == null
    });
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
            modal
        }),
        [open, setOpen, interactions, data, modal]
    );
}

type ContextType = (ReturnType<typeof usePopover>) | null;

const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
    const context = React.useContext(PopoverContext);

    if (context == null) {
        throw new Error("Popover components must be wrapped in <Popover />");
    }

    return context;
};
