import {StudyType} from "./StudyType";
import {StudySeries} from "./StudyContext";
import {TypeConverter, TypeConverterWithExtras} from "../Converters";
import {Referenced} from "../../crafter/Utils";
import {Equation} from "./Equation";

// TODO: See if read studyparam is required
export type StudyParam<T extends StudyType = StudyType> = {
    /**
     * Name of the parameter (can be an equation or input). Unique per study.
     * */
    paramName: string,

    /**
     * ID of the study dependency, the param belongs to. It's value comes from {@link StudyDependency.id}, and not from
     * {@link StudyDependency.studyId}, as there can be two or more {@link StudyDependency} with the same {@link studyId}.
     * */
    studyDepId: string,

    valueType: T
}

export type ReadStudyParam<T extends StudyType = StudyType> = {
    /**
     * Type of the value of the parameter (can be an equation or input).
     * */
    valueType: T
} & StudyParam


export const ReadStudyParamConverters: {
    fromEquation: TypeConverterWithExtras<Equation, Referenced<ReadStudyParam>, string>
    fromStudySeries: TypeConverter<StudySeries, Referenced<ReadStudyParam>[]>,
} = {
    fromEquation: (eq, studyId) => ({
        refId: eq._id,
        refValue: { paramName: eq.name, studyDepId: studyId, valueType: eq.valueType }
    }),
    fromStudySeries: series => [...series.inputs, ...series.otherStudyInputs, ...series.otherStudyEquations, ...series.equations]
}

export function checkStudyParam<T extends StudyType>(type: T, param: StudyParam): param is StudyParam<T>{
    return param.valueType === type
}