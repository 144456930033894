import {createAsyncThunk} from "@reduxjs/toolkit";
import {ReadStudyDependency} from "../../types/study/db/ReadStudy";
import {TRALY_BACKEND_URL} from "../../constants";
import {getAuthorizationHeader} from "../../home/Home";
import {ApiResponse} from "../../home/data/Thunks";

// TODO: Move URLS
// TODO: Move Requests

export const saveStudy = createAsyncThunk<void, string>("study/post", async (arg) => {
    const authHeader= await getAuthorizationHeader();
    await fetch(TRALY_BACKEND_URL+"/study/update", {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...authHeader
        },
        body: arg
    })
})

export const fetchStudyRef = createAsyncThunk<ReadStudyDependency, string>("study/info", async (arg) => {
    const authHeader= await getAuthorizationHeader();
    const res = await fetch(TRALY_BACKEND_URL+`/study/ref?studyId=${arg}`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...authHeader
        }
    })
    const dt = await res.json() as ApiResponse;
    if (dt.status)
        return dt.data;
    else
        throw new Error(dt.error);
})
