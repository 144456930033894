import {
    Widget, WidgetType
} from "./Widget";
import GridLayout from "react-grid-layout";
import {uniqueId} from "../../crafter/Utils";
import {ConfigurableOption, ConfigurableOptionConstructor} from "../../types/configurables/ConfigurableOption";
import {ConfigurableType} from "../../types/configurables/ConfigurableType";
import {Constructors} from "../../types/Constructors";


export const WidgetList = [
    {
        name: "Live Chart",
        initialGridOpts: {
            width: 8,
            height: 8
        },
        getWidget: (gridOpts: GridLayout.Layout): LiveChartWidget => ({
            type: "Chart",
            configurableOpts: {

            },
            displayName: "Live Chart",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    },
    {
        name: "Info Box",
        initialGridOpts: {
            width: 2,
            height: 1
        },
        getWidget: (gridOpts: GridLayout.Layout): InfoBoxWidget => ({
            configurableOpts: {
                Name: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "String")]),
                Text: ConfigurableOptionConstructor([Constructors.ConfigurableType("Constant", "String"), Constructors.ConfigurableType("Study", "NullableNumber")])
            },
            type: "InfoBox",
            displayName: "Info Box",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    },
    {
        name: "Sticky Note",
        initialGridOpts: {
            width: 2,
            height: 4
        },
        getWidget: (gridOpts: GridLayout.Layout): StickyNoteWidget => ({
            configurableOpts: {},
            type: "StickyNote",
            displayName: "Sticky Note",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    },
    {
        name: "OI Data",
        initialGridOpts: {
            width: 4,
            height: 4
        },
        getWidget: (gridOpts: GridLayout.Layout): OIWidget => ({
            configurableOpts: {},
            type: "OI",
            displayName: "OI Data",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    },
    {
        name: "Gainers & Losers",
        initialGridOpts: {
            width: 3,
            height: 3
        },
        getWidget: (gridOpts: GridLayout.Layout): GainersLosersWidget => ({
            configurableOpts: {},
            type: "GainersLosers",
            displayName: "Gainers & Losers",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    },
    {
        name: "Realtime Screener",
        initialGridOpts: {
            width: 4,
            height: 6
        },
        getWidget: (gridOpts: GridLayout.Layout): ScreenerWidget => ({
            configurableOpts: {},
            type: "Screener",
            displayName: "Realtime Screener",
            gridOpts: {
                x: gridOpts.x,
                y: gridOpts.y,
                w: gridOpts.w,
                h: gridOpts.h
            },
            id: uniqueId(),
            opts: {}
        })
    }
]

export interface LiveChartWidget extends Widget {
    id: string,
    type: "Chart",
    displayName: "Live Chart",
    opts: {},
    configurableOpts: {

    }
}

// -> Option has a list of Possible Values
export interface InfoBoxWidget extends Widget {
    id: string,
    type: "InfoBox",
    displayName: "Info Box",
    opts: {},
    configurableOpts: {
        Name: ConfigurableOption<[ConfigurableType<"Constant", "String">]>,
        Text: ConfigurableOption<[ConfigurableType<"Constant", "String">, ConfigurableType<"Study", "NullableNumber">]>,
    }
}

// Name
// Bcg
// Text Color
export interface StickyNoteWidget extends Widget {
    id: string,
    type: "StickyNote",
    displayName: "Sticky Note",
    opts: {},
    configurableOpts: {}
}

export interface OIWidget extends Widget {
    id: string,
    type: "OI",
    displayName: "OI Data",
    opts: {},
    configurableOpts: {}
}

export interface GainersLosersWidget extends Widget {
    id: string,
    type: "GainersLosers",
    displayName: "Gainers & Losers",
    opts: {},
    configurableOpts: {}
}

export interface ScreenerWidget extends Widget {
    id: string,
    type: "Screener",
    displayName: "Realtime Screener",
    opts: {},
    configurableOpts: {}
}

export function checkWidgetType<T extends Widget>(type: WidgetType, value: Widget): value is T {
    return value.type === type;
}