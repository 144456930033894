import {Dashboard} from "../../builder/types/Dashboard";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type DashboardSliceState = {
    dashboard: Dashboard,
}

const initialState: DashboardSliceState = {
    dashboard : {
        id: "",
        displayName: "",
        studies: [],
        widgets: []
    },
}

export const DashboardSlice = createSlice({
    name: "Builder",
    initialState: initialState,
    reducers: {

    }
})
