import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {equationReducers, inputReducers, studyReducers, styleReducers} from "./Reducers";
import {fetchStudyRef, saveStudy} from "./Thunks";
import {Study} from "../../types/study/Study";
import {Converters} from "../../types/Converters";
import {StudyConfig, TimeSeries} from "../../types/study/Config";


// states -> loading, loaded, failed => Does not exist
export type CrafterConfigState = {
    study: Study,
    saveStatus: "upToDate" | "loading" | "failed",
    config: StudyConfig
}
// export const initialState: CrafterConfigState = {
//     study: Constructors.Study.Empty({id: "", name: ""}),
//     saveStatus: "upToDate",
//     config: {
//         timeseries: {
//             instrument: {
//                 id: "",
//                 name: "",
//                 exchange: "",
//                 symbol: ""
//             },
//             timeframe: {
//                 multiplier: 0,
//                 unit: "m"
//             }
//         },
//         inputs: []
//     }
// }

export const CrafterSlice = createSlice({
    name: "Crafter",
    // Important: Hack that works only because the store is initialized with preloaded state
    initialState: null as unknown as CrafterConfigState,
    reducers: {
        ...equationReducers,
        ...styleReducers,
        ...inputReducers,
        ...studyReducers,
        setTimeSeries: (state, action: PayloadAction<TimeSeries>) => {
            state.config.timeSeries = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(saveStudy.pending, (state, action) => {
            state.saveStatus = "loading"
        }).addCase(saveStudy.fulfilled, (state, action) => {
            // TODO: Check for response success
            // TODO: Check if this is latest request's response
            state.saveStatus = "upToDate"
        }).addCase(saveStudy.rejected, (state, action) => {
            state.saveStatus = "failed"
        }).addCase(fetchStudyRef.fulfilled, (state, action) => {
            const dep = Converters.ReadStudy({...action.payload})
            state.study.dependencies.push(dep)
        })
    }
})

export type CrafterConfigStateReducer<T> = (state: CrafterConfigState, action: PayloadAction<T>) => void

export const {
    deleteEquation,
    addEquation,
    resetEquations,
    replaceBlock,
    addBlocks,
    setInput,
    deleteInput,
    updateEquation,
    setStyle,
    deleteStudyDependency,
    deleteStyle,
    setTimeSeries,
    removeBlock,
} = CrafterSlice.actions

