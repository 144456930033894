import {FaChartSimple, FaHouseChimney, FaListUl, FaUser} from "react-icons/fa6";
import {MdSpaceDashboard} from "react-icons/md";
import React, {useEffect, useMemo, useState} from "react";
import {useMainDispatch, useMainSelector} from "./data/Store";
import clsx from "clsx";
import {Dashboards} from "./pages/Dashboards";
import {Analyzer} from "./pages/Analyzer";
import {ProfilePage} from "./pages/Profile";
import {BrokersPage} from "./pages/BrokersPage";
import {BrokerDropDown} from "./components/BrokerDropdown";
import {hasValidState, setSelectedBroker} from "./data/HomeSlice";
import {getUserBrokers} from "./data/BrokerApi";
import {Studies} from "./pages/Studies";
import {Home} from "./pages/Home";
import {BrokerInfo, BrokerInputs, Brokers} from "../profile/BrokerPage";
import {useNavigate, useParams} from "react-router-dom";
import {Support} from "./pages/Support";
import {
    AcademicCapIcon,
    BookOpenIcon, ChartPieIcon,
    CircleStackIcon, PresentationChartLineIcon,
    WrenchIcon
} from "@heroicons/react/24/solid";

type SidebarOption = {
    group: string
    icon: React.ReactNode,
    main?: React.ReactNode
}
const SidebarOptions: Record<string, SidebarOption> = {
    Home: {
        group: "",
        icon: <FaHouseChimney/>,
        main: <Home/>
    },
    Studies: {
        group: "",
        icon: <FaChartSimple/>,
        main: <Studies/>
    },
    Dashboards: {
        group: "",
        icon: <MdSpaceDashboard/>,
        main: <Dashboards/>
    },
    Watchlists: {
        group: "",
        icon: <FaListUl/>
    },
    Analyzer: {
        group: "Tools",
        icon: <PresentationChartLineIcon className="size-4"/>,
        main: <Analyzer/>
    },
    Scanner: {
        group: "Tools",
        icon: <ChartPieIcon className="size-4"/>
    },
    Profile: {
        group: "Account",
        icon: <FaUser/>,
        main: <ProfilePage/>
    },
    Brokers: {
        group: "Account",
        icon: <CircleStackIcon className="size-4"/>,
        main: <BrokersPage/>
    },
    Support: {
        group: "Account",
        icon: <WrenchIcon className="size-4"/>,
        main: <Support/>
    },
    Documentation: {
        group: "Learn",
        icon: <AcademicCapIcon className="size-4"/>
    },
    "Change Log": {
        group: "Learn",
        icon: <BookOpenIcon className="size-4"/>
    }
}
const grouped = Object.entries(SidebarOptions).reduce((acc: Record<string, typeof SidebarOptions>, [key, value]) => {
    return {
        ...acc,
        [value.group]: {
            ...acc[value.group],
            [key]: value
        }
    }
}, {})

type SectionKey = keyof typeof SidebarOptions


export function HomeNew(){
    const [currentSection, setCurrentSection] = useState<SectionKey>("Home"); // TODO: Handle invalid routes

    return (
        <div className="max-w-screen min-h-screen bg-zinc-800 flex font-rubik overflow-hidden">
            {/* Side Panel */}
            <div className="w-64 h-screen text-zinc-300 overflow-x-clip overflow-y-auto">
                <div className="p-5">
                    <div className="flex items-center justify-center mb-2">
                        <img alt="Traly logo" className=" p-0.5 w-24 mr-2.5" src="/logo.svg"></img>

                    </div>
                    <ul className="space-y-4 divide-y divide-zinc-700">
                        {
                            Object.entries(grouped).map(([group, items], index) =>
                                <li className={index === 0 ? "" : "pt-4"}>
                                    <div className="text-xs font-semibold text-zinc-500 pb-1">{group}</div>
                                    <ul className="space-y-1">
                                        {
                                            Object.entries(items).map(([name, {icon}]) =>
                                                <li
                                                    onClick={() => {
                                                        // navigate(`/home/${name}`)
                                                        setCurrentSection(name as SectionKey)
                                                    }}
                                                    className={clsx("flex text-sm items-center cursor-pointer p-2 rounded-lg hover:shadow-xl", currentSection === name ? "bg-zinc-400 fill-zinc-800 text-zinc-800" : "hover:bg-zinc-300 hover:fill-zinc-800 hover:text-zinc-800")}>
                                                    <span className="mr-3">
                                                        {icon}
                                                    </span>
                                                    {name}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
            {/* Main */}
            <div className="flex-1 flex flex-col">
                <TopAppBar title={currentSection} onNoBrokerAvailable={() => setCurrentSection("Brokers")} />
                <div
                    className="flex-1 /*mt-2*/ pt-8 pl-8 bg-zinc-900/70 /*rounded-tl-[1.5rem]*/ shadow-[20px_0_40px_-10px_rgba(10,10,10,0.3)_inset]">
                    {SidebarOptions[currentSection].main}
                </div>
            </div>
        </div>
    )
}

type TopAppBarProps = {
    title: string,
    onNoBrokerAvailable?: () => void
}

export function TopAppBar(props: TopAppBarProps){
    // Dispatch
    const dispatch = useMainDispatch();

    // Selectors
    const user = useMainSelector(state => state.auth.user === "NOT_INITIALIZED" ? null : state.auth.user)
    const brokers = useMainSelector(state => state.home.brokers)
    const selectedBroker = useMainSelector(state => state.home.selectedBroker ? Brokers[state.home.selectedBroker] : undefined)

    const connectedBrokers = useMemo(() => {
        if (hasValidState(brokers)) {
            return brokers.filter(b => b.isConnected).map(br => Brokers[br.brokerId])
        }
        else
            return []
    }, [brokers]);

    useEffect(() => {
        if (brokers === "Idle")
            dispatch(getUserBrokers())
    }, [brokers]);

    return (
        <div className="relative h-14 bg-zinc-800 flex items-center border-l border-zinc-900 rounded-xs px-2">
            <h1 className="absolute pr-16 right-0 left-0 flex-1 text-center text-zinc-300 text-lg font-light">{props.title}</h1>
            <div className="flex-1"></div>
            <BrokerDropDown
                connectedBrokers={connectedBrokers}
                selectedBroker={selectedBroker}
                onNoBrokerAvailable={props.onNoBrokerAvailable}
                onBrokerSelected={b => dispatch(setSelectedBroker(b.id))}/>
            {/*TODO: User image*/}
            <img alt="user image" className="p-0.5 ml-4 size-10 mr-2.5 rounded-full" src={user?.image}></img>
        </div>
    )
}