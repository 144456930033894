import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import {ApiWrapper} from "./api-wrapper";
import {createChart, DeepPartial, IChartApi, TimeChartOptions} from "lightweight-charts";
import {createNullableContext} from "./react-helpers";

type Container = HTMLElement | null
type ChartApi = ApiWrapper<IChartApi>
export type ChartProps = {
    initialize?: (api: IChartApi) => void,
    className?: string
    options?: DeepPartial<TimeChartOptions>
    children?: ReactNode
}
export type ChartContainerProps = {
    initialize?: (api: IChartApi) => void,
    container: HTMLElement,
    options?: DeepPartial<TimeChartOptions>,
    children?: ReactNode
}

export const ChartApiContext = createNullableContext<ChartApi>()

export const Chart = forwardRef((props: ChartProps, ref) => {
    const [container, setContainer] = useState<Container>(null);
    const handleRef = useCallback((ref: Container) => setContainer(ref), []);
    return (
        <div className={props.className}
             ref={handleRef}>
            {container && <ChartContainer {...props} container={container} ref={ref} />}
        </div>
    );
})
Chart.displayName = 'Chart'

export const ChartContainer = forwardRef((props: ChartContainerProps, ref) => {
    const { container, options} = props
    const chartApiRef = useRef(new ApiWrapper<IChartApi>(
        (): IChartApi => {
            console.log("Creating")
            const chart = createChart(container, {
                layout: props.options?.layout,
                timeScale: props.options?.timeScale,
                width: props.container.clientWidth,
                height: props.container.clientHeight,
            })
            if (props.initialize) props.initialize(chart);
            return chart;
        },
        (api: IChartApi): void => {
            console.log("Destroying")
            api.remove()
        }
    ))

    useLayoutEffect(() => {
        const chartApi = chartApiRef.current.getApi()
        const handleResize = () => {
            chartApi.applyOptions({
                width: container.clientWidth,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container]);
    useImperativeHandle(ref, () => chartApiRef.current.getApi(), []);

    useEffect(() => {
        const currentRef = chartApiRef.current;
        if (options)
            currentRef.getApi().applyOptions(options);
    }, [options]);

    return (
        <ChartApiContext.Provider value={chartApiRef.current}>
            {props.children}
        </ChartApiContext.Provider>
    )
})
ChartContainer.displayName = 'ChartContainer';