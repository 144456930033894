import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useMainSelector} from "../home/data/Store";

type AuthComponentProps = {
    children?: React.ReactNode
}

export function AuthComponent(props: AuthComponentProps){
    const navigate = useNavigate();

    const user = useMainSelector(state => state.auth.user);
    useEffect(() => {
        if (user === null)
            navigate("/home")
    }, [user]);

    return (
        <>
            {
                user === "NOT_INITIALIZED" ? <div className="w-screen h-screen bg-zinc-900 pt-48">
                    <img src="/logo.svg" alt="logo" className="w-32 h-32 m-auto"/>
                </div> : null
            }
            {
                // TODO: Redirect UI
                user === null ? <div>Redirecting to login...</div> : null
            }
            {
                user !== null && user !== "NOT_INITIALIZED" && props.children
            }
        </>
    )
}