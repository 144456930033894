import {Grid} from "../builder/Builder";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDashboardSelector} from "./data/Store";
import {ChartOptions} from "../temp/Builder";
import {CandleStickSeries, LineSeries} from "../lib/chart/Series";
import {Chart} from "../lib/chart/Chart";
import {IChartApi, LineData} from "lightweight-charts";
import useWebSocket from "react-use-websocket";
import {checkWidgetType, InfoBoxWidget} from "../builder/types/Widgets";
import {AppInput} from "../crafter/components/ui/AppInput";
import {PlusIcon} from "@heroicons/react/16/solid";
import {deleteWidget} from "../builder/data/Slice";
import {DbStyle} from "../types/study/db/DbStyle";
import {StudyDependency} from "../types/study/Study";
import {DbInput} from "../types/study/db/DbInput";
import {StudyType} from "../types/study/StudyType";
import {MarkData, ParsedStyle, StyleData} from "../types/study/utils/StyleParser";

export function useHook(dashboardId: string, studies: { study: StudyDependency; config: DbInput<StudyType>[] }[]){
    const styles:{[id: string]: DbStyle[]}  = studies.reduce((acc, s):{[id: string]: DbStyle[]} => {
        return {
            ...acc,
            [s.study.id]: s.study.styles
        }
    }, {})
    const [styleData, setStyleData] = useState<StyleData<any>[]>([])

    const { sendMessage, lastMessage, readyState } = useWebSocket(`ws://localhost:8080/rt/dashboard/${dashboardId}`);
    useEffect(() => {
        const msg = {
            "timeSeriesConfig":{
                "ticker": "NIFTY",
                "timeFrame": "15m"
            },
            "studyConfig": []
        }
        sendMessage(JSON.stringify(msg))
    }, []);
    useEffect(() => {
        console.log("RAW", lastMessage?.data)
        if (!lastMessage) return;
        const data: { id: string, message: any } = JSON.parse(lastMessage.data)
        // const parser = new StyleParser(styles[data.id])
        // const dt = parser.parse(data.message)
        // setStyleData([...styleData, ...dt])
    }, [lastMessage]);
    return styleData
}

export function Dashboard() {
    const id = useDashboardSelector(state => state.dashboard.dashboard.id)
    const widgets = useDashboardSelector(state => state.dashboard.dashboard.widgets)
    const studies = useDashboardSelector(state => state.dashboard.dashboard.studies)

    const [chartData, setChartData] = useState<LineData[]>([])
    const { sendMessage, lastMessage, readyState } = useWebSocket(`ws://localhost:8080/rt/ts`);
    // const styleData: any[] = []
    const styleData = useHook(id, studies)
    console.log(styleData)
    useEffect(() => {
        const msg = {
            "timeSeriesConfig":{
                "ticker": "NIFTY",
                "timeFrame": "15m"
            },
            "studyConfig": []
        }
        sendMessage(JSON.stringify(msg))
    }, [sendMessage]);

    useEffect(() => {
        if (lastMessage) {
            const data = JSON.parse(lastMessage.data) as LineData[]
            setChartData(data)
            console.log(data)
        }
    }, [lastMessage]);

    const [height, setHeight] = useState(0)
    const chartRef = useRef<{[p: string]: IChartApi}>({});

    // TODO: Optimise observer
    const observer = useRef<ResizeObserver>(new ResizeObserver((e) => {
        e.forEach((entry) => {
            chartRef.current[entry.target.id]?.applyOptions({
                width: entry.target.clientWidth,
                height: entry.target.clientHeight
            })
        })
    }))
    // TODO: unobserve
    const handleRef = useCallback((ref: HTMLDivElement | null) => {
        if (ref) observer.current.observe(ref)
    }, []);

    useEffect(() => {
        return () => {
            observer.current.disconnect()
        }
    }, []);
    const csRef = useRef<any>();

    useEffect(() => {
        console.log(styleData);
        const m = styleData.reduce((acc: MarkData[], dt) => {
            const res: MarkData[] = dt.type === "Mark" ? dt.data : []
            return [...acc, ...res]
        }, [])
        csRef.current?.setMarkers(m)
    }, [styleData]);
    return (
        <div
            className="w-screen min-h-screen flex flex-col font-rubik dark:bg-zinc-900 dark:text-zinc-200 overflow-y-hidden"
            ref={(div) => {
                if (div)
                    setHeight((div.clientHeight - (2 * 16)) / 12)
            }}>

            <div className="flex-1 p-4">
                <div className="">
                    <Grid
                        className="max-h-full h-full w-full"
                        cols={{xxs: 12, lg:12, md:12}}
                        compactType={null}
                        margin={[0, 0]}
                        rowHeight={height}
                        autoSize={false}
                        useCSSTransforms={false}
                        preventCollision={true}
                        isBounded={true}
                        style={{height: height * 12}}
                        isDroppable={false}
                        isResizable={false}
                        isDraggable={false}
                    >
                        {
                            widgets.map((w) =>
                                <div
                                    data-grid={{...w.gridOpts, i: w.id}}
                                    key={w.id}>
                                    <div className="flex-1 p-1 w-full h-full" id={w.id} ref={(r) => {
                                        handleRef(r)
                                    }}>
                                        {
                                            w.type === "Chart" &&
                                            <Chart className="bg-zinc-700" options={ChartOptions} ref={(r) => {
                                                if (r)
                                                    chartRef.current[w.id] = r as IChartApi
                                                else
                                                    delete chartRef.current[w.id]
                                            }}>
                                                <CandleStickSeries  ref={csRef}  data={chartData} initialData={chartData}/>
                                                {
                                                    styleData.map((dt) =>
                                                        <>
                                                            {
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Chart>
                                        }
                                        {
                                            checkWidgetType<InfoBoxWidget>("InfoBox", w) &&
                                            <div className="flex-1 w-full h-full bg-zinc-700">
                                                <div className="flex flex-col justify-center items-center">
                                                    {/*@ts-ignore*/}
                                                    <h1 className="">{w.configurableOpts.Name.value.value?.value}</h1>
                                                    {
                                                        w.configurableOpts.Text.value.value &&
                                                        // @ts-ignore
                                                        <h1 className="text-2xl font-bold">{JSON.stringify(styleData.find(d => d.name === w.configurableOpts.Text.value.value["paramName"])?.data[374]["value"])}</h1>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            w.type === "StickyNote" &&
                                            <div className="flex-1 w-full h-full bg-amber-900">
                                                <div className="flex flex-col justify-center items-center">
                                                    <h1 className="text-2xl font-bold">{w.displayName}</h1>
                                                    <textarea placeholder={"Add Notes here"} className="bg-transparent resize-none outline-0" rows={10}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            w.type !== "InfoBox" && w.type !== "StickyNote" && w.type !== "Chart" &&
                                                <div className="flex justify-center items-center px-2 py-1.5 h-full bg-zinc-800">
                                                    <span>{w.displayName}</span>
                                                </div>
                                        }
                                    </div>

                                </div>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </div>
    )
}

// @ts-ignore
const CustomGridItemComponent = React.forwardRef(({style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props}, ref) => {
    useEffect(() => {
        console.log("style", style)
    }, [style]);
    return (
        // @ts-ignore
        <div style={{ ...style}} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
            {/* Some other content */}
            {children} {/* Make sure to include children to add resizable handle */}
        </div>
    );
})