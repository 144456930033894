import {AppInput} from "./ui/AppInput";
import {Checkbox} from "@headlessui/react";
import React, {useState} from "react";
import {checkStudyType, OptionalStudyTypeValue, StudyType, StudyTypePrimitiveMap} from "../../types/study/StudyType";
import _ from "lodash";
import {Popover, PopoverContent, PopoverTrigger} from "../../lib/components/Popover";
import {BlockPicker} from "react-color";
import {CheckIcon} from "@heroicons/react/16/solid";
import {AppComboBox} from "./ui/AppComboBox";
import {ValuesOf} from "../../lib/utils/Type";

type TypeEditorProps = {
    value: OptionalStudyTypeValue,
    setValue: (s: OptionalStudyTypeValue) => void,
    showInvalidError?: boolean
}

// TODO: Fix
export function TypeEditor(props: TypeEditorProps){
    return (
        <>
            {
                checkStudyType("Number", props.value) &&
                <NumEditor value={props.value} setValue={props.setValue} showInvalidError={props.showInvalidError}/>
            }
            {
                checkStudyType("Boolean", props.value) &&
                <BoolEditor value={props.value} setValue={props.setValue} showInvalidError={props.showInvalidError}/>
            }
            {
                checkStudyType("String", props.value) &&
                <StringEditor value={props.value} setValue={props.setValue} showInvalidError={props.showInvalidError}/>
            }
            {
                checkStudyType("Color", props.value) &&
                <ColorEditor value={props.value} setValue={props.setValue}  showInvalidError={props.showInvalidError}/>
            }
            {
                checkStudyType("Position", props.value) &&
                <PositionEditor value={props.value} setValue={props.setValue}  showInvalidError={props.showInvalidError}/>
            }
            {
                checkStudyType("Shape", props.value) &&
                <ShapeEditor value={props.value} setValue={props.setValue}  showInvalidError={props.showInvalidError}/>
            }
        </>
    )
}

type StudyTypeEditorProps<S extends StudyType> = {
    value: OptionalStudyTypeValue<S>,
    setValue: (s: OptionalStudyTypeValue<S>) => void,
    className?: string,
    showInvalidError?: boolean
}
// TODO: Remove
function isValid(num: any){
    return _.isFinite(num)
}
export function NumEditor(props: StudyTypeEditorProps<"Number">){
    const [query, setQuery] = useState<string>(props.value.value?.toString() ?? "")

    return (
        <AppInput
            error={props.showInvalidError && !isValid(parseFloat(query)) ? "Invalid Number" : undefined}
            type="number"
            placeholder="Value..."
            value={query}
            onChange={(e) => {
                setQuery(e.target.value)
                props.setValue({type: "Number", value: isValid(e.target.valueAsNumber) ? e.target.valueAsNumber : undefined})
            }}
        />
    )
}

export function BoolEditor(props: StudyTypeEditorProps<"Boolean">) {
    return (
        <Checkbox as={"div"}
            checked={props.value?.value}
            onChange={(b) => props.setValue({ type: "Boolean", value: b })}
            className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-white"
        >
            <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
        </Checkbox>
    )
}

export function StringEditor(props: StudyTypeEditorProps<'String'>){
    // TODO: Validate String - Limits
    return (
        <AppInput
            className={props.className}
            type="text"
            placeholder="Value..."
            value={props.value?.value}
            onChange={(e) => props.setValue({type: "String", value: e.target.value})}
        />
    )
}

export function ColorEditor(props: StudyTypeEditorProps<"Color">){
    const [open, setOpen] = useState(false)

    return (
        <>
            <Popover placement="right-start" open={open} onOpenChange={setOpen} modal={true}>
                <PopoverTrigger onClick={(e) => {
                    e.stopPropagation();
                    setOpen((v) => !v)}
                }>
                    <div className={`rounded-lg w-8 h-8`} style={{ backgroundColor: props.value?.value }}>

                    </div>
                </PopoverTrigger>
                <PopoverContent className="z-50">
                    <BlockPicker triangle={'hide'} color={props.value?.value} onChangeComplete={(c) => props.setValue({
                        type: "Color",
                        value: c.hex
                    })} />
                </PopoverContent>
            </Popover>
        </>
    )
}

export const Positions: ValuesOf<StudyTypePrimitiveMap["Position"]> = ["AboveBar", "BelowBar"]
export function PositionEditor(props: StudyTypeEditorProps<"Position">){
    return (
        <AppComboBox items={Positions} selected={props.value.value} setSelected={(v) => {
            props.setValue({type: "Position", value: v})
        }}/>
    )
}

export const Shapes: ValuesOf<StudyTypePrimitiveMap["Shape"]> = ["ArrowUp", "ArrowDown"]
export function ShapeEditor(props: StudyTypeEditorProps<"Shape">){
    return (
        <AppComboBox items={Shapes} selected={props.value.value} setSelected={(v) => {
            props.setValue({type: "Shape", value: v})
        }}/>
    )
}